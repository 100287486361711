<template>
    <div class="kanban" id="kanbanview">
        <kanban-board :stages="getStatusArray" :blocks="operations" @update-block="updateBlock" >
            <div v-for="stage in getStatusArray" :slot="stage" :key="stage" class="row statuses" >
               <h5 class="col-9">{{ stage }}</h5> 
               <div class="col-3"><span v-for="stat in getStatuses" :key="stat.ranking"><h5 v-bind:style="statusBgColor(stage)" v-if="stage==stat.name">{{getStatusCount[stat.ranking]}}</h5></span></div>     
            </div>
            <div v-for="block in operations" :slot="block.id" :key="block.id" v-on:click="goOperationDetails(block.id)" >
                <div class="row firstrow">
                    <div class="col-5 number"><font color="#5fd5b7">No </font> {{block.reference}} </div>
                    <div class="col-3 priority" v-bind:style="priorityColor(block.priority)">{{block.priority}}</div>
                    <div class="col-4 status" v-bind:style="statusBgColor(block.status)">{{block.status}}</div>
                </div> 
                    <hr/>
                <div class="row">  
                <div class="col-8 title">
                {{ block.title }}
                </div>
                <div class="col-4 assignee" v-if="typeof(block.assignee) != 'undefined'">
                {{block.assignee.firstName}}
                <img :src="block.assignee.picture" class="userPicture">
                </div>
                <div class="col-4 assignee" v-if="typeof(block.assignee) == 'undefined'">
                Not assigned
                </div>
                </div>
            </div>
        </kanban-board>
    </div>
</template>
<script>
import "@/assets/css/kanban.css";
import List from "@/components/Global/listHeader"
import configColor from '@/api/config.js'


export default {
    data: function() {
            return {
            stages: configColor.Status,
            }
    },
    components: {
        List
    },   
     computed:{
        operations(){
        return this.$store.getters['contractor/getOperations'];
        },        
        getColors(){
        return configColor.Colors
        },
        getAssigneePicture()
        {
        let user=JSON.parse(localStorage.getItem('user_account'));
        return user.picture
        },
        getStatusCount(){
        return this.$store.getters['contractor/getStatusCount'];
        },
        getStatusArray(){
        var arrayTemp=new Array();
        for(let i=0;i<configColor.Status.length;i++){
            arrayTemp.push(configColor.Status[i].name)
        }
        return arrayTemp;
        },
        getStatuses(){
        return configColor.Status    
        }
    },
    methods:{
        statusCount(){
        this.$store.dispatch('contractor/setStatusCount')
        },
        updateBlock(id, status) {
            this.operations.find(b => b.id === id).status = status;
            let description=this.operations.find(b => b.id === id).description;
            let payload={
                operationId: id,
                status:status,
                description:description
            }
            this.$store.dispatch('contractor/changeStatus',payload)
            let vm=this;
            setTimeout(function(){vm.statusCount()},1000)
        },  
        statusBgColor(status)
        {
        for(var i=0;i<configColor.Status.length;i++){
                if(configColor.Status[i].name==status)
                {
                    return { 
                    'background-color':configColor.Status[i].color,
                    'border-color':configColor.Status[i].color,
                    'color':'white',
                    'border-radius':'6px'
                    };
                }
            }    
        },
        priorityColor(priority)
        {
        for(var i=0;i<configColor.Priorities.length;i++){
            if(configColor.Priorities[i].name==priority)
            {
                return { 
                'color':configColor.Priorities[i].color,
                };
            }
        }
        },
        goOperationDetails(id){
            this.$store.dispatch('contractor/setOperationId',id);       
            this.$router.push('/dashboard/operations/'+id);
        }     
    },
    created(){
        this.statusCount()
    }
}
</script>    
<style lang="scss" scoped>
    .kanban{
        overflow: scroll;
    }
    .userPicture{
    border-radius: 15px;
    width: 25px;
    height: 25px;
    }
    hr{
    width: 90%;
    margin-left: 5px;
    }
    h5{
    font-size: 18px;
    font-weight: bold;
    }
    .number{
    float: left;
    padding-right: 5px;
    font-size: 12px;
    font-weight: bold;
    }
    .status{
    text-align: center;
    border-radius: 10px;
    float: right;
    color: #ffffff;
    padding-right: 0px;
    padding-left: 0px;
    }
    .priority{
    padding-left: 0px;
    float: left;
    }
    .assignee{
    padding-left: 0px;
    padding-right: 0px;
    }
    .firstrow{
    padding-right: 10px;
    }
    .statuses .col-3{
    color: #ffffff;
    border-radius: 6px !important;
    text-align: center;
  }
</style>

<template>
  <div class="row">
    <div class="col leftside">
      <sidebar></sidebar>
    </div>
    <div class="col rightside">
      <auth-content></auth-content>
    </div>
  </div>
</template>

<script>
import sidebar from "@/layout/Auth/Sidebar.vue";
import authContent from "@/layout/Auth/Content.vue";
export default {
  name: "auth-layout",
  components: {
    sidebar,
    authContent,
  },
  data: function () {
    return {};
  },
  computed: {},
  methods: {},
};
</script>
<style scoped>
.row {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 1;
  top: 0;
  overflow-x: hidden;
  margin: 0px;
  font-family: Nunito, sans-serif;
  background-color: #f1f3f4;
}
body {
  background: #f1f3f4;
}
.rightside {
  padding-top: 50px;
}
@media screen and (max-width: 900px) {
  side-bar {
    display: none;
  }
}
</style>

<template>

            <div class="vue-tempalte text-center ">
                <h2 class="head1 text-center">Inscription</h2>
                <img class="head2 logo" src="@/assets/img/proximov-logo-gray.png" alt="">
                <card class="card text-center" >
                    <form @submit.prevent="signup()">
                        <div class="form-group position-relative">
                                <input type="text"
                                placeholder="Email"
                                v-model="email"
                                name="email"
                                class="form-control-lg">
                            <i class="fa fa-envelope-o fa-lg position-absolute"></i>
                        </div>

                        <div class="form-group position-relative">
                            <input type="text" placeholder="Prénom" v-model="username" name="username" class="form-control-lg" required/>           
                            <i class="fa fa-user-o fa-lg position-absolute"></i>

                        </div>

                        <div class="form-group position-relative">
                            <input type="password" id="pass" placeholder="Mot de passe" v-model="password" name="password" class="form-control-lg"  required>
                            <span toggle="#password-field" class="fa fa-fw fa-eye field-icon toggle-password" @click="showPassword"></span>
                            <i class="fa fa-key fa-lg position-absolute"></i>
                        </div>

                        <div class="form-group position-relative">
                            <input type="password" id="pass2" placeholder="Mot de passe" v-model="password2" name="password2" class="form-control-lg password" required>
                            <span  class="fa fa-fw fa-eye field-icon toggle-password" @click="showPassword2"></span>
                            <p v-if="!passwordsmatch" class="auth-failure-msg">Merci de vérifier que vos mots de passe sont identiques.</p>                                                  
                            <i class="fa fa-key fa-lg position-absolute"></i>
                        </div>
                    
                        <div class="form-check position-relative">
                            <label class="form-check-label">
                            <input type="checkbox" class="form-check-input" value="" required>J'accepte les conditions générales d'utilisation
                            </label>
                        </div>
                        <p-button type="primary" :round="true" :loading='submitted'>S'enregistrer</p-button>
                        <p v-if="displayRegisFailureMessage" class="auth-failure-msg">L'email que vous avez utilisé existe déjà, veuillez réessayer!</p>
                        <p v-if="passwordFailureMessage" class="auth-failure-msg">Le mot de passe que vous avez saisi est faible, veuillez réessayer!</p>
                        <p v-if="displayRegisSuccessMessage" class="auth-success-msg"> Compte crée avec succès </p>
                    </form>
 
                </card>
                <p class="forgot-password text-center">
                    J'ai déjà un compte, 
                    <router-link :to="{name: 'signin'}">je me connecte</router-link>
               </p>
            </div>

</template>

<script>
import "@/assets/css/main.css";
import sidebar from "@/components/auth/sidebarsignin.vue"
    export default {
        name:'signup',
        components:{
            sidebar
        },
        data: function() {
            return {
            email:'',
            username: '',
            password: '',
            password2:'',          
            submitted: false,
            mobileView:true,
            passwordsmatch:true

            }
        },
        computed: {
            displayRegisFailureMessage: function () {
            this.submitted=false;
            return this.$store.getters['auth/displayRegisFailureMessage']
        },
            passwordFailureMessage: function () {
            this.submitted=false;
            return this.$store.getters['auth/passwordFailureMessage']
        },
            displayRegisSuccessMessage:function() {
            return this.$store.getters['auth/displayRegisSuccessMessage']
            }
        },
        methods:
        {
            async signup()
            {
            this.submitted=true;
            if(this.password == this.password2)
            {
            this.passwordsmatch=true
            let payload = {
            type: 'contractor',
            email : this.email,
            username: this.username,
            password : this.password
            }
            await this.$store.dispatch('auth/signup', payload)
            this.submitted=false;
            }
            else{
            this.passwordsmatch=false;
            }
            this.submitted=false;
            },
            showPassword()
            {
            var temp = document.getElementById("pass"); 
            if (temp.type === "password") { 
                temp.type = "text";
            } 
            else { 
                temp.type = "password";
            } 
            },
            showPassword2()
            {
            var temp2 = document.getElementById("pass2"); 
            if (temp2.type === "password") { 
                temp2.type = "text";
            } 
            else { 
                temp2.type = "password";
            } 
            }        
        }
    }
</script>
<style lang="scss" scoped>

h1{
    font-weight: bold;
    font-size: xx-large;
    padding-bottom: 50px;
}
.logo {
    height : 25px;
    width: auto !important;
    margin-bottom: 20px;
}
.row {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 1;
  top: 0;
  overflow-x: hidden;
  margin: 0px;
  font-family: Nunito, sans-serif ;
  background-color: #f1f3f4;
}

.card{
    background-color: white;
    padding:50px;
    border-radius:10px;    
    width: 400px;
    display: inline-block
}
body{
    background: #f1f3f4;
}
form{
    align-items: center;
    flex-direction: column;
}
.forgot-password
{
    margin-top: 10px;
    font-size: medium;
}
.form-group ,h2
{
    position: relative;
    padding: 5px;      
}
.form-check
{
    margin-bottom: 15px
}
h2{
    font-weight: bold;
    padding: 10px 5px 10px 10px;
}
input:focus
{
    outline: none;
    box-shadow: 0px 0px 2px #2d3748;
    border-radius:10px;    
}
input
{
    background-color: #f1f3f4; 
    font-size: 17px;
}
.form-control-lg{
    background-color: #f1f3f4;
    padding-left: 50px;
    border: none;
    border-radius:10px;    
    width: 250px
}
.form-group i {
  position: absolute;  
  font-family: FontAwesome;
  margin: 0 auto;
  font-size: 1rem;
  font-style: normal;
  left: 20px;
  top: 21px;
  padding: 2px;
  bottom: 5px;
  color: gray;
}

@media screen and (max-width: 900px) {
.card
{
    margin:0px;
    width: 80%;
    padding-right: 10px;
    padding-left: 10px;
}
.field-icon {
display: none;
}
side-bar{
    display: none
}
.btn{
    font-size: 10px;
    width: 50%;
    padding: 10px
}
 
.form-control-lg{
    width: 100%;
}
.form-group i
{
    left: 20px;
    top:18px;
}
}
@media screen and (max-width: 450px) {
.card{
    width: 100%
}
}
@media screen and (max-width: 300px) {
.btn{
    width: 75%;
}
.form-check-label,.forgot-password,.btn
{
    font-size: 11px;
}
}
</style>
<style>
.head1{
    color: #2D3748;
    font-size: 23px;
}
.logop{
    color: #eebb4d;
    font-weight: bold;
}
.auth-failure-msg{
      font-size: 12px;
      color: red;
      margin-top: 15px;
}
.auth-success-msg{
    font-size: 12px;
    color: green;
    margin-top: 15px;
}
.field-icon {
  float: right;
  margin-left: -15px;
  margin-right: 10px;
  margin-top: -30px;
  position: relative;
  z-index: 2;
}
.leftside{
    background-color:  #25273e;
    padding-right: 60px;
}
.rightside{
    padding: 40px;
    background-color: #f1f3f4;    
}
.head2{
    display: none
}
@media screen and (max-width: 900px) {

.rightside
{
    padding-bottom: 250px;
    padding-left: 40px;
    padding-top: 60px; 
}
.vue-tempalte
{
    text-align: center;
}
.forgot-password{
    padding: 20px;
}
.leftside,.head1{
    display: none
}
.head2{
    display:flex;
    font-weight: bold;
    margin-left: auto;
    margin-right: auto;
    width: 5em;
}
}
@media screen and (max-width: 450px) {

.forgot-password
{
    margin: 0px;
    padding-left: 0px;
    padding-right: 0px; 
}
}
@media screen and (max-width: 300px) {
.rightside{
    padding-left: 10px;
    padding-right: 10px;
} 
}
</style>
<template>
  <div>
    <crud-table
      :data="statuses"
      :columns="columns"
      :addFunction="addStatus"
      :editFunction="editStatus"
      :deleteFunction="deleteStatus"
      :isRowEditable="isEditable"
      :isRowDeletable="isDeletable"
      :addFormSchema="this.addFormSchema"
      :editFormSchema="this.editFormSchema"
      :showInfo="true"
      infoText="Vous ne pouvez pas supprimer des statuts rattachés à des opérations. Veuillez d'abord
      modifier le statut des opérations concernées pour pouvoir les supprimer."
    >
    </crud-table>
  </div>
</template>

<script>
import crudTable from "@/components/Global/crudTable";

export default {
  data() {
    return {
      columns: [
        {
          id: 1,
          prop: "label",
          label: "Libellé",
          width: "180",
        },
        {
          id: 2,
          prop: "ranking",
          label: "Ordre",
          width: "180",
        },
        {
          id: 3,
          prop: "color",
          label: "Couleur",
          width: "180",
          formatter: this.formatColor,
        },
        {
          id: 4,
          prop: "default",
          label: "Par défaut",
          width: "180",
          formatter: this.formatDefaultValue,
        },
      ],
      addFormSchema: [
        {
          component: "h3",
          children: "Ajouter un statut",
        },
        {
          type: "text",
          name: "label",
          label: "Libellé",
          validation: "required",
        },
        {
          type: "number",
          name: "ranking",
          label: "Ordre",
          validation: "required",
        },
        {
          type: "color",
          name: "color",
          label: "Couleur",
        },
        {
          type: "checkbox",
          name: "default",
          label: "Par défault",
        },
        {
          type: "submit",
          label: "Ajouter",
        },
      ],
      editFormSchema: [
        {
          component: "h3",
          children: "Modifier un statut",
        },
        {
          type: "text",
          name: "label",
          label: "Libellé",
          validation: "required",
        },
        {
          type: "number",
          name: "ranking",
          label: "Ordre",
          validation: "required",
        },
        {
          type: "color",
          name: "color",
          label: "Couleur",
        },
        {
          type: "checkbox",
          name: "default",
          label: "Par défault",
        },
        {
          type: "submit",
          label: "Modifier",
        },
      ],
    };
  },
  components: {
    crudTable,
  },
  computed: {
    statuses() {
      return this.$store.getters["contractor/getStatuses"];
    },
  },
  methods: {
    async addStatus(status) {
      let label = status.label;
      status.name = label.replace(/\W+/g, "_").toLowerCase();
      await this.$store.dispatch("contractor/addStatus", status);
      this.$store.dispatch("contractor/loadOrganization", status);
      //we update the status count components
      this.$store.dispatch("contractor/loadOperationsCount");
    },
    async editStatus(status) {
      await this.$store.dispatch("contractor/editStatus", status);
      this.$store.dispatch("contractor/loadOrganization", status);
      //we update the status count components
      this.$store.dispatch("contractor/loadOperationsCount");
    },
    async deleteStatus(status) {
      await this.$store.dispatch("contractor/deleteStatus", status);
      this.$store.dispatch("contractor/loadOrganization", status);
      //we update the status count components
      this.$store.dispatch("contractor/loadOperationsCount");
    },
    formatColor(row, column, cellValue, index) {
      // return '<el-color-picker v-model="' + cellValue + '"></el-color-picker>';
      //return '<div style="width:10px height:10px background-color:' + cellValue + '"></div>'
      return cellValue;
    },
    formatDefaultValue(row, column, cellValue, index) {
      if (cellValue === true) {
        return "oui";
      } else {
        return "non";
      }
    },
    isEditable(item) {
      return true;
    },
    isDeletable(item) {
      let operationsCount =
        this.$store.getters["contractor/getOperationsCountByStatus"];
      let matchingStatus = operationsCount.find((x) => x.id === item.id);
      if (matchingStatus.count > 0) {
        return false;
      } else return true;
    },
  },
  created() {},
};
</script>
<style scoped>
</style>


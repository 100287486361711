import axios from "axios";
import configColor from "./config";
import AuthService from "@/auth/AuthService";

/**variables**/
let apiURL = process.env.VUE_APP_API_URI;
let accountId;
let config;
let httpClient = axios.create({
  baseURL: apiURL,
  //we set a 10s timeout to better handle errors
  timeout: 10000,
});
let refreshConfig = function () {
  accountId = AuthService.getUserTokenId();
  config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accountId}`,
    },
  };
};
let refreshConfigFile = function () {
  accountId = AuthService.getUserTokenId();
  config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${accountId}`,
    },
  };
};
let refreshConfigDownload = function () {
  accountId = AuthService.getUserTokenId();
  config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accountId}`,
    },
    responseType: "blob",
  };
};
export default {
  //////////////////////// ACCOUNT MANAGEMENT /////////////////////////////////////
  createAccount: async (payload) => {
    try {
      refreshConfig();

      let result = await httpClient.post(
        "/public/accounts/signup ",
        {
          email: payload.email,
          password: payload.password,
          invitationCode: payload.invitationCode,
          firstName: payload.firstName,
          lastName: payload.lastName,
        },
        config
      );
      let account = result.data.data;
      return account;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },

  initResetPassword: async (email) => {
    try {
      refreshConfig();

      let result = await httpClient.post(
        "/public/accounts/forgotPassword ",
        {
          email: email,
        },
        config
      );
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  resetPassword: async (payload) => {
    try {
      refreshConfig();

      let result = await httpClient.post(
        "/public/accounts/resetPassword ",
        payload,
        config
      );
    } catch (e) {
      console.log(e);
      throw e;
    }
  },

  //////////////////////// INVITATION Validity /////////////////////////////////////
  getInvitation: async (code) => {
    try {
      refreshConfig();
      let result = await httpClient.get(
        "/public/invitations?filters[code][$eq]=" + code,
        config
      );
      let invitations = result.data.data;
      if (invitations.length > 0) {
        return invitations[0];
      } else return null;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },

  //////////////////////// PROFILE MANAGEMENT /////////////////////////////////////
  getProfile: async () => {
    try {
      refreshConfig();
      let result = await httpClient.get("/account/me", config);
      let profile = result.data.data;
      return profile;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  setProfile: async (payload) => {
    try {
      refreshConfig();

      let result = await httpClient.put(
        "/account/me",
        {
          firstName: payload.firstName,
          lastName: payload.lastName,
          phoneNumber: payload.phoneNumber,
        },
        config
      );
      let profile = result.data.data;
      return profile;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  //////////////////////////// ORGANIZATION MANAGEMENT ///////////////////////////////

  getUserOrganization: async () => {
    try {
      refreshConfig();
      let result;
      let organization;
      result = await httpClient.get("/account/myOrganization", config);
      //console.log(result)
      organization = result.data.data;
      return organization;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },

  //////////////////////////// OPERATION MANAGEMENT ///////////////////////////////

  getOperationsCountByStatus: async () => {
    try {
      refreshConfig();
      let result = await httpClient.get("/operations/countByStatus", config);
      let count = result.data;
      return count;
    } catch (e) {
      throw e;
    }
  },

  getOperationById: async (operationId) => {
    try {
      refreshConfig();
      let result = await httpClient.get("/operations/" + operationId, config);
      let operation = result.data.data;
      return operation;
    } catch (e) {
      throw e;
    }
  },
  getOperations: async (options) => {
    try {
      refreshConfig();
      let result;
      /*
      var statusArraytemp=[];
      statusArraytemp.push(options.status)
      var statusArray;
        if(options.status){statusArray=statusArraytemp[0]}
        if(statusArray && options.lastName && options.firstName){
          result = await httpClient.get('/operations?sort[0]=createdAt:desc&pagination[limit]='+options.limit+"&pagination[start]="+options.start+"&status_contains="+statusArray[0]+"&status_contains="+statusArray[1]+"&status_contains="+statusArray[2]+"&status_contains="+statusArray[3]+"&status_contains="+statusArray[4]+"&status_contains="+statusArray[5]+"&status_contains="+statusArray[6]+"&category_contains="+options.category+"&assignee.firstName_contains="+options.firstName+"&assignee.lastName_contains="+options.lastName,config)        
        }
        else {
          //result = await httpClient.get('/operations?_sort=created_at:DESC&_limit='+options.limit+"&_start="+options.start+"&status_contains="+statusArray[0]+"&status_contains="+statusArray[1]+"&status_contains="+statusArray[2]+"&status_contains="+statusArray[3]+"&status_contains="+statusArray[4]+"&status_contains="+statusArray[5]+"&status_contains="+statusArray[6]+"&category_contains="+options.category,config)        
          result = await httpClient.get('/operations?sort[0]=createdAt:desc&pagination[limit]='+options.limit+"&pagination[start]="+options.start+"&status_contains="+statusArray[0]+"&status_contains="+statusArray[1]+"&status_contains="+statusArray[2]+"&status_contains="+statusArray[3]+"&status_contains="+statusArray[4]+"&status_contains="+statusArray[5]+"&status_contains="+statusArray[6]+"&category_contains="+options.category,config)        

        }
      /*else if(options.lastName && options.firstName){
        result = await httpClient.get('/operations?_sort=createdAt:DESC&_limit='+options.limit+"&_start="+options.start+"&status_contains="+"&category_contains="+options.category+"&assignee.firstName_contains="+options.firstName+"&assignee.lastName_contains="+options.lastName,config)   
      }
      else{
        result = await httpClient.get('/operations?_sort=createdAt:DESC&_limit='+options.limit+"&_start="+options.start+"&status_contains="+"&category_contains="+options.category,config)   
      }*/
      result = await httpClient.get(
        "/operations?populate=category&populate=priority&populate=status&populate=assignee&sort[0]=createdAt:desc&pagination[limit]=" +
        options.limit +
        "&pagination[start]=" +
        options.start,
        config
      );
      let operationsWithMeta = result.data;
      return operationsWithMeta;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  getOperationsToUpdate: async (options) => {
    try {
      refreshConfig();
      let result;
      /*
      var statusArraytemp=[];
      statusArraytemp.push(options.status)
      var statusArray;
        if(options.status){statusArray=statusArraytemp[0]}
        if(statusArray && options.lastName && options.firstName){
          result = await httpClient.get('/operations?sort[0]=createdAt:desc&pagination[limit]='+options.limit+"&pagination[start]="+options.start+"&status_contains="+statusArray[0]+"&status_contains="+statusArray[1]+"&status_contains="+statusArray[2]+"&status_contains="+statusArray[3]+"&status_contains="+statusArray[4]+"&status_contains="+statusArray[5]+"&status_contains="+statusArray[6]+"&category_contains="+options.category+"&assignee.firstName_contains="+options.firstName+"&assignee.lastName_contains="+options.lastName,config)        
        }
        else {
          //result = await httpClient.get('/operations?_sort=created_at:DESC&_limit='+options.limit+"&_start="+options.start+"&status_contains="+statusArray[0]+"&status_contains="+statusArray[1]+"&status_contains="+statusArray[2]+"&status_contains="+statusArray[3]+"&status_contains="+statusArray[4]+"&status_contains="+statusArray[5]+"&status_contains="+statusArray[6]+"&category_contains="+options.category,config)        
          result = await httpClient.get('/operations?sort[0]=createdAt:desc&pagination[limit]='+options.limit+"&pagination[start]="+options.start+"&status_contains="+statusArray[0]+"&status_contains="+statusArray[1]+"&status_contains="+statusArray[2]+"&status_contains="+statusArray[3]+"&status_contains="+statusArray[4]+"&status_contains="+statusArray[5]+"&status_contains="+statusArray[6]+"&category_contains="+options.category,config)        
 
        }
      /*else if(options.lastName && options.firstName){
        result = await httpClient.get('/operations?_sort=createdAt:DESC&_limit='+options.limit+"&_start="+options.start+"&status_contains="+"&category_contains="+options.category+"&assignee.firstName_contains="+options.firstName+"&assignee.lastName_contains="+options.lastName,config)   
      }
      else{
        result = await httpClient.get('/operations?_sort=createdAt:DESC&_limit='+options.limit+"&_start="+options.start+"&status_contains="+"&category_contains="+options.category,config)   
      }*/
      result = await httpClient.get(
        "/operations?populate=category&populate=priority&populate=status&populate=assignee&sort[0]=updatedAt:desc&pagination[limit]=" +
        options.limit +
        "&pagination[start]=" +
        options.start +
        "&filters[updatedAt][$lt]=" +
        options.limitDate +
        "&filters[events][updatedAt][$lt]=" +
        options.limitDate,
        config
      );
      let operationsWithMeta = result.data;
      return operationsWithMeta;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  searchOperation: async (keyword) => {
    let result = await httpClient.get(
      "/operations?_q=" +
      keyword +
      "&populate=category&populate=priority&populate=status&populate=assignee&sort[0]=createdAt:desc",
      config
    );
    return result.data.data;
  },
  setOperation: async (payload) => {
    try {
      refreshConfig();
      let result = await httpClient.post(
        "/operations",
        {
          description: payload.description,
          priority: payload.priority,
          status: payload.status,
          title: payload.title,
          category: payload.category,
          contact: payload.contact,
          location: payload.location,
          secondaryContact: payload.secondaryContact,
          customer: payload.customer
        },
        config
      );
      let operation = result.data;
      return operation;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  deleteOperation: async (operationId) => {
    try {
      refreshConfig();
      let result = await httpClient.delete(
        "/operations/" + operationId,
        config
      );
      let operation = result.data.data;
      return operation;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  updateOperation: async (id, payload) => {
    try {
      refreshConfig();
      let result = await httpClient.put("/operations/" + id, payload, config);
      let operation = result.data.data;
      return operation;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  ////////////////////////////// STATUS MANAGEMENT //////////////////////////////
  changeStatus: async (payload) => {
    try {
      refreshConfig();

      let result = await httpClient.put(
        "/operations/" + payload.operationId,
        {
          status: payload.status,
          description: payload.description,
        },
        config
      );
      let operation = result.data;
      return operation;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  getAllCount: async () => {
    let result;
    var statusCountArray = new Array();
    try {
      refreshConfig();
      for (var i = 0; i < configColor.Status.length; i++) {
        result = await httpClient.get(
          "/operations/count?filters[status][$eq]=" +
          configColor.Status[i].name,
          config
        );
        statusCountArray.push(result.data);
      }
      return statusCountArray;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  //////////////////////////////////////LANDING MANAGEMENT////////////////////////////
  getLandingbyId: async (landingId) => {
    if (landingId) {
      try {
        refreshConfig();
        let result = await httpClient.get("/landings/" + landingId, config);
        let landing = result.data;
        return landing;
      } catch (e) {
        console.log(e);
        throw e;
      }
    }
  },
  updateLanding: async (payload, landingId) => {
    try {
      refreshConfig();
      let result = await httpClient.put(
        "/landings/" + landingId,
        {
          address: payload.address,
          phone: payload.phone,
          description: payload.description,
          domain: payload.domain,
          title: payload.title,
          logo: payload.logo,
        },
        config
      );
      let landing = result.data.data;
      return landing;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  //////////////////////////////////// TEAM MANAGEMENT //////////////////////////////////////////////
  getTeamMembers: async () => {
    try {
      refreshConfig();
      let result1 = await httpClient.get("/account/me", config);
      let profile = result1.data;
      let result = await httpClient.get(
        "/organizations/" + profile.organization.id,
        config
      );
      let members = result.data;
      return members;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  setNewMember: async (payload) => {
    try {
      refreshConfig();
      let result = await httpClient.post(
        "/account/newmember/",
        {
          email: payload.email,
          firstName: payload.firstName,
          lastName: payload.lastName,
          password: payload.password,
        },
        config
      );
      let member = result.data.data;
      return member;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  editTeamMember: async (id, payload) => {
    try {
      refreshConfig();
      let result = await httpClient.put(
        "/accounts/" + id,
        {
          data: {
            firstName: payload.firstName,
            lastName: payload.lastName,
            role: payload.role,
          },
        },
        config
      );
      let member = result.data.data;
      return member;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  deleteTeamMember: async (id) => {
    try {
      refreshConfig();
      let result = await httpClient.delete("/accounts/" + id, config);
      let member = result.data.data;
      return member;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  setAssignee: async (payload) => {
    try {
      refreshConfig();
      let result = await httpClient.put(
        "/operations/" + payload.operationId,
        {
          assignee: payload.assignee,
        },
        config
      );
      let assignee = result.data;
      return assignee;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  //////////////////////////////////// CLIENT MANAGEMENT //////////////////////////////////////////////
  getClients: async () => {
    try {
      refreshConfig();
      let result = await httpClient.get("/clients", config);
      let clients = result.data;
      return clients;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  //////////////////////////////////// EVENTS MANAGEMENT //////////////////////////////////////////////
  setOperationEvent: async (payload) => {
    try {
      refreshConfig();
      let result = await httpClient.post(
        "/events/",
        {
          data: {
            operation: payload.id,
            startDate: payload.startDate,
            endDate: payload.endDate,
            title: payload.title,
            summary: payload.title,
            assignee: payload.assignee,
          },
        },
        config
      );
      let event = result.data.data;
      return event;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  getEventbyOperation: async (operationId) => {
    try {
      refreshConfig();
      let result = await httpClient.get(
        "/events?_sort=startDate:DESC&operation=" + operationId,
        config
      );
      let event = result.data.data;
      return event;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  getAllEvents: async (payload) => {
    //let options=payload.options
    let result;
    try {
      refreshConfig();
      /** 
      if(options.lastName && options.firstName){
        result = await httpClient.get('/events?_sort=startDate:DESC&operation.organization='+payload.id+"&operation.priority_contains="+options.priority+"&operation.category_contains="+options.category+"&assignee.firstName_contains="+options.firstName+"&assignee.lastName_contains="+options.lastName,config)        
      }
      else {
        result = await httpClient.get('/events?_sort=startDate:DESC&operation.organization='+payload.id+"&operation.priority_contains="+options.priority+"&operation.category_contains="+options.category,config)        
      }
      */
      //result = await httpClient.get('/events?_sort=startDate:DESC&operation.organization='+payload.id+"&operation.priority_contains="+options.priority+"&operation.category_contains="+options.category,config)
      result = await httpClient.get(
        "/events?populate=operation.organization&pagination[start]=0&pagination[limit]=100&sort[0]=createdAt:desc&filters[operation][organization][id][$eq]=" +
        payload.id,
        config
      );
      let events = result.data.data;
      return events;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  getEventbyAssignee: async (assigneeId) => {
    try {
      refreshConfig();
      let result = await httpClient.get(
        "/events?assignee=" + assigneeId,
        config
      );
      let event = result.data;
      return event;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  deleteOperationEvent: async (event) => {
    try {
      refreshConfig();
      let result = await httpClient.delete("/events/" + event.id, config);
      let event1 = result.data;
      return event1;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  editOperationEvent: async (payload) => {
    try {
      refreshConfig();
      let result = await httpClient.put(
        "/events/" + payload.idEvent,
        {
          data: {
            operation: payload.id,
            startDate: payload.startDate,
            endDate: payload.endDate,
            title: payload.title,
            summary: payload.title,
            assignee: payload.assignee,
          },
        },
        config
      );
      let event = result.data.data;
      return event;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  //////////////////////// UPLOAD MANAGEMENT //////////////////////////
  setOperationAttachments: async (payload) => {
    var formData = new FormData();
    /*
    let jsondata = {};
    jsondata.title = payload.title;
    jsondata.operation= payload.id;
    */
    formData.append(
      "data",
      '{"title":"' + payload.title + '","operation":"' + payload.id + '"}'
    );
    // formData.append('data', JSON.stringify(jsondata))
    let filesArray = [];
    for (const file of payload.media) {
      //filesArray.push(file.blob)
      formData.append("files.media", file.raw);
    }
    formData.append("files.path", payload.operationId);
    //formData.append('files.media',filesArray)

    try {
      refreshConfigFile();
      let result = await httpClient.post("/attachments", formData, config);
      let files = result.data;
      return files;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  setOperationNotes: async (payload) => {
    try {
      refreshConfig();
      let result = await httpClient.post(
        "/notes",
        {
          data: {
            operation: payload.id,
            title: payload.title,
            content: payload.description,
          },
        },
        config
      );
      let event = result.data.data;
      return event;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  editOperationNotes: async (payload) => {
    try {
      refreshConfig();
      let result = await httpClient.put(
        "/notes/" + payload.idNote,
        {
          data: {
            operation: payload.id,
            title: payload.title,
            content: payload.description,
          },
        },
        config
      );
      let event = result.data.data;
      return event;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },

  //-------- Settings (Priority)--------//
  addPriority: async (payload) => {
    try {
      refreshConfig();
      let result = await httpClient.post(
        "/priorities",
        {
          data: {
            configuration: payload.configuration,
            name: payload.name,
            label: payload.label,
            color: payload.color,
            ranking: payload.ranking,
          },
        },
        config
      );
      let priority = result.data.data;
      return priority;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },

  editPriority: async (id, payload) => {
    try {
      refreshConfig();
      let result = await httpClient.put(
        "/priorities/" + id,
        {
          data: {
            name: payload.name,
            label: payload.label,
            color: payload.color,
            ranking: payload.ranking,
          },
        },
        config
      );
      let priority = result.data.data;
      return priority;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  deletePriority: async (id) => {
    try {
      refreshConfig();
      let result = await httpClient.delete("/priorities/" + id, config);
      let priority = result.data.data;
      return priority;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },

  //-------- Settings (Status)--------//
  addStatus: async (payload) => {
    try {
      refreshConfig();
      let result = await httpClient.post(
        "/statuses",
        {
          data: {
            configuration: payload.configuration,
            name: payload.name,
            label: payload.label,
            color: payload.color,
            ranking: payload.ranking,
            default: payload.default,
            inProgress: payload.inProgress,
          },
        },
        config
      );
      let status = result.data.data;
      return status;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },

  editStatus: async (id, payload) => {
    try {
      refreshConfig();
      let result = await httpClient.put(
        "/statuses/" + id,
        {
          data: {
            name: payload.name,
            label: payload.label,
            color: payload.color,
            ranking: payload.ranking,
            default: payload.default,
            inProgress: payload.inProgress,
          },
        },
        config
      );
      let status = result.data.data;
      return status;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },

  deleteStatus: async (id) => {
    try {
      refreshConfig();
      let result = await httpClient.delete("/statuses/" + id, config);
      let status = result.data.data;
      return status;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },

  ////-------------- Config Management ---------- /////

  getConfiguration: async (configId) => {
    /* let result1 = await httpClient.get('/accounts/me',config)        
     let profile = result1.data
     let result = await httpClient.get('/organizations/'+profile.organization.id,config)  
     let organization = result.data*/
    if (configId) {
      try {
        refreshConfig();
        let result = await httpClient.get(
          "/configurations/" + configId,
          config
        );
        let configuration = result.data;
        return configuration;
      } catch (e) {
        console.log(e);
        throw e;
      }
    }
  },

  deleteCategory: async (catagoryId) => {
    try {
      refreshConfig();
      let result = await httpClient.delete("/categories/" + catagoryId, config);
      let category = result.data.data;
      return category;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  editCategory: async (id, payload) => {
    try {
      refreshConfig();
      let result = await httpClient.put(
        "/categories/" + id,
        {
          data: {
            name: payload.name,
            ranking: payload.ranking,
            label: payload.label,
          },
        },
        config
      );
      let category = result.data.data;
      return category;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  setCategory: async (payload) => {
    try {
      refreshConfig();
      let result = await httpClient.post(
        "/categories",
        {
          configuration: payload.id,
          name: payload.name,
          ranking: payload.ranking,
        },
        config
      );
      let category = result.data;
      return category;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  addCategory: async (payload) => {
    try {
      refreshConfig();
      let result = await httpClient.post(
        "/categories",
        {
          data: {
            configuration: payload.configurationId,
            name: payload.name,
            label: payload.label,
            ranking: payload.ranking,
          },
        },
        config
      );
      let category = result.data.data;
      return category;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },

  /** Customer Management */

  addCustomer: async (payload) => {
    try {
      refreshConfig();
      let result = await httpClient.post(
        "/customers",
        {
          data: payload,
        },
        config
      );
      let customer = result.data.data;
      return customer;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  editCustomer: async (id, payload) => {
    try {
      refreshConfig();
      let result = await httpClient.put(
        "/customers/" + id,
        {
          data: payload,
        },
        config
      );
      let customer = result.data.data;
      return customer;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  addMap: async (operations) => {
    let mapCoordinates = new Array();
    let result;
    try {
      refreshConfig();
      for (var i = 0; i < operations.length; i++) {
        if (
          operations[i].location != null &&
          operations[i].location != undefined
        ) {
          result = await axios.get(
            "https://nominatim.openstreetmap.org/search?format=json&street=" +
            operations[i].location.address +
            "&country=" +
            operations[i].location.country +
            "&postalcode=" +
            operations[i].location.zipCode +
            "&city=" +
            operations[i].location.city +
            "&addressdetails=1"
          );
          if (result.data[0] != undefined) {
            let objectCoord = new Object();
            objectCoord.lat = result.data[0].lat;
            objectCoord.lon = result.data[0].lon;
            objectCoord.title = operations[i].title;
            objectCoord.operationId = operations[i].id;
            objectCoord.id = i;
            mapCoordinates.push(objectCoord);
          }
        }
      }
      //console.log(mapCoordinates)

      return mapCoordinates;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  updatePassword: async (password) => {
    try {
      refreshConfig();
      let result = await httpClient.put(
        "/account/changePassword",
        {
          newPassword: password,
        },
        config
      );
      let account = result.data.data;
      return account;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  downloadAllOperations: async () => {
    try {
      refreshConfig();
      let result = await httpClient.get(
        "/download/operations?_sort=created_at:DESC&_limit=1000",
        config
      );
      // Adding the string "\ufeff" to the Blob is improtant to have special characters like (é, à) displayed correctly in Excel
      let url = window.URL.createObjectURL(new Blob(["\ufeff", result.data]));
      let link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "file.csv");
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      console.log(e);
    }
  },
  inviteCustomer: async (payload) => {
    try {
      refreshConfig();
      let result = await httpClient.post(
        "/invitation/customer",
        payload,
        config
      );
      return result;
    } catch (e) {
      throw e;
    }
  },

  /**************** Stripe interaction  *************/
  initStripePortal: async () => {
    try {
      refreshConfig();
      let result = await httpClient.post(
        "/organization/create-portal-session",
        null,
        config
      );
      return result.data;
    } catch (e) {
      throw e;
    }
  },
  initCheckoutSession: async (payload) => {
    try {
      refreshConfig();
      let result = await httpClient.post(
        "/organization/create-checkout-session",
        payload,
        config
      );
      return result.data;
    } catch (e) {
      throw e;
    }
  }


};

import {mutations} from './mutations'
import {actions} from './actions'
import {getters} from './getters'

export default {
  namespaced: true,
  state: {
    
    profile:{},
    isAuthenticated: false,

    //To be likely removed
    //account: {},
    displayAuthFailureMessage: false,
    displayAuthSuccessMessage:false,
    displayRegisFailureMessage:false,
    displayRegisSuccessMessage:false,
    passwordFailureMessage:false,
    isRegistered:false,
    profileLoaded:false,
    

  },
  actions,
  mutations,
  getters
}

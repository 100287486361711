<template>
  <div>
    <crud-table
      :data="customers"
      :columns="columns"
      :editFunction="editCustomer"
      :deleteFunction="deleteCustomer"
      :isRowEditable="isEditable"
      :isRowDeletable="isDeletable"
      :addFunction="addCustomer"
      :addFormSchema="this.addFormSchema"
      :editFormSchema="this.editFormSchema"
      :actions="actions"
    >
    </crud-table>
  </div>
</template>

<script>
import crudTable from "@/components/Global/crudTable";

export default {
  data() {
    return {
      columns: [
        {
          id: 1,
          prop: "organism",
          label: "Organisme",
          width: "180",
        },

        {
          id: 2,
          prop: "firstName",
          label: "Prénom",
          width: "180",
        },
        {
          id: 3,
          prop: "lastName",
          label: "Nom",
          width: "180",
        },
        {
          id: 4,
          prop: "email",
          label: "Email",
          width: "180",
        },
        {
          id: 5,
          prop: "phoneNumber",
          label: "Téléphone",
          width: "180",
        },
      ],
      addFormSchema: [
        {
          component: "h3",
          children: "Ajouter un client",
        },
        {
          type: "text",
          name: "organism",
          label: "Organisme",
          validation: "required",
        },
        {
          type: "text",
          name: "email",
          label: "Email",
          //validation: "required",
        },
        {
          type: "text",
          name: "firstName",
          label: "Prénom",
          //validation: "required",
        },
        {
          type: "text",
          name: "lastName",
          label: "Nom",
          //validation: "required",
        },
        {
          type: "text",
          name: "phoneNumber",
          label: "Téléphone",
          //validation: "required",
        },
        {
          type: "submit",
          label: "Ajouter",
        },
      ],
      editFormSchema: [
        {
          component: "h3",
          children: "Modifier un client",
        },
        {
          type: "text",
          name: "organism",
          label: "Organisme",
          validation: "required",
        },
        {
          type: "text",
          name: "email",
          label: "Email",
          disabled: true,
          //validation: "required",
        },
        {
          type: "text",
          name: "firstName",
          label: "Prénom",
          //validation: "required",
        },
        {
          type: "text",
          name: "lastName",
          label: "Nom",
          //validation: "required",
        },
        {
          type: "text",
          name: "phoneNumber",
          label: "Téléphone",
          //validation: "required",
        },

        {
          type: "submit",
          label: "Modifier",
        },
      ],
      actions: [
        {
          name: "Inviter au portail client",
          command: this.inviteCustomer,
        },
      ],
    };
  },
  components: {
    crudTable,
  },
  computed: {
    customers() {
      return this.$store.getters["contractor/getCustomers"];
    },
  },
  methods: {
    async addCustomer(customer) {
      //to-do add validation for password rules
      await this.$store.dispatch("contractor/addCustomer", customer);
      this.$store.dispatch("contractor/loadOrganization", customer);
    },
    async editCustomer(customer) {
      await this.$store.dispatch("contractor/editCustomer", customer);
      this.$store.dispatch("contractor/loadOrganization", customer);
    },
    async deleteCustomer(customer) {},
    isEditable(item) {
      return true;
    },
    isDeletable(item) {
      return false;
    },
    async inviteCustomer(customer) {
      await this.$store.dispatch("contractor/inviteCustomer", customer);
    },
  },
  created() {},
};
</script>

<template>
  <div>
    <card>
      <b-card-header>
        <h5>Personnaliser votre configuration</h5>
      </b-card-header>
      <div class="col-12">
        <el-tabs
          content-class="mt-3"
          v-model="activeTab"
          @tab-click="navigateTo"
        >
          <el-tab-pane label="Catégories" name="categories" active>
          </el-tab-pane>
          <el-tab-pane label="Status" name="statuses"> </el-tab-pane>
          <el-tab-pane label="Priorités" name="priorities"> </el-tab-pane>
        </el-tabs>
        <router-view></router-view>
      </div>
    </card>
  </div>
</template>
<script>
import config from "@/api/config.js";
import category from "./category.vue";
import priority from "./priority.vue";
import status from "./status.vue";
import popupPriority from "@/components/Popups/popupPriority";
import popupStatus from "@/components/Popups/popupStatus";
import popupCategory from "@/components/Popups/popupCategory";
import EventBus from "@/eventBus";

export default {
  name: "administration",

  data: function () {
    return {
      //activeTab:"categories"
    };
  },
  components: {
    category,
    priority,
    status,
    popupPriority,
    popupStatus,
    popupCategory,
  },
  computed: {
    configuration() {
      return config;
    },
    organization() {
      return this.$store.getters["contractor/getfilteredMembers"];
    },
    activeTab() {
      return this.$router.currentRoute.path.substring(
        this.$router.currentRoute.path.lastIndexOf("/") + 1
      );
    },
  },
  methods: {
    navigateTo(tab, event) {
      //console.log(tab, event);
      console.log(tab, event);
      /*
        switch(tab.name) {
            case "categories" :
                this.$router.push('/dashboard/settings/categories')
            case "priorities" :
                this.$router.push('/dashboard/settings/priorities')
            case "statuses" :
                this.$router.push('/dashboard/settings/statuses')
        }
        */
      if (tab.name == "priorities") {
        this.$router.push("/dashboard/settings/priorities");
      }
      if (tab.name == "categories") {
        this.$router.push("/dashboard/settings/categories");
      }
      if (tab.name == "statuses") {
        this.$router.push("/dashboard/settings/statuses");
      }
      if (tab.name == "table") {
        this.$router.push("/dashboard/settings/table");
      }
    },
    switchOnAdd() {
      this.add1 = true;
    },
    switchOnAddStatus() {
      this.add2 = true;
    },
    switchOnCategory() {
      this.add3 = true;
    },
  },
  created() {
    console.log(this.$router.currentRoute.path);
  },
  mounted() {},
};
</script>
<style scoped>
.card {
  background: transparent;
}
.title {
  font-size: 17px !important;
}
.headers {
  padding-right: 10px;
  font-size: 16px;
  color: rgba(88, 82, 82, 0.745);
  text-align: center;
}
@media screen and (max-width: 999px) {
  .headers > div {
    padding: 0px 5px 0px 5px;
  }
}
</style>
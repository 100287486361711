<template>
     <card class="card-container" v-bind:style="applyBackgroundColor(color)"> 
          <div class="row">
            <div class="col-6 title">
                {{title}}
            </div>
            <div class="col-3">
            </div>
            <div class="col-3 kpi">
              {{kpi}}
            </div>
          </div>
          
        </card>
</template>
<script>
import Card from "./Card.vue";

export default {
  name: "stats-card",
  components: {
    Card
  },
  props:{
    title: String,
    kpi: String,
    color: String
  },
  methods:{
    applyBackgroundColor(color){
      return "background-color:" + color;
    }
  }
};
</script>
<style scoped>


</style>

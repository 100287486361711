// Auth Mutations
export const SIGNUP = 'SIGNUP'
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS'
export const WEAK_PASSWORD = 'WEAK_PASSWORD'
export const SIGNUP_FAILURE = 'SIGNUP_FAILURE'
export const LOGIN = 'LOGIN'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'
export const LOGOUT = 'LOGOUT'
export const SET_ACCOUNT = 'SET_ACCOUNT'
export const INIT_AUTH_STATE = 'INIT_AUTH_STATE'

export const INIT_RESET_PASSWORD = 'INIT_RESET_PASSWORD'
export const INIT_RESET_PASSWORD_SUCCESS = 'INIT_RESET_PASSWORD_SUCCESS'
export const INIT_RESET_PASSWORD_FAILURE = 'INIT_RESET_PASSWORD_FAILURE'

export const RESET_PASSWORD = 'RESET_PASSWORD'
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS'
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE'

//Config Mutations
export const LOAD_APP = 'LOAD_APP'
export const LOAD_APP_SUCCESS = 'LOAD_APP_SUCCESS'
export const LOAD_APP_FAILURE = 'LOAD_APP_FAILURE'

export const SET_USER_TYPE = 'SET_USER_TYPE'
export const SET_CLIENT = 'SET_CLIENT'
export const SET_CONTRACTOR = 'SET_CONTRACTOR'
export const SET_SIDEBAR_LINKS = 'SET_SIDEBAR_LINKS'

//Profile Mutation
export const GET_PROFILE_BY_CONTRACTOR_SUCCESS = 'GET_PROFILE_SUCCESS_BY_CONTRACTOR_SUCCESS'
export const GET_PROFILE_BY_CONTRACTOR_FAILURE = 'GET_PROFILE_FAILURE_BY_CONTRACTOR_FAILURE'
export const SET_PROFILE_BY_CONTRACTOR_SUCCESS = 'SET_PROFILE_SUCCESS_BY_CONTRACTOR_SUCCESS'
export const SET_PROFILE_BY_CONTRACTOR_FAILURE = 'SET_PROFILE_FAILURE_BY_CONTRACTOR_FAILURE'


//organization Mutation 
export const GET_ORGANIZATION = 'GET_ORGANIZATION'
export const GET_ORGANIZATION_SUCCESS = 'GET_ORGANIZATION_SUCCESS'
export const GET_ORGANIZATION_FAILURE = 'GET_ORGANIZATION_FAILURE'

//Settings Mutations
export const SETTINGS_ADD_CATEGORY = 'SETTINGS_ADD_CATEGORY'
export const SETTINGS_ADD_CATEGORY_SUCCESS = 'SETTINGS_ADD_CATEGORY_SUCCESS'
export const SETTINGS_ADD_CATEGORY_FAILURE = 'SETTINGS_ADD_CATEGORY_FAILURE'

export const SETTINGS_EDIT_CATEGORY = 'SETTINGS_EDIT_CATEGORY'
export const SETTINGS_EDIT_CATEGORY_SUCCESS = 'SETTINGS_EDIT_CATEGORY_SUCCESS'
export const SETTINGS_EDIT_CATEGORY_FAILURE = 'SETTINGS_EDIT_CATEGORY_FAILURE'

export const SETTINGS_DELETE_CATEGORY = 'SETTINGS_DELETE_CATEGORY'
export const SETTINGS_DELETE_CATEGORY_SUCCESS = 'SETTINGS_DELETE_CATEGORY_SUCCESS'
export const SETTINGS_DELETE_CATEGORY_FAILURE = 'SETTINGS_DELETE_CATEGORY_FAILURE'


export const SETTINGS_ADD_PRIORITY = 'SETTINGS_ADD_PRIORITY'
export const SETTINGS_ADD_PRIORITY_SUCCESS = 'SETTINGS_ADD_PRIORITY_SUCCESS'
export const SETTINGS_ADD_PRIORITY_FAILURE = 'SETTINGS_ADD_PRIORITY_FAILURE'

export const SETTINGS_EDIT_PRIORITY = 'SETTINGS_EDIT_PRIORITY'
export const SETTINGS_EDIT_PRIORITY_SUCCESS = 'SETTINGS_EDIT_PRIORITY_SUCCESS'
export const SETTINGS_EDIT_PRIORITY_FAILURE = 'SETTINGS_EDIT_PRIORITY_FAILURE'

export const SETTINGS_DELETE_PRIORITY = 'SETTINGS_DELETE_PRIORITY'
export const SETTINGS_DELETE_PRIORITY_SUCCESS = 'SETTINGS_DELETE_PRIORITY_SUCCESS'
export const SETTINGS_DELETE_PRIORITY_FAILURE = 'SETTINGS_DELETE_PRIORITY_FAILURE'


export const SETTINGS_ADD_STATUS = 'SETTINGS_ADD_STATUS'
export const SETTINGS_ADD_STATUS_SUCCESS = 'SETTINGS_ADD_STATUS_SUCCESS'
export const SETTINGS_ADD_STATUS_FAILURE = 'SETTINGS_ADD_STATUS_FAILURE'

export const SETTINGS_EDIT_STATUS = 'SETTINGS_EDIT_STATUS'
export const SETTINGS_EDIT_STATUS_SUCCESS = 'SETTINGS_EDIT_STATUS_SUCCESS'
export const SETTINGS_EDIT_STATUS_FAILURE = 'SETTINGS_EDIT_STATUS_FAILURE'

export const SETTINGS_DELETE_STATUS = 'SETTINGS_DELETE_STATUS'
export const SETTINGS_DELETE_STATUS_SUCCESS = 'SETTINGS_DELETE_STATUS_SUCCESS'
export const SETTINGS_DELETE_STATUS_FAILURE = 'SETTINGS_DELETE_STATUS_FAILURE'

//Operations Mutation
export const INIT_OPERATIONS = 'INIT_OPERATIONS'
export const INIT_OPERATIONS_SUCCESS = 'INIT_OPERATIONS_SUCCESS'
export const INIT_OPERATIONS_FAILURE = 'INIT_OPERATIONS_FAILURE'

export const LOAD_OPERATIONS_COUNT = 'LOAD_OPERATIONS_COUNT'
export const LOAD_OPERATIONS_COUNT_SUCCESS = 'LOAD_OPERATIONS_COUNT_SUCCESS'
export const LOAD_OPERATIONS_COUNT_FAILURE = 'LOAD_OPERATIONS_COUNT_FAILURE'

export const GET_OPERATIONS_SUCCESS = 'GET_OPERATIONS_SUCCESS'
export const GET_OPERATIONS_FAILURE = 'GET_OPERATIONS_FAILURE'

export const GET_OPERATIONS_TO_UPDATE_SUCCESS = 'GET_OPERATIONS_TO_UPDATE_SUCCESS'
export const GET_OPERATIONS_TO_UPDATE_FAILURE = 'GET_OPERATIONS_TO_UPDATE_FAILURE'

export const SET_OPERATION_ID = 'SET_OPERATION_ID'
export const SET_OPERATION_BY_CONTRACTOR_FAILURE = 'SET_OPERATION_BY_CONTRACTOR_FAILURE'
export const SET_OPERATION_BY_CONTRACTOR_SUCCESS = 'SET_OPERATION_BY_CONTRACTOR_SUCCESS'

export const CHANGE_STATUS = 'CHANGE_STATUS'
export const CHANGE_STATUS_FAILURE = 'CHANGE_STATUS_FAILURE'

export const UPDATE_OPERATION = 'UPDATE_OPERATION'
export const UPDATE_OPERATION_SUCCESS = 'UPDATE_OPERATION_SUCCESS'
export const UPDATE_OPERATION_FAILURE = 'UPDATE_OPERATION_FAILURE'

export const UPDATE_OPRTATIONS_FILTER = 'UPDATE_OPRTATIONS_FILTER'
export const UPDATE_OPRTATIONS_FILTER_SUCCESS = 'UPDATE_OPRTATIONS_FILTER_SUCCESS'
export const UPDATE_OPRTATIONS_FILTER_FAILURE = 'UPDATE_OPRTATIONS_FILTER_FAILURE'


export const GET_ONE_OPERATION = 'GET_ONE_OPERATION'
export const GET_ONE_OPERATION_SUCCESS = 'GET_ONE_OPERATION_SUCCESS'
export const GET_ONE_OPERATION_FAILURE = 'GET_ONE_OPERATION_FAILURE'

export const SET_STATUS_SUCCESS = 'SET_STATUS_SUCCESS'
export const SET_STATUS_FAILURE = 'SET_STATUS_FAILURE'

export const SET_STATUS_COUNT = 'SET_STATUS_COUNT'

export const GET_MORE_OPERATIONS_BY_CONTRACTOR_SUCCESS = 'GET_MORE_OPERATIONS_SUCCESS_BY_CONTRACTOR_SUCCESS'
export const GET_MORE_OPERATIONS_BY_CONTRACTOR_FAILURE = 'GET_MORE_OPERATIONS_FAILURE_BY_CONTRACTOR_FAILURE'
export const SET_ALL_OPERTATIONS_LOADED = 'SET_ALL_OPERATIONS_LOADED'

export const DELETE_OPERATION = 'DELETE_OPERATION'
export const DELETE_OPERATION_SUCCESS = 'DELETE_OPERATION_SUCCESS'
export const DELETE_OPERATION_FAILURE = 'DELETE_OPERATION_FAILURE'


export const LOAD_FILTERED_OP = 'LOAD_FILTERED_OP'
export const GET_LANDING_SUCCESS = 'GET_LANDING_SUCCESS'
export const GET_LANDING_FAILURE = 'GET_LANDING_FAILURE'

export const UPDATE_LANDING_SUCCESS = 'UPDATE_LANDING_SUCCESS'
export const UPDATE_LANDING_FAILURE = 'UPDATE_LANDING_FAILURE'

//Team mutations

export const GET_TEAM_SUCCESS = 'GET_TEAM_SUCCESS'
export const GET_TEAM_FAILURE = 'GET_TEAM_FAILURE'

export const ADD_MEMBER_SUCCESS = 'ADD_MEMBER_SUCCESS'
export const ADD_MEMBER_FAILURE = 'ADD_MEMBER_FAILURE'

export const EDIT_MEMBER_SUCCESS = 'EDIT_MEMBER_SUCCESS'
export const EDIT_MEMBER_FAILURE = 'EDIT_MEMBER_FAILURE'

export const DELETE_MEMBER_SUCCESS = 'DELETE_MEMBER_SUCCESS'
export const DELETE_MEMBER_FAILURE = 'DELETE_MEMBER_FAILURE'

//Customers Mutations

export const ADD_CUSTOMER_SUCCESS = 'ADD_CUSTOMER_SUCCESS'
export const ADD_CUSTOMER_FAILURE = 'ADD_CUSTOMER_FAILURE'

export const EDIT_CUSTOMER_SUCCESS = 'EDIT_CUSTOMER_SUCCESS'
export const EDIT_CUSTOMER_FAILURE = 'EDIT_CUSTOMER_FAILURE'

export const SET_ASSIGNEE_SUCCESS = 'SET_ASSIGNEE_SUCCESS'
export const SET_ASSIGNEE_FAILURE = 'SET_ASSIGNEE_FAILURE'


export const GET_MORE_MEMBERS_BY_CONTRACTOR_SUCCESS = 'GET_MORE_MEMBERS_SUCCESS_BY_CONTRACTOR_SUCCESS'
export const GET_MORE_MEMBERS_BY_CONTRACTOR_FAILURE = 'GET_MORE_MEMBERS_FAILURE_BY_CONTRACTOR_FAILURE'
export const SET_ALL_MEMBERS_LOADED = 'SET_ALL_MEMBERS_LOADED'
export const LOAD_FILTERED_MEMBERS = 'LOAD_FILTERED_MEMBERS'

export const LOAD_CLIENTS_SUCCESS = 'LOAD_CLIENTS_SUCCESS'
export const LOAD_CLIENTS_FAILURE = 'LOAD_CLIENTS_FAILURE'
export const LOAD_FILTERED_CLIENTS = 'LOAD_FILTERED_CLIENTS'

export const ADD_EVENT_SUCCESS = 'ADD_EVENT_SUCCESS'
export const ADD_EVENT_FAILURE = 'ADD_EVENT_FAILURE'

export const GET_EVENT_SUCCESS = 'GET_EVENT_SUCCESS'
export const GET_EVENT_FAILURE = 'GET_EVENT_FAILURE'
export const GET_EVENTS_SUCCESS = 'GET_EVENTS_SUCCESS'
export const GET_EVENTS_FAILURE = 'GET_EVENTS_FAILURE'
export const GET_EVENT_BY_ASSIGNEE_SUCCESS = 'GET_EVENT_BY_ASSIGNEE_SUCCESS'
export const GET_EVENT_BY_ASSIGNEE_FAILURE = 'GET_EVENT_BY_ASSIGNEE_FAILURE'
export const DELETE_EVENT_FAILURE = 'DELETE_EVENT_FAILURE'
export const DELETE_EVENT_SUCCESS = 'DELETE_EVENT_SUCCESS'
export const EDIT_EVENT_SUCCESS = 'EDIT_EVENT_SUCCESS'
export const EDIT_EVENT_FAILURE = 'EDIT_EVENT_FAILURE'
export const ADD_EVENT_ELEMENT_SUCCESS = 'ADD_EVENT_ELEMENT_SUCCESS'



export const GET_TEAM_ARR_SUCCESS = 'GET_TEAM_ARR_SUCCESS'


export const ADD_ATTACHMENTS_SUCCESS = 'ADD_ATTACHMENTS_SUCCESS'
export const ADD_ATTACHMENTS_FAILURE = 'ADD_ATTACHMENTS_FAILURE'
export const ADD_NOTE_SUCCESS = 'ADD_NOTE_SUCCESS'
export const ADD_NOTE_FAILURE = 'ADD_NOTE_FAILURE'
export const SET_NOTE_SUCCESS = 'SET_NOTE_SUCCESS'

export const ADD_CONFIG_PRIORITY_SUCCESS = 'ADD_CONFIG_PRIORITY_SUCCESS'
export const ADD_CONFIG_PRIORITY_FAILURE = 'ADD_CONFIG_PRIORITY_FAILURE'
export const GET_PRIORITIES_SUCCESS = 'GET_PRIORITIES_SUCCESS'
export const GET_PRIORITIES_FAILURE = 'GET_PRIORITIES_FAILURE'
export const DELETE_PRIORITY_SUCCESS = 'DELETE_PRIORITY_SUCCESS'
export const DELETE_PRIORITY_FAILURE = 'DELETE_PRIORITY_FAILURE'
export const ADD_PRIOELEMENT_SUCCESS = 'ADD_PRIOELEMENT_SUCCESS'
export const EDIT_PRIORITY_SUCCESS = 'EDIT_PRIORITY_SUCCESS'
export const EDIT_PRIORITY_FAILURE = 'EDIT_PRIORITY_FAILURE'

export const DELETE_STATUS_SUCCESS = 'DELETE_STATUS_SUCCESS'
export const DELETE_STATUS_FAILURE = 'DELETE_STATUS_FAILURE'

export const EDIT_STATUSELEMENT_SUCCESS = 'EDIT_STATUSELEMENT_SUCCESS'
export const EDIT_STATUSELEMENT_FAILURE = 'EDIT_STATUSELEMENT_FAILURE'
export const ADD_STATUSELEMENT_SUCCESS = 'ADD_STATUSELEMENT_SUCCESS'

export const ADD_CONFIG_STATUS_SUCCESS = 'ADD_CONFIG_STATUS_SUCCESS'
export const ADD_CONFIG_STATUS_FAILURE = 'ADD_CONFIG_STATUS_FAILURE'

export const DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS'
export const DELETE_CATEGORY_FAILURE = 'DELETE_CATEGORY_FAILURE'
export const EDIT_CATEGORYELEMENT_SUCCESS = 'EDIT_CATEGORYELEMENT_SUCCESS'
export const EDIT_CATEGORYELEMENT_FAILURE = 'EDIT_CATEGORYELEMENT_FAILURE'
export const ADD_CATEGORYELEMENT_SUCCESS = 'ADD_CATEGORYELEMENT_SUCCESS'
export const ADD_CONFIG_CATEGORY_SUCCESS = 'ADD_CONFIG_CATEGORY_SUCCESS'
export const ADD_CONFIG_CATEGORY_FAILURE = 'ADD_CONFIG_CATEGORY_FAILURE'

export const UPDATE_OPERATION_BY_CONTRACTOR_SUCCESS = 'UPDATE_OPERATION_BY_CONTRACTOR_SUCCESS'
export const UPDATE_OPERATION_BY_CONTRACTOR_FAILURE = 'UPDATE_OPERATION_BY_CONTRACTOR_FAILURE'

export const LOAD_FILTERED_ASSIGNED_OP = 'LOAD_FILTERED_ASSIGNED_OP'
export const SET_COORDS_MAP_SUCCESS = 'SET_COORDS_MAP_SUCCESS'

export const UPDAYE_PW_SUCCESS = 'UPDAYE_PW_SUCCESS'
export const UPDAYE_PW_ERROR = 'UPDAYE_PW_ERROR'


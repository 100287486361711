<template>
  <div class="wrapper">
    <!--
    <div class="trial-banner">
      Votre essai gratuit expire le xx/yy
    </div>
    -->
    <!--
    <div v-if="loading" class="loader">
      loading Data
    </div>
    -->
    <loader v-if="loading"></loader>
    <expiration-page v-if="planExpired" :isTrial="isTrial"> </expiration-page>

    <demo-page v-if="displayDemo"></demo-page>

    <!-- we avoid displaying the sidebar before the end of data loading -->
    <side-bar v-if="!loading">
      <template slot="links">
        <sidebar-link
          to="/home"
          name="Accueil"
          icon="ti-home"
          :comingsoon="false"
          :unlocked="true"
        />
        <sidebar-link
          to="/dashboard/operations"
          name="Opérations"
          icon="ti-settings"
          :comingsoon="false"
          :unlocked="hasFeature('OPERATIONS')"
        />
        <!--
        <sidebar-link
          to="/dashboard/myOperations"
          name="Mes opérations"
          icon="ti-target"
          :comingsoon="false"
          :unlocked="hasFeature('OPERATIONS')"
        />
        -->
        <sidebar-link
          to="/dashboard/calendrier"
          name="Planning"
          icon="ti-calendar"
          :comingsoon="false"
          :unlocked="hasFeature('CALENDAR')"
        />
        <sidebar-link
          to="/dashboard/equipe"
          name="Équipe"
          icon="fa fa-group"
          :comingsoon="false"
          :unlocked="hasFeature('TEAM')"
        />
        <sidebar-link
          to="/dashboard/crm"
          name="Carnet clients"
          icon="fa fa-suitcase"
          :comingsoon="false"
          :unlocked="hasFeature('CLIENTS')"
        />

        <sidebar-link
          to="/dashboard/landing"
          name="Portail client"
          icon="ti-location-pin"
          :comingsoon="false"
          :unlocked="hasFeature('CLIENT_PORTAL')"
        />

        <sidebar-link
          to="/dashboard/projects"
          name="Projets"
          icon="ti-briefcase"
          :comingsoon="true"
          :unlocked="true"
        />

        <sidebar-link
          to="/dashboard/rapports"
          name="Cockpit"
          icon="ti-pulse"
          :comingsoon="true"
          :unlocked="true"
        />
      </template>
      <mobile-menu></mobile-menu>
    </side-bar>
    <div class="main-panel">
      <top-navbar></top-navbar>

      <dashboard-content @click.native="toggleSidebar"> </dashboard-content>

      <content-footer></content-footer>
    </div>
  </div>
</template>
<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import MobileMenu from "./MobileMenu";
import AuthService from "@/auth/AuthService";
import ExpirationPage from "@/components/Plan/Expiration";
import DemoPage from "@/components/onboarding/Demo";
import Loader from "@/components/Global/Loader";
export default {
  data() {
    return {
      islogged: AuthService.checkAuth("contractor"),
      loading: true,
      planExpired: false,
      isTrial: false,
      statusFilter: [
        "Nouveau",
        "Prête",
        "Préparation",
        "En cours",
        "Planifiée",
      ],
    };
  },
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
    MobileMenu,
    ExpirationPage,
    Loader,
    DemoPage,
  },
  computed: {
    displayDemo() {
      return this.$store.getters["contractor/displayDemo"];
    },
    /*
    operations() {
      return this.$store.getters["contractor/getOperations"];
    },
    */
  },
  methods: {
    hasFeature(feature) {
      let subscription = this.$store.getters["contractor/getSubscriptionInfo"];
      let availableFeatures = [];
      if (typeof subscription.features != "undefined") {
        availableFeatures = subscription.plan.features.concat(
          subscription.features
        );
      } else {
        availableFeatures = subscription.plan.features;
      }
      if (availableFeatures.includes(feature)) {
        return true;
      } else return false;
    },
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    // Log the user out
    logout() {
      this.$store.dispatch("auth/logout");
    },
    getOperationsCoordinates() {
      this.$store.dispatch("contractor/loadMap");
    },
  },
  async created() {
    //we load the organization with the current plan info
    await this.$store.dispatch("auth/loadProfile");
    await this.$store.dispatch("contractor/loadOrganization");
    //console.log('heere')
    let subscription = this.$store.getters["contractor/getSubscriptionInfo"];

    //if the current plan is expired, we display the plan expired banner
    let plan_expiry_date = new Date(subscription.plan_expiry_date);
    let today = new Date();
    if (subscription.plan.name === "trial") {
      this.isTrial = true;
    }
    if (today > plan_expiry_date) {
      this.planExpired = true;
    }

    this.loading = false;

    //if not, then we display the dashboard

    await this.$store.dispatch("contractor/loadOperationsCount");

    // await this.$store.dispatch("contractor/confirmAppLoaded");
    // await this.$store.dispatch("contractor/loadTeamList");

    let vm = this;
    setTimeout(function () {
      vm.$store.dispatch("contractor/loadMap");
    }, 2000);
    /*
    setTimeout(function () {
      vm.$store.dispatch("contractor/loadConfig");
    }, 1000);
    */
    //this.$store.dispatch("contractor/loadLanding");

    let options = {
      limit: 20,
      start: 0,
      status: this.statusFilter,
      priority: "",
      category: "",
      firstName: "",
      lastName: "",
    };
    this.$store.dispatch("contractor/loadEvents", options);
    //this.$store.dispatch("contractor/loadOperations", options);
    this.$store.dispatch("contractor/initOperations");
    this.$store.dispatch("contractor/loadMap");
  },
};
</script>
<style>
.nav-item {
  padding-left: 10px;
}
.loader {
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 1000;
  background-color: azure;
}
</style>

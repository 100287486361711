import {
  GET_OPERATIONS_SUCCESS,
  GET_OPERATIONS_FAILURE,
  SET_OPERATION_ID,
  SET_OPERATION_BY_CONTRACTOR_SUCCESS,
  SET_OPERATION_BY_CONTRACTOR_FAILURE,
  GET_ONE_OPERATION,
  GET_ONE_OPERATION_SUCCESS,
  GET_ONE_OPERATION_FAILURE,
  SET_STATUS_SUCCESS,
  SET_STATUS_FAILURE,
  SET_STATUS_COUNT,
  GET_MORE_OPERATIONS_BY_CONTRACTOR_SUCCESS,
  GET_MORE_OPERATIONS_BY_CONTRACTOR_FAILURE,
  SET_ALL_OPERTATIONS_LOADED,
  LOAD_FILTERED_OP,
  GET_TEAM_SUCCESS,
  GET_TEAM_FAILURE,

  SET_ASSIGNEE_SUCCESS,
  SET_ASSIGNEE_FAILURE,
  GET_MORE_MEMBERS_BY_CONTRACTOR_SUCCESS,
  GET_MORE_MEMBERS_BY_CONTRACTOR_FAILURE,
  SET_ALL_MEMBERS_LOADED,
  LOAD_FILTERED_MEMBERS,
  LOAD_CLIENTS_SUCCESS,
  LOAD_CLIENTS_FAILURE,
  LOAD_FILTERED_CLIENTS,
  ADD_EVENT_SUCCESS,
  ADD_EVENT_FAILURE,
  GET_EVENT_SUCCESS,
  GET_EVENT_FAILURE,
  GET_EVENT_BY_ASSIGNEE_SUCCESS,
  GET_EVENT_BY_ASSIGNEE_FAILURE,
  GET_TEAM_ARR_SUCCESS,
  DELETE_EVENT_SUCCESS,
  DELETE_EVENT_FAILURE,
  EDIT_EVENT_SUCCESS,
  EDIT_EVENT_FAILURE,
  ADD_ATTACHMENTS_SUCCESS,
  ADD_ATTACHMENTS_FAILURE,
  GET_EVENTS_SUCCESS,
  GET_EVENTS_FAILURE,
  ADD_NOTE_SUCCESS,
  ADD_NOTE_FAILURE,
  ADD_CONFIG_PRIORITY_SUCCESS,
  ADD_CONFIG_PRIORITY_FAILURE,
  GET_PRIORITIES_SUCCESS,
  GET_PRIORITIES_FAILURE,
  DELETE_PRIORITY_SUCCESS,
  DELETE_PRIORITY_FAILURE,
  ADD_PRIOELEMENT_SUCCESS,
  EDIT_PRIORITY_SUCCESS,
  EDIT_PRIORITY_FAILURE,
  DELETE_STATUS_SUCCESS,
  DELETE_STATUS_FAILURE,
  EDIT_STATUSELEMENT_SUCCESS,
  EDIT_STATUSELEMENT_FAILURE,
  ADD_STATUSELEMENT_SUCCESS,
  ADD_CONFIG_STATUS_SUCCESS,
  ADD_CONFIG_STATUS_FAILURE,
  DELETE_CATEGORY_SUCCESS,
  DELETE_CATEGORY_FAILURE,
  EDIT_CATEGORYELEMENT_SUCCESS,
  EDIT_CATEGORYELEMENT_FAILURE,
  ADD_CATEGORYELEMENT_SUCCESS,
  ADD_CONFIG_CATEGORY_SUCCESS,
  ADD_CONFIG_CATEGORY_FAILURE,
  SET_NOTE_SUCCESS,
  GET_LANDING_SUCCESS,
  GET_LANDING_FAILURE,
  UPDATE_LANDING_SUCCESS,
  UPDATE_LANDING_FAILURE,
  UPDATE_OPERATION_BY_CONTRACTOR_SUCCESS,
  UPDATE_OPERATION_BY_CONTRACTOR_FAILURE,
  LOAD_FILTERED_ASSIGNED_OP,
  ADD_EVENT_ELEMENT_SUCCESS,
  SET_COORDS_MAP_SUCCESS,
  DELETE_OPERATION,
  GET_ORGANIZATION,
  GET_ORGANIZATION_SUCCESS,
  GET_ORGANIZATION_FAILURE,


  LOAD_OPERATIONS_COUNT,
  LOAD_OPERATIONS_COUNT_SUCCESS,
  LOAD_OPERATIONS_COUNT_FAILURE,

  //Settings - Category
  SETTINGS_ADD_CATEGORY,
  SETTINGS_ADD_CATEGORY_SUCCESS,
  SETTINGS_ADD_CATEGORY_FAILURE,
  SETTINGS_EDIT_CATEGORY,
  SETTINGS_EDIT_CATEGORY_SUCCESS,
  SETTINGS_EDIT_CATEGORY_FAILURE,
  SETTINGS_DELETE_CATEGORY,
  SETTINGS_DELETE_CATEGORY_SUCCESS,
  SETTINGS_DELETE_CATEGORY_FAILURE,

  //Settings - Priority
  SETTINGS_ADD_PRIORITY,
  SETTINGS_ADD_PRIORITY_SUCCESS,
  SETTINGS_ADD_PRIORITY_FAILURE,
  SETTINGS_EDIT_PRIORITY,
  SETTINGS_EDIT_PRIORITY_SUCCESS,
  SETTINGS_EDIT_PRIORITY_FAILURE,
  SETTINGS_DELETE_PRIORITY,
  SETTINGS_DELETE_PRIORITY_SUCCESS,
  SETTINGS_DELETE_PRIORITY_FAILURE,

  //Settings Status
  SETTINGS_ADD_STATUS,
  SETTINGS_ADD_STATUS_SUCCESS,
  SETTINGS_ADD_STATUS_FAILURE,
  SETTINGS_EDIT_STATUS,
  SETTINGS_EDIT_STATUS_SUCCESS,
  SETTINGS_EDIT_STATUS_FAILURE,
  SETTINGS_DELETE_STATUS,
  SETTINGS_DELETE_STATUS_SUCCESS,
  SETTINGS_DELETE_STATUS_FAILURE,

  //Init Operations
  INIT_OPERATIONS,
  INIT_OPERATIONS_SUCCESS,
  INIT_OPERATIONS_FAILURE,

  //Operations - update filter
  UPDATE_OPRTATIONS_FILTER,
  UPDATE_OPRTATIONS_FILTER_SUCCESS,
  UPDATE_OPRTATIONS_FILTER_FAILURE,

  //Update operation
  UPDATE_OPERATION,
  UPDATE_OPERATION_SUCCESS,
  UPDATE_OPERATION_FAILURE,

  //Operations to update (widget)
  GET_OPERATIONS_TO_UPDATE_SUCCESS,
  GET_OPERATIONS_TO_UPDATE_FAILURE,

  //Add team members 
  ADD_MEMBER_SUCCESS,
  ADD_MEMBER_FAILURE,

  //Edit team members 
  EDIT_MEMBER_SUCCESS,
  EDIT_MEMBER_FAILURE,

  //Delete team members 
  DELETE_MEMBER_SUCCESS,
  DELETE_MEMBER_FAILURE,

  //Add Customer
  ADD_CUSTOMER_SUCCESS,
  ADD_CUSTOMER_FAILURE,

  //Edit Customer
  EDIT_CUSTOMER_SUCCESS,
  EDIT_CUSTOMER_FAILURE,



}
  from '../../mutation-types'

export const mutations = {
  [GET_ORGANIZATION]: function (state) {
    //
  },
  [GET_ORGANIZATION_SUCCESS]: function (state, payload) {
    state.organization = payload;
    state.organizationId = payload.id
    state.landingId = payload.landing.id
    state.landing = payload.landing
    state.teamMembers = payload.accounts
    state.configurationId = payload.configuration.id
    state.customers = payload.customers
    //sort statuses 
    payload.configuration.statuses.sort(function (a, b) {
      return a.ranking - b.ranking;
    }
    )
    state.configuration = payload.configuration

    state.appLoaded = true
    //we init the statuses count array with the statuses array without count to have a good display 
    //state.operationsCountByStatus = payload.configuration.statuses
  },

  [GET_ORGANIZATION_FAILURE]: function (state) {
    //
  },
  [GET_OPERATIONS_SUCCESS]: function (state, payload) {
    // console.log(payload)
    state.operations.data.push(...payload.data);
    state.operations.meta.pagination.start = payload.meta.pagination.start
    state.operations.meta.pagination.total = payload.meta.pagination.total

    //operationsLoaded=true;
  },


  [LOAD_OPERATIONS_COUNT]: function (state) {
    //
  },
  [LOAD_OPERATIONS_COUNT_SUCCESS]: function (state, payload) {
    //we sort the statuses by ranking 
    payload.sort(function (a, b) {
      return a.ranking - b.ranking;
    }
    )
    //and we set the operationCountByStatus array
    state.operationsCountByStatus = payload
  },
  [LOAD_OPERATIONS_COUNT_FAILURE]: function (state) {
    //
  },

  //------------- Init Operations ------------//
  [INIT_OPERATIONS]: function (state) {
    state.operations.data = [];
    state.operations.meta.pagination.start = 0;
    state.loader.operationsList = true
  },
  [INIT_OPERATIONS_SUCCESS]: function (state, operationsWithMeta) {
    state.operations.data = operationsWithMeta.data,
      state.operations.meta.pagination.total = operationsWithMeta.meta.pagination.total
    state.loader.operationsList = false
  },
  [INIT_OPERATIONS_FAILURE]: function (state) {
    state.loader.operationsList = false
  },

  //------------- Update Operation ------------//
  [UPDATE_OPERATION]: function (state) { },
  [UPDATE_OPERATION_SUCCESS]: function (state, operation) {
    state.currentOperation = operation
  },
  [UPDATE_OPERATION_FAILURE]: function (state) { },

  //------- GET OPERATIONS TO UPDATE -------//
  [GET_OPERATIONS_TO_UPDATE_SUCCESS]: function (state, operationsWithMeta) {
    state.operationsViews.operationsToUpdate.data = operationsWithMeta.data,
      state.operationsViews.operationsToUpdate.meta.pagination.total = operationsWithMeta.meta.pagination.total
  },

  [GET_OPERATIONS_TO_UPDATE_FAILURE]: function (state) { },


  //------------- SETTINGS (Category) ------------//
  [SETTINGS_ADD_CATEGORY]: function (state) { },
  [SETTINGS_ADD_CATEGORY_SUCCESS]: function (state) { },
  [SETTINGS_ADD_CATEGORY_FAILURE]: function (state) { },
  [SETTINGS_EDIT_CATEGORY]: function (state) { },
  [SETTINGS_EDIT_CATEGORY_SUCCESS]: function (state) { },
  [SETTINGS_EDIT_CATEGORY_FAILURE]: function (state) { },
  [SETTINGS_DELETE_CATEGORY]: function (state) { },
  [SETTINGS_DELETE_CATEGORY_SUCCESS]: function (state) { },
  [SETTINGS_DELETE_CATEGORY_FAILURE]: function (state) { },

  //----------SETTINGS (Priority) ------------//

  [SETTINGS_ADD_PRIORITY]: function (state) { },
  [SETTINGS_ADD_PRIORITY_SUCCESS]: function (state) { },
  [SETTINGS_ADD_PRIORITY_FAILURE]: function (state) { },
  [SETTINGS_EDIT_PRIORITY]: function (state) { },
  [SETTINGS_EDIT_PRIORITY_SUCCESS]: function (state) { },
  [SETTINGS_EDIT_PRIORITY_FAILURE]: function (state) { },
  [SETTINGS_DELETE_PRIORITY]: function (state) { },
  [SETTINGS_DELETE_PRIORITY_SUCCESS]: function (state) { },
  [SETTINGS_DELETE_PRIORITY_FAILURE]: function (state) { },

  //----------SETTINGS (Status) ------------//

  [SETTINGS_ADD_STATUS]: function (state) { },
  [SETTINGS_ADD_STATUS_SUCCESS]: function (state) { },
  [SETTINGS_ADD_STATUS_FAILURE]: function (state) { },
  [SETTINGS_EDIT_STATUS]: function (state) { },
  [SETTINGS_EDIT_STATUS_SUCCESS]: function (state) { },
  [SETTINGS_EDIT_STATUS_FAILURE]: function (state) { },
  [SETTINGS_DELETE_STATUS]: function (state) { },
  [SETTINGS_DELETE_STATUS_SUCCESS]: function (state) { },
  [SETTINGS_DELETE_STATUS_FAILURE]: function (state) { },

  //----------Update operations Filter ------------//
  [UPDATE_OPRTATIONS_FILTER]: function (state) { },
  [UPDATE_OPRTATIONS_FILTER_SUCCESS]: function (state, payload) {
    state.operationsFilter = payload.filter
    state.filteredOperations = payload.filteredOperations
  },
  [UPDATE_OPRTATIONS_FILTER_FAILURE]: function (state) { },

  //---------- Add/Edit Customer ------------//

  [ADD_CUSTOMER_SUCCESS]: function (state) { },
  [ADD_CUSTOMER_FAILURE]: function (state) { },
  [EDIT_CUSTOMER_SUCCESS]: function (state) { },
  [EDIT_CUSTOMER_FAILURE]: function (state) { },

  //--------------------------//

  [GET_OPERATIONS_FAILURE]: function (state) {
    //state.operationsLoaded=false;
  },
  [SET_OPERATION_ID]: function (state, payload) {
    state.operationId = payload;
  },
  [SET_OPERATION_BY_CONTRACTOR_SUCCESS]: function (state, payload) {
    //state.operationAdded=true
    //state.newOperation=payload;
    //state.operations.push(payload)
    //state.newOperation={};
  },
  [SET_OPERATION_BY_CONTRACTOR_FAILURE]: function (state) {
    state.operationAdded = false
  },
  [GET_ONE_OPERATION_SUCCESS]: function (state, payload) {
    state.currentOperation = payload
  },
  [GET_ONE_OPERATION_FAILURE]: function (state) {
    //
  },
  [GET_ONE_OPERATION]: function (state) {
    state.currentOperation = {}
  },
  [SET_STATUS_SUCCESS]: function (state) {
    state.statusChanged = true
  },
  [SET_STATUS_FAILURE]: function (state) {
    state.statusChanged = false
  },
  [SET_STATUS_COUNT]: function (state, payload) {
    for (var i = payload.allCounts.length - 1; i >= 0; i--) {
      state.statusCounts.splice(
        0,
        0,
        payload.allCounts[i]
      )
    }
  },
  [GET_MORE_OPERATIONS_BY_CONTRACTOR_SUCCESS]: function (state, payload) {
    state.operations = state.operations.concat(payload)
  },
  [GET_MORE_OPERATIONS_BY_CONTRACTOR_FAILURE]: function (state) {
    // state.operationsLoaded = false
  },
  [SET_ALL_OPERTATIONS_LOADED]: function (state) {
    state.operationsLoaded = true;
  },
  [GET_MORE_MEMBERS_BY_CONTRACTOR_SUCCESS]: function (state, payload) {
    state.teamMembers = state.teamMembers.concat(payload)
  },
  [GET_MORE_MEMBERS_BY_CONTRACTOR_FAILURE]: function (state) {
    // state.operations = payload
  },
  [SET_ALL_MEMBERS_LOADED]: function (state) {
    state.teamLoaded = true
  },
  [LOAD_FILTERED_OP]: function (state, filteredOperations) {
    state.filteredOperations = filteredOperations
  },
  [LOAD_FILTERED_MEMBERS]: function (state, filteredMembers) {
    state.filteredMembers = filteredMembers
  },
  [LOAD_FILTERED_ASSIGNED_OP]: function (state, filteredOperations) {
    state.filteredassignedOperations = filteredOperations
  },
  [GET_TEAM_SUCCESS]: function (state, team) {
    state.teamMembers = team
  },
  [GET_TEAM_ARR_SUCCESS]: function (state, team) {
    state.teamMembers2 = team.accounts
    state.organizationId = team.id
    state.configurationId = team.configuration.id
    state.landingId = team.landing.id
  },
  [GET_TEAM_FAILURE]: function (state) {
    //
  },
  [ADD_MEMBER_SUCCESS]: function (state, payload) {
    state.memberAdded = true;
    state.newMember = payload;
    state.teamMembers.push(state.newMember);
    state.newMember = {};
  },
  [ADD_MEMBER_FAILURE]: function (state) {
    state.memberAdded = false;
  },
  [EDIT_MEMBER_SUCCESS]: function (state, payload) {

  },
  [EDIT_MEMBER_FAILURE]: function (state) {

  },
  [DELETE_MEMBER_SUCCESS]: function (state, payload) {

  },
  [DELETE_MEMBER_FAILURE]: function (state) {

  },

  [SET_ASSIGNEE_SUCCESS]: function (state) {
    state.operationAssigned = true;
  },
  [SET_ASSIGNEE_FAILURE]: function (state) {
    state.operationAssigned = false;
  },
  [LOAD_CLIENTS_SUCCESS]: function (state, clients) {
    state.clients = clients;
  },
  [LOAD_CLIENTS_FAILURE]: function (state) {
    //    
  },
  [LOAD_FILTERED_CLIENTS]: function (state, filteredClients) {
    state.filteredClients = filteredClients
  },
  [ADD_EVENT_SUCCESS]: function (state, payload) {
    state.newEvent = payload;
    state.eventUploaded = true;
    state.newEvent = {}
  },
  [ADD_EVENT_ELEMENT_SUCCESS]: function (state, payload) {
    state.newEvent = payload;
  },
  [ADD_EVENT_FAILURE]: function (state) {
    state.eventUploaded = false
  },
  [GET_EVENT_SUCCESS]: function (state, event) {
    state.currentEvent = event;
  },
  [GET_EVENT_FAILURE]: function (state) {
    //    
  },
  [GET_EVENTS_SUCCESS]: function (state, events) {
    state.allEvents = events;
  },
  [GET_EVENTS_FAILURE]: function (state) {
    //    
  },
  [GET_EVENT_BY_ASSIGNEE_SUCCESS]: function (state, event) {
    state.assigneeEvent = event
  },
  [GET_EVENT_BY_ASSIGNEE_FAILURE]: function (state) {
    //
  },
  [DELETE_EVENT_SUCCESS]: function (state) {
    //    state.eventDeleted=true
  },
  [DELETE_EVENT_FAILURE]: function (state) {
    //
  },
  [EDIT_EVENT_SUCCESS]: function (state) {
    //    state.eventUpdated=true,
    state.newEvent = {}
  },
  [EDIT_EVENT_FAILURE]: function (state) {
    //
  },
  [ADD_ATTACHMENTS_SUCCESS]: function (state, attachments) {
    state.media = attachments.media
    state.attachmentUploaded = true
    state.media = {}
  },
  [ADD_ATTACHMENTS_FAILURE]: function (state) {
    //   
  },
  [ADD_NOTE_SUCCESS]: function (state, payload) {
    state.notes = payload
  },
  [ADD_NOTE_FAILURE]: function (state) {
    //   
  },
  [SET_NOTE_SUCCESS]: function (state, note) {
    state.note = note
  },
  [ADD_CONFIG_PRIORITY_SUCCESS]: function (state, payload) {
    state.ElementPriority = payload
    state.ElementPriority = {}
  },
  [ADD_CONFIG_PRIORITY_FAILURE]: function (state) {
    //   
  },
  [GET_PRIORITIES_SUCCESS]: function (state, payload) {
    state.priorities = payload.priorities
    state.statuses = payload.statuses
    state.categories = payload.categories
  },
  [GET_PRIORITIES_FAILURE]: function (state) {
    //   
  },
  [DELETE_PRIORITY_SUCCESS]: function (state, payload) {
    //    
  },
  [DELETE_PRIORITY_FAILURE]: function (state) {
    //   
  },
  [ADD_PRIOELEMENT_SUCCESS]: function (state, payload) {
    state.ElementPriority = payload
  },
  [EDIT_PRIORITY_SUCCESS]: function (state) {
    state.ElementPriority = {}
  },
  [EDIT_PRIORITY_FAILURE]: function (state, payload) {
    //
  },
  [DELETE_STATUS_SUCCESS]: function (state) {
    //
  },
  [DELETE_STATUS_FAILURE]: function (state, payload) {
    //
  },
  [ADD_STATUSELEMENT_SUCCESS]: function (state, payload) {
    state.ElementStatus = payload
  },
  [EDIT_STATUSELEMENT_SUCCESS]: function (state) {
    state.ElementStatus = {}
  },
  [EDIT_STATUSELEMENT_FAILURE]: function (state, payload) {
    //
  },
  [ADD_CONFIG_STATUS_SUCCESS]: function (state, payload) {
    state.ElementStatus = payload
    state.ElementStatus = {}
  },
  [ADD_CONFIG_STATUS_FAILURE]: function (state) {
    //   
  },
  [DELETE_CATEGORY_SUCCESS]: function (state) {
    //
  },
  [DELETE_CATEGORY_FAILURE]: function (state, payload) {
    //
  },
  [ADD_CATEGORYELEMENT_SUCCESS]: function (state, payload) {
    state.ElementCategory = payload
  },
  [EDIT_CATEGORYELEMENT_SUCCESS]: function (state) {
    state.ElementCategory = {}
  },
  [EDIT_CATEGORYELEMENT_FAILURE]: function (state, payload) {
    //
  },
  [ADD_CONFIG_CATEGORY_SUCCESS]: function (state, payload) {
    state.ElementCategory = payload
    state.ElementCategory = {}
  },
  [ADD_CONFIG_CATEGORY_FAILURE]: function (state) {
    //   
  },
  [GET_LANDING_SUCCESS]: function (state, payload) {
    state.landing = payload;
  },
  [GET_LANDING_FAILURE]: function (state, payload) {
    //
  },
  [UPDATE_LANDING_SUCCESS]: function (state, payload) {
    state.landing = payload;
    state.landingUpdated = true;
  },
  [UPDATE_LANDING_FAILURE]: function (state, payload) {
    //
  },
  [UPDATE_OPERATION_BY_CONTRACTOR_SUCCESS]: function (state, payload) {
    state.newOperation = payload
  },
  [UPDATE_OPERATION_BY_CONTRACTOR_FAILURE]: function (state, payload) {
    //
  },
  [SET_COORDS_MAP_SUCCESS]: function (state, payload) {
    state.mapCoords = payload
  },
  [DELETE_OPERATION]: function (state, payload) {
    state.newOperation = {}
  },
}

<template>
  <div class="col-12 filter-card">
    <div class="row">
      <div class="col-2">
        <el-button v-if="!filterDisplayed" round v-on:click="displayFilter()"
          >Filtrer</el-button
        >
        <div v-show="isFilteringEnabled()" class="filter-counter">
          {{ filterCount }}
        </div>
      </div>
      <!--
            <el-button class="col-2" round v-on:click="navigateTo('/dashboard/myOperations')">Mes Opérations</el-button>            
            -->
    </div>
    <card v-if="filterDisplayed" class="filter">
      <div class="row">
        <div class="col-md-3 col-12">
          <el-select
            v-model="filter.statuses"
            placeholder="Statut"
            :multiple="true"
            @change="filterList()"
            value-key="id"
          >
            <!--
                <el-option :value="emptyList">Tous</el-option>
                -->
            <el-option
              v-for="item in statuses"
              :key="item.id"
              :label="item.label"
              :value="item"
            >
            </el-option>
          </el-select>
        </div>
        <div class="col-md-3 col-12">
          <el-select
            v-model="filter.category"
            placeholder="Catégorie"
            @change="filterList()"
            value-key="id"
          >
            <el-option :value="empty">Tous</el-option>
            <el-option
              v-for="item in categories"
              :key="item.id"
              :label="item.label"
              :value="item"
            >
            </el-option>
          </el-select>
        </div>
        <div class="col-md-3 col-12">
          <el-select
            v-model="filter.assignee"
            placeholder="Intervenant"
            @change="filterList()"
            value-key="id"
          >
            <el-option :value="empty">Tous</el-option>
            <el-option
              v-for="item in assignees"
              :key="item.id"
              :label="item.firstName"
              :value="item"
            >
            </el-option>
          </el-select>
        </div>
        <!--
        <div class="col-md-3 col-12">
            <el-select v-model="filter.department" placeholder="Département" @change="filterList()" value-key="id">
                <el-option :value="empty">Tous</el-option>
                <el-option
                v-for="item in departments"
                :key="item.code"
                :label="displayDepartmentLabel(item)"
                :value="item">
                </el-option>
            </el-select>
        </div>
        -->
        <div class="col-md-2 col-6">
          <el-button round v-on:click="reset()">Rénitisaliser</el-button>
        </div>
        <div class="col-md-1 col-6">
          <el-button
            circle
            icon="el-icon-close"
            v-on:click="hideFilter()"
          ></el-button>
        </div>
      </div>
    </card>
  </div>
</template>
<script>
import EventBus from "@/eventBus";
import utils from "@/utils/index.js";

export default {
  name: "operations-filter",
  data: function () {
    return {
      /*
                statusFilter: [],
                categoryFilter: '',
                assigneeFilter: '',
                */
      departments: [],
      filterDisplayed: false,
      emptyObject: {},
      emptyList: [],
      empty: "",
      filter: {
        statuses: "",
        assignee: "",
        category: "",
        department: "",
      },
    };
  },
  computed: {
    statuses() {
      return this.$store.getters["contractor/getStatuses"];
    },
    categories() {
      return this.$store.getters["contractor/getCategories"];
    },
    assignees() {
      return this.$store.getters["contractor/getTeamMembers"];
    },
    stats() {
      let operations = this.$store.getters["contractor/getOperations"];
      let operationsWithMeta =
        this.$store.getters["contractor/getOperationsWithMeta"];
      let filteredOperations =
        this.$store.getters["contractor/getFilteredOperations"];
      return {
        total: operationsWithMeta.meta.pagination.total,
        loaded: operations.length,
        filtered: filteredOperations.length,
      };
    },
    filterCount() {
      let count = this.filter.statuses.length;
      return count;
    },
  },
  methods: {
    filterList() {
      /*
        let options = {
            limit: 10,
            start: 0,
            status:this.statusFilter,
            category:this.categoryFilter,
            firstName:this.assigneeFilter.split(" ")[0],
            lastName:this.assigneeFilter.split(" ")[1]
        }   
        this.$store.dispatch('contractor/loadOperations',options); 
        EventBus.$emit('ADD_FILTER', options);
        */
      //console.log(this.filter)
      this.$store.dispatch("contractor/updateOperationsFilter", this.filter);
    },
    isFilteringEnabled() {
      if (this.filterCount > 0) {
        return true;
      } else return false;
    },
    reset() {
      this.filter.assignee = "";
      this.filter.category = "";

      //to be updated by the default statuses
      this.filter.statuses = "";
      this.filter.department = "";

      /*
            let options = {
            limit: 20,
            start: 0,
            status:this.statusFilter,
            category:this.categoryFilter,
            firstName:this.assigneeFilter,
            lastName:this.assigneeFilter
            }   
            this.$store.dispatch('contractor/loadOperations',options);  
            this.$store.dispatch('contractor/disableAllloaded');
            EventBus.$emit('ADD_FILTER', options);    
            */
    },
    hideFilter() {
      this.filterDisplayed = false;
    },
    displayFilter() {
      this.filterDisplayed = true;
    },
    navigateTo(nav) {
      this.$router.push(nav);
    },
    displayDepartmentLabel(item) {
      return item.code + " - " + item.name;
    },
  },
  created() {
    //this.departments = utils.getDepartments();
    this.filter = this.$store.getters["contractor/getOperationsFilter"];
  },
  mounted() {
    //this.filterList()
    // this.reset();
  },
};
</script>
<style scoped>
.filter-card {
  margin-bottom: 20px;
}
.filter-counter {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #eabe00;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
}
@media screen and (max-width: 999px) {
  .row .col-12 {
    margin-bottom: 10px;
  }
}
</style>
    
<template>
  <div >
    <div>
        <transition name="fade" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </transition>
    </div>
  </div>
</template>
<script>
export default {
};
</script>

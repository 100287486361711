<template>
  <div>
    <crud-table
      :data="priorities"
      :columns="columns"
      :editFunction="editPriority"
      :deleteFunction="deletePriority"
      :isRowEditable="isEditable"
      :isRowDeletable="isDeletable"
      :addFunction="addPriority"
      :addFormSchema="this.addFormSchema"
      :editFormSchema="this.editFormSchema"
    >
    </crud-table>
  </div>
</template>

<script>
import crudTable from "@/components/Global/crudTable";

export default {
  data() {
    return {
      columns: [
        {
          id: 1,
          prop: "label",
          label: "Libellé",
          width: "180",
        },
        {
          id: 2,
          prop: "ranking",
          label: "Ordre",
          width: "180",
        },
        {
          id: 3,
          prop: "color",
          label: "Couleur",
          width: "180",
          formatter: this.formatColor,
        },
      ],
      addFormSchema: [
        {
          component: "h3",
          children: "Ajouter une priorité",
        },
        {
          type: "text",
          name: "label",
          label: "Libellé",
          validation: "required",
        },
        {
          type: "number",
          name: "ranking",
          label: "Ordre",
          validation: "required",
        },
        {
          type: "color",
          name: "color",
          label: "Couleur",
          validation: "required",
        },
        {
          type: "submit",
          label: "Ajouter",
        },
      ],
      editFormSchema: [
        {
          component: "h3",
          children: "Modifier une priorité",
        },
        {
          type: "text",
          name: "label",
          label: "Libellé",
          validation: "required",
        },
        {
          type: "number",
          name: "ranking",
          label: "Ordre",
          validation: "required",
        },
        {
          type: "color",
          name: "color",
          label: "Couleur",
          validation: "required",
        },
        {
          type: "submit",
          label: "Modifier",
        },
      ],
    };
  },
  components: {
    crudTable,
  },
  computed: {
    priorities() {
      return this.$store.getters["contractor/getPriorities"];
    },
  },
  methods: {
    async addPriority(priority) {
      let label = priority.label;
      priority.name = label.replace(/\W+/g, "_").toLowerCase();
      await this.$store.dispatch("contractor/addPriority", priority);
      this.$store.dispatch("contractor/loadOrganization", priority);
    },
    async editPriority(priority) {
      await this.$store.dispatch("contractor/editPriority", priority);
      this.$store.dispatch("contractor/loadOrganization", priority);
    },
    async deletePriority(priority) {
      await this.$store.dispatch("contractor/deletePriority", priority);
      this.$store.dispatch("contractor/loadOrganization", priority);
    },
    formatColor(row, column, cellValue, index) {
      // return '<el-color-picker v-model="' + cellValue + '"></el-color-picker>';
      //return '<div style="width:10px height:10px background-color:"' + cellValue + '></div>'
      return cellValue;
    },
    isEditable(item) {
      return true;
    },
    isDeletable(item) {
      return true;
    },
  },
  created() {},
};
</script>

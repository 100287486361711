import {
  SIGNUP,
  SIGNUP_SUCCESS,
  SIGNUP_FAILURE,
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
  SET_ACCOUNT,
  INIT_AUTH_STATE,
  WEAK_PASSWORD,
  GET_PROFILE_BY_CONTRACTOR_SUCCESS,
  GET_PROFILE_BY_CONTRACTOR_FAILURE,
  SET_PROFILE_BY_CONTRACTOR_SUCCESS,
  SET_PROFILE_BY_CONTRACTOR_FAILURE,
  UPDAYE_PW_SUCCESS,
  UPDAYE_PW_ERROR,
  INIT_RESET_PASSWORD,
  INIT_RESET_PASSWORD_SUCCESS,
  INIT_RESET_PASSWORD_FAILURE

} from '../../mutation-types'

import AuthService from '@/auth/AuthService'
import Api from '@/api'
import router from '@/router'
//import {Notification} from 'vue-notifyjs'
import { Notification } from 'element-ui'

export const actions = {
  //Sign up Action 
  signup: async function ({ commit }, payload) {
    try {
      let account = await Api.createAccount(payload);
      commit(SIGNUP_SUCCESS)
      commit(SIGNUP)
      commit(SET_ACCOUNT, account)
      //Notification.notify({type: 'success', message: 'Account successfuly created, redirecting to sign in',timeout: 5000,icon: "ti-check"})
      Notification.success({
        title: 'Compte créé avec succès!',
        timeout: 5000,
      })
      router.replace("/auth/signin")
    }
    catch (e) {
      if (e.response.status == '500') {
        Notification.error({
          title: "Enregistrement échoué !",
          timeout: 5000
        })
        commit(WEAK_PASSWORD)
      }
      if (e.response.status == '409') {
        Notification.error({
          title: "Enregistrement échoué !",
          timeout: 5000
        })
        commit(SIGNUP_FAILURE)
      }

    }

  },
  //verify invitation 
  getInvitationInfos: async function ({ commit }, code) {
    try {
      let invitation = await Api.getInvitation(code);
      if(invitation !== null){
        let result = {
          valid : invitation.valid,
          email: invitation.email
        }
        return result
      }
      else return null
    }
    catch (e) {
      console.log(e)
    }

  },
  /**
   * Login Action 
   */
  login: async function ({ commit }, payload) {
    commit(LOGIN)

    try {
      await AuthService.loginWithKeycloak(payload.type, payload.email, payload.password)
      router.replace("/home")

    }
    catch(e){
      commit(LOGIN_FAILURE)
      console.log(e)
      console.log(e.response)
      Notification.error({
        title: "Authentification échouée !",
        message: 'login ou mot de passe invalides',
        timeout: 5000
      })

    }
   

  },

  /**
   * Handle Authentication Callback action
   */

  //to be removed as it's no longuer used

  /*
  handleAuthCallback: function({commit}) {
    AuthService.handleAuthentication(function(err, payload){
      if(err){
        commit(LOGIN_FAILURE)
      }
      else{
        commit(LOGIN_SUCCESS)
        commit(SET_ACCOUNT, payload)
        //Notification.notify({type: 'success', message: 'Successfuly signed in',timeout: 5000,icon: "ti-check"})
      }
    })
  },
  */

  /**
   * 
   */
  logout: function ({ commit }) {
    AuthService.logout()
    commit(LOGOUT)
  },
  /**
   * 
   */
  initAuthState: function ({ commit }) {
    //get Account 

    //get AppUser

    //commit
    commit(INIT_AUTH_STATE, AuthService.getAccount())
  },

  // load profile data

  loadProfile: async function ({ commit }) {
    try {
      let profile = await Api.getProfile();
      commit(GET_PROFILE_BY_CONTRACTOR_SUCCESS, profile)
    }

    catch (e) {
      commit(GET_PROFILE_BY_CONTRACTOR_FAILURE)
    }
  },
  // update profile data

  updateProfile: async function ({ commit, state }) {
    let myProfile = state.profile
    try {
      let profileData = await Api.setProfile(myProfile);
      commit(SET_PROFILE_BY_CONTRACTOR_SUCCESS, profileData)

      Notification.success({
        title: 'Votre profil a été mis à jour avec succès!',
        timeout: 5000
      })
    }

    catch (e) {
      commit(SET_PROFILE_BY_CONTRACTOR_FAILURE)
      Notification.error({
        title: 'La modification du profil a échoué',
        timeout: 5000
      })

    }
  },
  updatePassword: async function ({ commit, state }, password) {
    try {
      let updatedPassword = await Api.updatePassword(password);
      commit(UPDAYE_PW_SUCCESS, updatedPassword)
      Notification.success({
        title: 'Mot de pass mis à jour avec succès!',
        timeout: 5000
      })
    }

    catch (e) {
      commit(UPDAYE_PW_ERROR)
      Notification.error({
        title: 'Modification de mot de pass échouée !',
        timeout: 5000
      })
    }
  },
  initResetPassword: async function ({ commit, state }, email) {
    try {
      await Api.initResetPassword(email);
      //commit(UPDAYE_PW_SUCCESS, updatedPassword)
      Notification.success({
        title: 'Votre demande a été prise en compte!',
        timeout: 5000
      })
    }

    catch (e) {
      //commit(UPDAYE_PW_ERROR)
      Notification.error({
        title: 'Votre demande a échoué !',
        timeout: 5000
      })
      throw e
    }
  },
  resetPassword: async function ({ commit, state }, payload) {
    try {
      await Api.resetPassword(payload);
      //commit(UPDAYE_PW_SUCCESS, updatedPassword)
      Notification.success({
        title: 'Votre mot de passe a été réinitialisé !',
        timeout: 5000
      })
      router.replace("/auth/signin")
    }

    catch (e) {
      //commit(UPDAYE_PW_ERROR)
      //analyse the error
      Notification.error({
        title: 'Votre demande a échoué !',
        message: 'Token invalide ou expiré',
        timeout: 5000
      })
    }
  }
}

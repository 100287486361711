<template>
  <div class="col-12">
    <card>
      <b-card-header>
        <h5>Modifier votre opération</h5>
        <h6 class="obligatoireTitle">
          <span class="obligatoire">*</span> champs obligatoires
        </h6>
      </b-card-header>
      <form @submit.prevent="update()" v-if="!loadingOperation">
        <div class="row">
          <div class="col-md-12">
            <label>Titre </label><span class="obligatoire"> *</span>
            <input
              type="text"
              placeholder="Titre"
              v-model="currentOperation.title"
              name="titre"
              class="form-control"
              required
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <label>Desciption</label><span class="obligatoire"> *</span>
            <textarea
              type="text"
              placeholder="Description"
              v-model="currentOperation.description"
              name="desciption"
              class="form-control"
              rows="3"
              required
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <label>Catégorie </label><span class="obligatoire"> *</span>
            <select
              class="form-control custom-select"
              v-model="currentOperation.category"
              required
            >
              <option
                v-for="c in categories"
                :key="c.id"
                :value="c.id"
                selected
              >
                {{ c.label }}
              </option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <label>Priorité </label><span class="obligatoire"> *</span>
            <select
              class="form-control custom-select"
              v-model="currentOperation.priority"
              required
            >
              <option
                v-for="priority in priorities"
                :key="priority.id"
                :value="priority.id"
                selected
              >
                {{ priority.label }}
              </option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <h5 class="form-title">Client</h5>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <select
              class="form-control custom-select"
              v-model="currentOperation.customer"
              required
            >
              <option
                v-for="customer in customers"
                :key="customer.id"
                :value="customer.id"
                selected
              >
                {{ customer.organism }}
              </option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <h5 class="form-title">Contact principal</h5>
          </div>
        </div>
        <div class="row" v-if="currentOperation.contact">
          <div class="col-md-4">
            <label>Nom du contact </label><span class="obligatoire"> *</span>
            <input
              type="text"
              placeholder=""
              v-model="currentOperation.contact.name"
              name="name"
              class="form-control"
            />
          </div>
          <div class="col-md-4">
            <label>Téléphone</label><span class="obligatoire"> *</span>
            <input
              type="tel"
              placeholder=""
              v-model="currentOperation.contact.phoneNumber"
              name="phoneNumber"
              class="form-control"
            />
          </div>
          <div class="col-md-4">
            <label>Email </label><span class="obligatoire"> *</span>
            <input
              type="email"
              placeholder=""
              v-model="currentOperation.contact.email"
              name="email"
              class="form-control"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <h5 class="form-title">Contact secondaire</h5>
          </div>
        </div>
        <div class="row" v-if="currentOperation.secondaryContact">
          <div class="col-md-4">
            <label>Nom du contact</label>
            <input
              type="text"
              placeholder=""
              v-model="currentOperation.secondaryContact.name"
              name="name"
              class="form-control"
            />
          </div>
          <div class="col-md-4">
            <label>Téléphone</label>
            <input
              type="tel"
              placeholder=""
              v-model="currentOperation.secondaryContact.phoneNumber"
              name="phoneNumber"
              class="form-control"
            />
          </div>
          <div class="col-md-4">
            <label>Email</label>
            <input
              type="email"
              placeholder=""
              v-model="currentOperation.secondaryContact.email"
              name="email"
              class="form-control"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <h5 class="form-title">Adresse</h5>
          </div>
        </div>
        <div class="row" v-if="currentOperation.location">
          <div class="col-md-12">
            <label>Adresse</label><span class="obligatoire"> *</span>
            <input
              type="text"
              placeholder=""
              v-model="currentOperation.location.address"
              name="address"
              class="form-control"
              required
            />
          </div>
        </div>
        <div class="row" v-if="currentOperation.location">
          <!--
          <div class="col-md-4">
            <label>Département</label><span class="obligatoire"> *</span>
            <select
              class="form-control custom-select"
              v-model="currentOperation.location.department"
              required
            >
              <option
                v-for="department in departments"
                :key="department.id"
                :value="department.code"
                selected
              >
                {{ department.code }} - {{ department.name }}
              </option>
            </select>
          </div>
          <div class="col-md-4">
            <label>Ville</label><span class="obligatoire"> *</span>
            <select
              class="form-control custom-select"
              v-model="currentOperation.location.city"
              required
            >
              <option
                v-for="city in cities"
                :key="city.id"
                :value="city"
                selected
              >
                {{ city.name }} ( {{ city.zip_code }} )
              </option>
            </select>
          </div>
          -->
          <div class="col-md-4 postal-code">
            <label>Code Postal<span class="obligatoire">*</span></label>
            <el-select
              v-cancel-read-only
              v-model="selectedCity"
              value-key="Code_commune_INSEE"
              filterable
              remote
              reserve-keyword
              placeholder="Code Postal"
              :remote-method="searchCity"
              :loading="citiesLoading"
              @change="setCity"
            >
              <el-option
                v-for="item in cityOptions"
                :key="item.Code_commune_INSEE"
                :label="item.Code_postal + ' - ' + item.Nom_commune"
                :value="item"
              >
              </el-option>
            </el-select>
          </div>
          <div class="col-md-4">
            <label>Ville</label><span class="obligatoire">*</span>
            <input
              type="text"
              placeholder=""
              v-model="currentOperation.location.city"
              name="country"
              class="form-control"
              required
              disabled
            />
          </div>
          <div class="col-md-4">
            <label>Pays</label><span class="obligatoire">*</span>
            <input
              type="text"
              placeholder=""
              v-model="currentOperation.location.country"
              name="country"
              class="form-control"
              required
              disabled
            />
          </div>
        </div>
        <div class="row buttonsUpdate">
          <div>
            <p-button type="primary" :round="true" :loading="loading"
              >Modifier</p-button
            >
          </div>
          <form @submit.prevent="deleteOperation()">
            <div>
              <p-button type="danger" :round="true" :loading="loading"
                >Supprimer</p-button
              >
            </div>
          </form>
        </div>

        <popup-confirmation v-if="confirmation"></popup-confirmation>
      </form>
    </card>
  </div>
</template>
<script>
import configuration from "@/api/config.js";
import EventBus from "@/eventBus";
import popupConfirmation from "@/components/Popups/popupConfirmation.vue";
import { Notification } from "element-ui";
import utils from "@/utils/index.js";

export default {
  data: function () {
    return {
      submitted: false,
      cityOptions: [],
      citiesLoading: false,
      loading: false,
      loadingOperation: true,
      confirmation: "",
      departments: [],
      currentOperation: {},
      selectedCity: {},
      city: "",
      reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
    };
  },
  components: {
    popupConfirmation,
  },
  methods: {
    async update() {
      this.loading = true;
      let payload = {
        operationId: this.currentOperation.id,
        operationData: this.currentOperation,
      };
      await this.$store.dispatch("contractor/updateOperation", payload);
      this.loading = false;
      this.$router.push("/dashboard/operations/" + this.currentOperation.id);
      this.$store.dispatch("contractor/initOperations");
    },
    /** 
        acceptNumber() {
        var x = this.currentOperation.contact.phoneNumber.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
        var y = this.currentOperation.secondaryContact.phoneNumber.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
        this.currentOperation.contact.phoneNumber = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
        this.currentOperation.secondaryContact.phoneNumber = !y[2] ? y[1] : '(' + y[1] + ') ' + y[2] + (y[3] ? '-' + y[3] : '');
        },
        */
    async deleteOperation() {
      try {
        this.loading = true;
        await this.$store.dispatch(
          "contractor/deleteOperation",
          this.currentOperation
        );
        this.loading = false;
        this.$store.dispatch(
          "contractor/initOperations",
          this.currentOperation
        );
        this.$router.push("/dashboard/operations");
      } catch (e) {}
    },
    searchCity(query) {
      if (query !== "") {
        this.cityOptions = utils.searchCitiesByZipCode(query);
      } else this.cityOptions = [];
    },
    setCityName(zipCode) {
      let city = utils.getCityFromZipCode(zipCode);
      this.currentOperation.location.city = city.Nom_commune;
    },
    setCity(city) {
      this.currentOperation.location.city = city.Nom_commune;
      this.currentOperation.location.zipCode = city.Code_postal;
    },
  },
  computed: {
    operation() {
      let operation = this.$store.getters["contractor/getCurrentOperation"];
      if (typeof operation.secondaryContact === "undefined") {
        operation.secondaryContact = {};
      }
      return operation;
    },
    operationID() {
      return this.$route.params.id;
    },
    statuses() {
      return this.$store.getters["contractor/getStatuses"];
    },
    categories() {
      return this.$store.getters["contractor/getCategories"];
    },
    priorities() {
      return this.$store.getters["contractor/getPriorities"];
    },
    customers() {
      return this.$store.getters["contractor/getCustomers"];
    },
    /*
    cities() {
      return utils.getCities(this.currentOperation.location.department);
    },
    */
  },
  async created() {
    await this.$store.dispatch(
      "contractor/loadOperationbyId",
      this.operationID
    );
    this.currentOperation = this.operation;
    if (this.operation.customer !== null) {
      this.currentOperation.customer = this.operation.customer.id;
    } else {
      this.currentOperation.customer = null;
    }

    if (this.operation.category !== null) {
      this.currentOperation.category = this.operation.category.id;
    } else {
      this.currentOperation.category = null;
    }

    if (this.operation.priority !== null) {
      this.currentOperation.priority = this.operation.priority.id;
    } else {
      this.currentOperation.priority = null;
    }
    console.log("here");
    this.loadingOperation = false;
    if (this.operation.location.zipCode !== "") {
      this.selectedCity = utils.getCityFromZipCodeAndName(
        this.operation.location.zipCode,
        this.operation.location.city
      );

      //we populate the option with the selected City as well to have a label displayed
      this.cityOptions.push(this.selectedCity);
    } else {
      this.selectedCity = {};
    }
    //this.departments = utils.getDepartments();
  },
  /**
   * This directive has been added to fix the issue on iOS where the el-select is not editable
   * (the keyboard doesn't show up)
   */
  directives: {
    cancelReadOnly(el) {
      const input = el.querySelector(".el-input__inner");
      input.removeAttribute("readonly");
    },
  },
};
</script>
<style scoped>
.obligatoireTitle {
  float: right;
}
.obligatoire {
  color: red;
}
.form-title {
  margin-top: 10px;
  margin-bottom: 10px;
}
.buttonsUpdate button {
  margin: 10px 10px 0px 10px;
}
.buttonsUpdate {
  text-align: center;
  padding: auto !important;
  justify-content: center;
  margin: auto !important;
}
.custom-select:focus {
  box-shadow: 0px 0px 2px #2d3748;
  box-shadow: 0 0 0 3px -moz-mac-focusring;
  color: #222;
  outline: none;
}
.custom-select option {
  color: white;
  background-color: #2d3748;
}
/* Style the arrow inside the select element: */
.select-selected:after {
  position: absolute;
  content: "";
  top: 14px;
  right: 10px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-color: #fff transparent transparent transparent;
}

/* Point the arrow upwards when the select box is open (active): */
.select-selected.select-arrow-active:after {
  border-color: transparent transparent #fff transparent;
  top: 7px;
}

.container {
  padding: 30px;
  background-color: #f1f3f4;
  padding-bottom: 500px;
  align-content: center;
}
img {
  margin-top: -120px;
  margin-bottom: 20px;
  padding: 10px;
}
.form {
  text-align: center;
  padding: 10px;
  margin: auto;
}
.col-md-12 {
  padding-bottom: 15px;
}
input,
textarea {
  background-color: #f1f3f4;
  outline: none;
}
input:focus,
textarea:focus {
  outline: none !important;
  box-shadow: 0px 0px 2px #2d3748;
}
.form-control:focus {
  outline: none !important;
  background-color: #f1f3f4;
}
.form-control {
  background-color: #f1f3f4;
  border: none;
}
label {
  padding-left: 12px;
  float: left;
}
.postal-code {
  display: flex;
  flex-direction: column;
}
</style>

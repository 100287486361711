<template>
<div class="members">
    <div class="row">
            <div class="col-lg-8 col-12">
             <fg-input type="text"
                            placeholder="Search"
                            v-model="search"
                            class="searchInput"
                            v-on="filterList"
                            >
                </fg-input>
            </div>
    </div>
    <members></members> 
</div>
</template>
<script>
import members from "@/pages/CRM/Clients.vue"
import list from "@/components/Global/listHeader"

export default {
    
    data: function() {
            return {
                search:''
            }
    },
    components:{
        list,
        members
    },
    computed:{
    clientMembers(){
            return this.$store.getters['contractor/getClients'];
    },
    filteredClients() {    
        if(this.search==='')
        {
            return this.clientMembers;
        }
        else{
        return this.clientMembers.filter( (member) => {
            if(member.email && member.phoneNumber ){
            return member.email.toLowerCase().includes(this.search.toLowerCase()) || member.phoneNumber.includes(this.search);
            }
            if(member.email || member.phoneNumber)
            {
            return member.email.toLowerCase().includes(this.search.toLowerCase()) || member.firstName.includes(this.search);
            }   
        });
        }
        },
    filterList(){
            this.$store.dispatch('contractor/loadfilteredClients',this.filteredClients);     
    }    
    },
    methods:{  
    navigateTo (nav) {
        this.$router.push(nav)
    }
    },
    created(){
    this.$store.dispatch('contractor/loadClients');
    }
}
</script>
<style scoped>

.bouton {
  background-color: #5fd5b7;
  border: none;
  color: white;
  padding: 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 20px;
  width: 50px;
  height: 50px;
  margin-bottom: 20px;
  float: right;
}
@media screen and (max-width: 999px){
.headers p{
    display: none;
}   
}
@media screen and (max-width: 371px){
.bouton{
  width: 35px;
  height: 35px;
  font-size: small;
}
}
</style>

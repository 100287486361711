<template>
  <div class="expiration-wrapper">
    <card class="card card-expiration">
      <el-button
        class="close-btn"
        icon="el-icon-close"
        circle
        @click="hide"
      ></el-button>
      <div class="card-body">
        <div style="height: 0">
          <iframe
            src="https://demo.arcade.software/fQ0JojER1dMMI4kXRXBZ?embed"
            frameborder="0"
            loading="lazy"
            webkitallowfullscreen
            mozallowfullscreen
            allowfullscreen
            style="
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            "
          ></iframe>
        </div>
      </div>
    </card>
  </div>
</template>
<script>
export default {
  computed: {},
  methods: {
    navigateTo(nav) {
      this.$router.push(nav);
    },
    hide() {
      this.$store.dispatch("contractor/hideDemo");
    },
  },
  mounted() {},
  created() {},
};
</script>

<style scoped>
.expiration-wrapper {
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 1000;
  background-color: rgb(105 105 105 / 70%);
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  justify-content: space-around;
}

.card-expiration {
  width: 800px;
  height: 450px;
  text-align: center;
}
.locker {
  padding: 10px;
  border-radius: 50%;
  background-color: #eabe00;
  color: white;
  font-size: 20px;
}
.card-expiration .card-body {
  display: flex !important;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  align-content: center;
}

.close-btn {
  position: absolute;
  right: -50px;
}
</style>

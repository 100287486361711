<template>
  <card class="coming_soon">
    <div class="coming_soon_content">
      <img class="empty_image" src="@/assets/img/reports_coming_soon.png">
        <h4> Bientôt ! </h4>
        <h5> Des rapports ultra riches pour piloter votre activité </h5>
      </div>
  </card>
</template>
<script>


export default {
  created () {

  },
  computed: {
  
  },
  components : {
  }
}
</script>
<style scoped>
  .coming_soon{
    padding: 50px;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .coming_soon_content{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  img {
    width: 200px;
  }
</style>


<template>
  <footer class="footer">
    <div class="container-fluid" id="foot">
      <nav class="pull-left">
        <ul>
          <li>
            <router-link :to="{path:'/home'}">Home</router-link>
          </li>
        </ul>
      </nav>
      <div class="copyright pull-right">
        &copy; Optimop 2022
      </div>
    </div>
  </footer>
</template>
<script>
  export default {}
</script>
<style>
.footer{
    background-color: #f1f3f4;
    overflow: hidden;
}
</style>

<template>
  <div>
    <p>Plan : {{ subscription.plan.name }}</p>
    <p>Date d'expiration : {{ subscription.plan_expiry_date }}</p>
    <p v-if="expired">Statut du plan : Expiré</p>
    <p v-else>Statut du plan : Actif</p>
    <!--
    <a
      type="primary"
      href="#"
      :loading="loading"
      @click.prevent="redirectToStripePortal"
      >Gérer votre abonnement</a
    >
    -->
    <el-button v-show="displayStripeButton" type="primary" :loading="loading" @click="redirectToStripePortal"
      >Gérer votre abonnement</el-button
    >
  </div>
</template>
<script>
export default {
  name: "orgnization",

  data: function () {
    return {
      //activeTab:"categories"
      loading: false,
    };
  },
  components: {},
  computed: {
    subscription() {
      return this.$store.getters["contractor/getSubscriptionInfo"];
    },
    displayStripeButton() {
      return this.$store.getters["contractor/hasStripeSubscription"];
    },
    expired() {
      let subscription = this.$store.getters["contractor/getSubscriptionInfo"];
      let plan_expiry_date = new Date(subscription.plan_expiry_date);
      let today = new Date();
      if (today > plan_expiry_date) {
        return true;
      } else return false;
    },
  },
  methods: {
    async redirectToStripePortal() {
      console.log("start");
      //Start loading the button
      this.loading = true;
      //init Stripe billing portal
      try {
        let url = await this.$store.dispatch("contractor/initStripePortal");
        //stop loading the button
        this.loading = false;
        //redirect to the portal url
        window.location.replace(url);
        //console.log(url)
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },
  },
  created() {},
  mounted() {},
};
</script>
<style scoped>
</style>
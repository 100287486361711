import {
  GET_OPERATIONS_SUCCESS,
  GET_OPERATIONS_FAILURE,
  SET_OPERATION_ID,
  SET_OPERATION_BY_CONTRACTOR_SUCCESS,
  SET_OPERATION_BY_CONTRACTOR_FAILURE,
  GET_ONE_OPERATION,
  GET_ONE_OPERATION_SUCCESS,
  GET_ONE_OPERATION_FAILURE,
  SET_STATUS_SUCCESS,
  SET_STATUS_FAILURE,
  SET_STATUS_COUNT,
  GET_MORE_OPERATIONS_BY_CONTRACTOR_SUCCESS,
  GET_MORE_OPERATIONS_BY_CONTRACTOR_FAILURE,
  SET_ALL_OPERTATIONS_LOADED,
  LOAD_FILTERED_OP,
  GET_TEAM_SUCCESS,
  GET_TEAM_FAILURE,
  SET_ASSIGNEE_SUCCESS,
  SET_ASSIGNEE_FAILURE,
  GET_MORE_MEMBERS_BY_CONTRACTOR_SUCCESS,
  GET_MORE_MEMBERS_BY_CONTRACTOR_FAILURE,
  SET_ALL_MEMBERS_LOADED,
  LOAD_FILTERED_MEMBERS,
  LOAD_CLIENTS_SUCCESS,
  LOAD_CLIENTS_FAILURE,
  LOAD_FILTERED_CLIENTS,
  ADD_EVENT_SUCCESS,
  ADD_EVENT_FAILURE,
  GET_EVENT_SUCCESS,
  GET_EVENT_FAILURE,
  GET_EVENTS_SUCCESS,
  GET_EVENTS_FAILURE,
  GET_EVENT_BY_ASSIGNEE_SUCCESS,
  GET_EVENT_BY_ASSIGNEE_FAILURE,
  GET_TEAM_ARR_SUCCESS,
  DELETE_EVENT_SUCCESS,
  DELETE_EVENT_FAILURE,
  EDIT_EVENT_SUCCESS,
  EDIT_EVENT_FAILURE,
  ADD_EVENT_ELEMENT_SUCCESS,
  ADD_ATTACHMENTS_SUCCESS,
  ADD_ATTACHMENTS_FAILURE,
  ADD_NOTE_SUCCESS,
  ADD_NOTE_FAILURE,
  ADD_CONFIG_PRIORITY_SUCCESS,
  ADD_CONFIG_PRIORITY_FAILURE,
  GET_PRIORITIES_SUCCESS,
  GET_PRIORITIES_FAILURE,
  DELETE_PRIORITY_SUCCESS,
  DELETE_PRIORITY_FAILURE,
  ADD_PRIOELEMENT_SUCCESS,
  EDIT_PRIORITY_SUCCESS,
  EDIT_PRIORITY_FAILURE,
  DELETE_STATUS_SUCCESS,
  DELETE_STATUS_FAILURE,
  EDIT_STATUSELEMENT_SUCCESS,
  EDIT_STATUSELEMENT_FAILURE,
  ADD_STATUSELEMENT_SUCCESS,
  ADD_CONFIG_STATUS_SUCCESS,
  ADD_CONFIG_STATUS_FAILURE,
  EDIT_CATEGORYELEMENT_SUCCESS,
  EDIT_CATEGORYELEMENT_FAILURE,
  ADD_CATEGORYELEMENT_SUCCESS,
  SET_NOTE_SUCCESS,
  GET_LANDING_SUCCESS,
  GET_LANDING_FAILURE,
  UPDATE_LANDING_SUCCESS,
  UPDATE_LANDING_FAILURE,
  UPDATE_OPERATION_BY_CONTRACTOR_SUCCESS,
  UPDATE_OPERATION_BY_CONTRACTOR_FAILURE,
  LOAD_FILTERED_ASSIGNED_OP,
  SET_COORDS_MAP_SUCCESS,

  // New Mutations related to refacto
  GET_ORGANIZATION,
  GET_ORGANIZATION_SUCCESS,
  GET_ORGANIZATION_FAILURE,
  LOAD_OPERATIONS_COUNT,
  LOAD_OPERATIONS_COUNT_SUCCESS,
  LOAD_OPERATIONS_COUNT_FAILURE,

  //Settings (Category  )
  SETTINGS_ADD_CATEGORY,
  SETTINGS_ADD_CATEGORY_SUCCESS,
  SETTINGS_ADD_CATEGORY_FAILURE,
  SETTINGS_DELETE_CATEGORY,
  SETTINGS_DELETE_CATEGORY_SUCCESS,
  SETTINGS_DELETE_CATEGORY_FAILURE,
  SETTINGS_EDIT_CATEGORY,
  SETTINGS_EDIT_CATEGORY_SUCCESS,
  SETTINGS_EDIT_CATEGORY_FAILURE,

  //Settings (Priority)
  SETTINGS_ADD_PRIORITY,
  SETTINGS_ADD_PRIORITY_SUCCESS,
  SETTINGS_ADD_PRIORITY_FAILURE,
  SETTINGS_EDIT_PRIORITY,
  SETTINGS_EDIT_PRIORITY_SUCCESS,
  SETTINGS_EDIT_PRIORITY_FAILURE,
  SETTINGS_DELETE_PRIORITY,
  SETTINGS_DELETE_PRIORITY_SUCCESS,
  SETTINGS_DELETE_PRIORITY_FAILURE,

  //Settings (Status)
  SETTINGS_ADD_STATUS,
  SETTINGS_ADD_STATUS_SUCCESS,
  SETTINGS_ADD_STATUS_FAILURE,
  SETTINGS_EDIT_STATUS,
  SETTINGS_EDIT_STATUS_SUCCESS,
  SETTINGS_EDIT_STATUS_FAILURE,
  SETTINGS_DELETE_STATUS,
  SETTINGS_DELETE_STATUS_SUCCESS,
  SETTINGS_DELETE_STATUS_FAILURE,

  //Init operations
  INIT_OPERATIONS,
  INIT_OPERATIONS_SUCCESS,
  INIT_OPERATIONS_FAILURE,

  //Operations - update filter
  UPDATE_OPRTATIONS_FILTER,
  UPDATE_OPRTATIONS_FILTER_SUCCESS,
  UPDATE_OPRTATIONS_FILTER_FAILURE,

  //update operation
  UPDATE_OPERATION,
  UPDATE_OPERATION_SUCCESS,
  UPDATE_OPERATION_FAILURE,

  //delete operation
  DELETE_OPERATION,
  DELETE_OPERATION_SUCCESS,
  DELETE_OPERATION_FAILURE,

  //Get operations to be updated
  GET_OPERATIONS_TO_UPDATE_SUCCESS,
  GET_OPERATIONS_TO_UPDATE_FAILURE,

  //Add team members
  ADD_MEMBER_SUCCESS,
  ADD_MEMBER_FAILURE,

  //Edit team members
  EDIT_MEMBER_SUCCESS,
  EDIT_MEMBER_FAILURE,

  //Edit team members
  DELETE_MEMBER_SUCCESS,
  DELETE_MEMBER_FAILURE,

  //Add Customer
  ADD_CUSTOMER_SUCCESS,
  ADD_CUSTOMER_FAILURE,

  //Edit Customer
  EDIT_CUSTOMER_SUCCESS,
  EDIT_CUSTOMER_FAILURE,
} from "../../mutation-types";
import Api from "@/api";
import { Notification } from "element-ui";

export const actions = {
  /**
   *
   * Organization
   *
   */

  loadOrganization: async function ({ commit }) {
    try {
      commit(GET_ORGANIZATION);
      let organization = await Api.getUserOrganization();
      console.log(organization);
      commit(GET_ORGANIZATION_SUCCESS, organization);
    } catch (e) {
      commit(GET_ORGANIZATION_FAILURE);
    }
  },

  //////////////////////////////// OPERATIONS MANAGEMENT ////////////////////////

  loadOperationsCount: async function ({ commit, getters }) {
    try {
      commit(LOAD_OPERATIONS_COUNT);
      let count = await Api.getOperationsCountByStatus();
      /*
      let organizationStatuses = getters.getStatuses
      console.log(organizationStatuses.length)
      organizationStatuses.forEach(element => {
        if(count[element.name] === undefined){
          element.count = 0
        }
        else{
          element.count = count[element.name]
        }
      })
      */
      commit(LOAD_OPERATIONS_COUNT_SUCCESS, count);
    } catch (e) {
      console.log(e);
      commit(LOAD_OPERATIONS_COUNT_FAILURE);
    }
  },

  updateOperationsFilter: function ({ commit, state }, filter) {
    try {
      commit(UPDATE_OPRTATIONS_FILTER);
      let operations = state.operations.data;
      let filteredOperations = [];
      /*
      filteredOperations = operations.filter(operation => {
        let doesStatusMatch = false
        let doesCategoryMatch = false
        let doesAssigneeMatch = false
        if(filter.statuses.length > 0){
          //return filter.statuses[0].id == operation.status.id
          filter.statuses.forEach(element => {
            if(element.id == operation.status.id){
              doesStatusMatch = true
            }
         })    
        }
        else{
          doesStatusMatch = true
        }
        if(Object.keys(filter.category).length != 0){
          if(filter.category.id == operation.category.id){
            doesCategoryMatch = true
          }
        }
        else {
          doesCategoryMatch = true
        }

        return doesStatusMatch && doesCategoryMatch

      })
      */
      //console.log(filteredOperations)
      commit(UPDATE_OPRTATIONS_FILTER_SUCCESS, { filter, filteredOperations });
    } catch (e) {
      console.log(e);
      commit(UPDATE_OPRTATIONS_FILTER_FAILURE);
    }
  },
  /*
  loadOperations: async function ({ commit }, options) {
    try {
      let operations = await Api.getOperations(options);
      commit(GET_OPERATIONS_SUCCESS, operations)
    }
    catch (e) {
      commit(GET_OPERATIONS_FAILURE)
    }
  },
  */

  loadOperations: async function ({ commit, state }) {
    try {
      let options = {
        start: state.operations.data.length,
        limit: state.operations.meta.pagination.limit,
      };
      let operationsWithMeta = await Api.getOperations(options);
      commit(GET_OPERATIONS_SUCCESS, operationsWithMeta);
    } catch (e) {
      commit(GET_OPERATIONS_FAILURE);
    }
  },

  initOperations: async function ({ commit, state }) {
    commit(INIT_OPERATIONS);
    try {
      let options = {
        start: 0,
        limit: state.operations.meta.pagination.limit,
      };
      let operationsWithMeta = await Api.getOperations(options);
      commit(INIT_OPERATIONS_SUCCESS, operationsWithMeta);
    } catch (e) {
      commit(INIT_OPERATIONS_FAILURE);
    }
  },

  getOperationsToUpdate: async function ({ commit, state }) {
    //commit(INIT_OPERATIONS)
    try {
      let currentDate = new Date();
      currentDate.setDate(currentDate.getDate() - 1);

      let options = {
        start: 0,
        limit: state.operationsViews.operationsToUpdate.meta.pagination.limit,
        limitDate: currentDate.toJSON(),
      };
      let operationsWithMeta = await Api.getOperationsToUpdate(options);
      commit(GET_OPERATIONS_TO_UPDATE_SUCCESS, operationsWithMeta);
    } catch (e) {
      console.log(e);
      commit(GET_OPERATIONS_TO_UPDATE_FAILURE);
    }
  },

  setOperationId: function ({ commit }, payload) {
    commit(SET_OPERATION_ID, payload);
  },
  createOperation: async function ({ commit, state }, payload) {
    // let newOperation=state.newOperation

    try {
      let newOperationData = await Api.setOperation(payload);
      commit(SET_OPERATION_BY_CONTRACTOR_SUCCESS, newOperationData);
      console.log("TRUE");
      Notification.success({
        title: "Votre opération a été ajoutée avec succès",
        timeout: 5000,
        icon: "ti-check",
        horizontalAlign: "right",
        verticalAlign: "top",
        position: "top right",
      });
    } catch (e) {
      console.log(e);
      commit(SET_OPERATION_BY_CONTRACTOR_FAILURE);
      console.log("FALSE");
      Notification.error({
        title: "L'ajout de l'opération a échoué",
        timeout: 5000,
        type: "danger",
        icon: "fa fa-times-circle",
        horizontalAlign: "right",
        verticalAlign: "top",
        position: "top right",
      });
    }
  },
  updateOperation: async function ({ commit, state }, payload) {
    try {
      commit(UPDATE_OPERATION);
      console.log(payload);
      let newOperationData = await Api.updateOperation(
        payload.operationId,
        payload.operationData
      );
      commit(UPDATE_OPERATION_SUCCESS, newOperationData);
      Notification.success({
        title: "Votre opération a été modifiée avec succès",
        type: "success",
        timeout: 5000,
      });
    } catch (e) {
      commit(UPDATE_OPERATION_FAILURE);
      Notification.error({
        title: "La modification de l'opération a échoué",
        timeout: 5000,
        type: "danger",
      });
    }
  },
  deleteOperation: async function ({ commit }, operation) {
    try {
      commit(DELETE_OPERATION);
      let deleted = await Api.deleteOperation(operation.id);
      commit(DELETE_OPERATION_SUCCESS);
      Notification.success({
        title: "Votre opération a été supprimée avec succès!",
        type: "success",
        timeout: 5000,
      });
    } catch (e) {
      console.log(e);
      commit(DELETE_OPERATION_FAILURE);
      Notification.error({
        title: "La suppression de votre opération a échoué",
        type: "danger",
        timeout: 5000,
      });
    }
  },
  changeStatus: async function ({ commit }, payload) {
    try {
      let changeStatus = await Api.changeStatus(payload);
      commit(SET_STATUS_SUCCESS, changeStatus);
    } catch (e) {
      commit(SET_STATUS_FAILURE);
    }
  },
  loadOperationbyId: async function ({ commit }, operationId) {
    commit(GET_ONE_OPERATION);
    try {
      let operation = await Api.getOperationById(operationId);
      commit(GET_ONE_OPERATION_SUCCESS, operation);
    } catch (e) {
      commit(GET_ONE_OPERATION_FAILURE);
    }
  },
  refreshOperationbyId: async function ({ commit }, operationId) {
    try {
      let operation = await Api.getOperationById(operationId);
      commit(GET_ONE_OPERATION_SUCCESS, operation);
    } catch (e) {
      commit(GET_ONE_OPERATION_FAILURE);
    }
  },
  loadfilteredOperations: async function ({ commit }, filteredOperations) {
    commit(LOAD_FILTERED_OP, filteredOperations);
  },
  loadfilteredAssignedOperations: async function (
    { commit },
    filteredOperations
  ) {
    commit(LOAD_FILTERED_ASSIGNED_OP, filteredOperations);
  },
  loadfilteredMembers: async function ({ commit }, filteredMembers) {
    commit(LOAD_FILTERED_MEMBERS, filteredMembers);
  },
  setStatusCount: async function ({ commit }) {
    let payload = {
      allCounts: await Api.getAllCount(),
    };
    commit(SET_STATUS_COUNT, payload);
  },
  loadMoreOperations: async function ({ commit }, options) {
    try {
      let operations = await Api.getOperations(options);
      if (operations.length < options.limit && operations.length != 0) {
        commit(GET_MORE_OPERATIONS_BY_CONTRACTOR_SUCCESS, operations);
        commit(SET_ALL_OPERTATIONS_LOADED);
      } else {
        commit(GET_MORE_OPERATIONS_BY_CONTRACTOR_SUCCESS, operations);
      }
      if (operations.length == 0) {
        commit(SET_ALL_OPERTATIONS_LOADED);
      }
    } catch (e) {
      commit(GET_MORE_OPERATIONS_BY_CONTRACTOR_FAILURE);
    }
  },
  disableAllloaded: function ({ commit }) {
    commit(GET_MORE_OPERATIONS_BY_CONTRACTOR_FAILURE);
  },
  loadMoreMembers: async function ({ commit }) {
    try {
      let members = await Api.getTeamMembers();
      if (members.length > 0) {
        commit(GET_MORE_MEMBERS_BY_CONTRACTOR_SUCCESS, members);
      } else {
        commit(SET_ALL_MEMBERS_LOADED);
      }
    } catch (e) {
      commit(GET_MORE_MEMBERS_BY_CONTRACTOR_FAILURE);
    }
  },
  loadTeamList: async function ({ commit, state }) {
    try {
      let team = await Api.getTeamMembers();
      var arrayMembers = new Array();
      for (var i = 0; i < team.accounts.length; i++) {
        var objectTemp = {
          id: team.accounts[i].id,
          name: team.accounts[i].firstName + " " + team.accounts[i].lastName,
        };
        arrayMembers.push(objectTemp);
      }
      commit(GET_TEAM_SUCCESS, arrayMembers);
      commit(GET_TEAM_ARR_SUCCESS, team);
    } catch (e) {
      commit(GET_TEAM_FAILURE);
    }
  },

  /////////////////// Team MANAGEMENT /////////////////////////

  addTeamMember: async function ({ commit, state }, newMember) {
    //let newMember=state.newMember
    try {
      let member = await Api.setNewMember(newMember);
      commit(ADD_MEMBER_SUCCESS, member);
      Notification.success({
        position: "top-right",
        title: "Succès",
        message: "Membre ajouté avec succès",
        type: "success",
        timeout: 5000,
        icon: "ti-check",
      });
    } catch (e) {
      console.log(e);
      commit(ADD_MEMBER_FAILURE);
      Notification.error({
        position: "top-right",
        title: "Erreur",
        message: "L'ajout du membre a échoué",
        type: "danger",
        timeout: 5000,
        icon: "fa fa-times-circle",
      });
    }
  },
  editTeamMember: async function ({ commit }, member) {
    try {
      //this check is not accurate. needs to modify it
      if (member.role === "owner") {
        Notification.error({
          title: "Vous ne pouvez pas suuprimer le owner",
          type: "danger",
          timeout: 5000,
        });
      } else {
        let result = await Api.editTeamMember(member.id, member);
        commit(EDIT_MEMBER_SUCCESS);
        Notification.success({
          title: "Modification avec succès!",
          type: "success",
          timeout: 5000,
        });
      }
    } catch (e) {
      console.log(e);
      commit(EDIT_MEMBER_FAILURE);
    }
  },
  deleteTeamMember: async function ({ commit }, member) {
    try {
      if (member.role === "owner") {
        Notification.error({
          title: "Vous ne pouvez pas suuprimer le owner",
          type: "danger",
          timeout: 5000,
        });
      } else {
        let result = await Api.deleteTeamMember(member.id);
        commit(DELETE_MEMBER_SUCCESS);
        Notification.success({
          title: "Suppression avec succès!",
          type: "success",
          timeout: 5000,
        });
      }
    } catch (e) {
      console.log(e);
      commit(DELETE_MEMBER_FAILURE);
    }
  },

  /////////////////// Customers MANAGEMENT /////////////////////////

  addCustomer: async function ({ commit, state }, customer) {
    //let newMember=state.newMember
    try {
      customer.organization = state.organizationId;
      let entity = await Api.addCustomer(customer);
      commit(ADD_CUSTOMER_SUCCESS, entity);
      Notification.success({
        position: "top-right",
        title: "Succès",
        message: "Client ajouté avec succès",
        type: "success",
        timeout: 5000,
        icon: "ti-check",
      });
    } catch (e) {
      console.log(e);
      commit(ADD_CUSTOMER_FAILURE);
      Notification.error({
        position: "top-right",
        title: "Erreur",
        message: "L'ajout du client a échoué",
        type: "danger",
        timeout: 5000,
        icon: "fa fa-times-circle",
      });
    }
  },

  editCustomer: async function ({ commit, state }, customer) {
    //let newMember=state.newMember
    try {
      //customer.organization = state.organizationId;
      let entity = await Api.editCustomer(customer.id, customer);
      commit(EDIT_CUSTOMER_SUCCESS, entity);
      Notification.success({
        position: "top-right",
        title: "Succès",
        message: "Client modifié avec succès",
        type: "success",
        timeout: 5000,
        icon: "ti-check",
      });
    } catch (e) {
      console.log(e);
      commit(EDIT_CUSTOMER_FAILURE);
      Notification.error({
        position: "top-right",
        title: "Erreur",
        message: "La modification du client a échoué",
        type: "danger",
        timeout: 5000,
        icon: "fa fa-times-circle",
      });
    }
  },

  inviteCustomer: async function ({ commit, state }, customer) {
    try {
      let payload = {
        email: customer.email,
        firstName: customer.firstName,
        sendEmail: true,
        customer: customer.id
      }
      if (customer.email === null || customer.firstName === null) {
        Notification.error({
          position: "top-right",
          message: "Vous devez renseigner les infos clients manquants",
          type: "warning",
          timeout: 5000,
          icon: "ti-check",
        });
      }
      else {
        let entity = await Api.inviteCustomer(payload);
        Notification.success({
          position: "top-right",
          title: "Succès",
          message: "Invitation envoyée avec succès",
          type: "success",
          timeout: 5000,
          icon: "ti-check",
        });
      }

    }
    catch (e) {
      if (e.response.status === 406) {
        Notification.warning({
          position: "top-right",
          message: "Le client dispose déjà d'une invitation valide ou d'un compte",
          type: "warning",
          timeout: 5000,
          icon: "ti-check",
        });
      }
      else {
        if (e.response.status === 412) {
          Notification.warning({
            position: "top-right",
            message: "Vous devez d'abord créer votre vitrine et lui associer un domaine",
            type: "warning",
            timeout: 5000,
            icon: "ti-check",
          });
        }
        else {
          Notification.error({
            position: "top-right",
            message: "Une erreur est survenue",
            type: "error",
            timeout: 5000,
            icon: "ti-check",
          });
        }
      }
    }

  },

  //////////////////////////////////////////////////////////////////

  addAssignee: async function ({ commit }, payload) {
    try {
      let assignee = await Api.setAssignee(payload);
      commit(SET_ASSIGNEE_SUCCESS, assignee);
    } catch (e) {
      commit(SET_ASSIGNEE_FAILURE);
    }
  },
  loadClients: async function ({ commit }) {
    try {
      let clients = await Api.getClients();
      commit(LOAD_CLIENTS_SUCCESS, clients);
    } catch (e) {
      commit(LOAD_CLIENTS_FAILURE);
    }
  },
  loadfilteredClients: async function ({ commit }, filteredClients) {
    commit(LOAD_FILTERED_CLIENTS, filteredClients);
  },
  loadLanding: async function ({ commit, state }) {
    let landingId = state.landingId;
    try {
      let landing = await Api.getLandingbyId(landingId);
      commit(GET_LANDING_SUCCESS, landing);
    } catch (e) {
      commit(GET_LANDING_FAILURE);
    }
  },
  updateLanding: async function ({ commit, state }, payload) {
    let landingId = state.landingId;
    try {
      let landing = await Api.updateLanding(payload, landingId);
      commit(UPDATE_LANDING_SUCCESS, landing);
      Notification.success({
        title: "Votre vitrine a été mise à jour avec succès!",
        type: "success",
        timeout: 5000,
        icon: "ti-check",
        horizontalAlign: "right",
        verticalAlign: "top",
        position: "top right",
      });
    } catch (e) {
      commit(UPDATE_LANDING_FAILURE);
      Notification.error({
        title: "La mise à jour de la vitrine a échoué",
        type: "danger",
        icon: "fa fa-times-circle",
        timeout: 5000,
        horizontalAlign: "right",
        verticalAlign: "top",
        position: "top right",
      });
    }
  },
  /////////////////// EVENT MANAGEMENT /////////////////////////
  addNewEvent: async function ({ commit }, payload) {
    try {
      let event = await Api.setOperationEvent(payload);
      commit(ADD_EVENT_SUCCESS, event);
      Notification.success({
        title: "Votre évenement a été bien ajoutée",
        type: "success",
        timeout: 5000,
      });
    } catch (e) {
      console.log(e);
      commit(ADD_EVENT_FAILURE);
      Notification.error({
        title: "L'ajout de l'évenement a échoué",
        type: "danger",
      });
    }
  },
  getNewEvent: async function ({ commit }, event) {
    try {
      commit(ADD_EVENT_ELEMENT_SUCCESS, event);
    } catch (e) {
      console.log(e);
    }
  },
  loadEventbyAssignee: async function ({ commit }, assigneeId) {
    try {
      let event = await Api.getEventbyAssignee(assigneeId);
      commit(GET_EVENT_BY_ASSIGNEE_SUCCESS, event);
    } catch (e) {
      console.log(e);
      commit(GET_EVENT_BY_ASSIGNEE_FAILURE);
    }
  },
  loadEventbyOperation: async function ({ commit }, operationId) {
    try {
      let event = await Api.getEventbyOperation(operationId);
      commit(GET_EVENT_SUCCESS, event);
    } catch (e) {
      console.log(e);
      commit(GET_EVENT_FAILURE);
    }
  },
  loadEvents: async function ({ commit, state }, options) {
    let organizationId = state.organizationId;
    let payload = {
      id: organizationId,
    };
    console.log(payload);
    try {
      let events = await Api.getAllEvents(payload);
      commit(GET_EVENTS_SUCCESS, events);
    } catch (e) {
      console.log(e);
      commit(GET_EVENTS_FAILURE);
    }
  },
  deleteEvent: async function ({ commit }, event) {
    try {
      let event1 = await Api.deleteOperationEvent(event);
      commit(DELETE_EVENT_SUCCESS);
      Notification.success({
        title: "Votre évenement a été supprimé avec succès",
        type: "success",
        timeout: 5000,
      });
    } catch (e) {
      console.log(e);
      commit(DELETE_EVENT_FAILURE);
      Notification.error({
        title: "La suppression de l'évenement a échoué",
        type: "danger",
        timeout: 5000,
      });
    }
  },
  editNewEvent: async function ({ commit }, payload) {
    try {
      let event = await Api.editOperationEvent(payload);
      commit(EDIT_EVENT_SUCCESS, event);
      Notification.success({
        title: "Votre évenement a été modifié avec succès",
        type: "success",
        timeout: 5000,
      });
    } catch (e) {
      console.log(e);
      commit(EDIT_EVENT_FAILURE);
      Notification.error({
        title: "La modification de votre événement a échoué",
        type: "danger",
        timeout: 5000,
      });
    }
  },
  addAttachments: async function ({ commit }, payload) {
    try {
      let attachments = await Api.setOperationAttachments(payload);
      commit(ADD_ATTACHMENTS_SUCCESS, attachments);
      Notification.success({
        title: "Votre pièce jointe a été ajoutée avec succès!",
        timeout: 5000,
        icon: "ti-check",
        horizontalAlign: "right",
        verticalAlign: "top",
        position: "top right",
      });
    } catch (e) {
      console.log(e);
      commit(ADD_ATTACHMENTS_FAILURE);
      Notification.error({
        title: "L'ajout de la pièce jointe a échoué!",
        timeout: 5000,
        type: "danger",
        icon: "fa fa-times-circle",
        horizontalAlign: "right",
        verticalAlign: "top",
        position: "top right",
      });
    }
  },
  addNote: async function ({ commit }, payload) {
    try {
      let notes = await Api.setOperationNotes(payload);
      commit(ADD_NOTE_SUCCESS, notes);
      Notification.success({
        title: "Votre note a été bien ajoutée",
        type: "success",
        timeout: 5000,
      });
    } catch (e) {
      console.log(e);
      commit(ADD_NOTE_FAILURE);
      Notification.error({
        title: "L'ajout de la note a échoué",
        type: "danger",
        timeout: 5000,
      });
    }
  },
  editNote: async function ({ commit }, payload) {
    try {
      let notes = await Api.editOperationNotes(payload);
      Notification.success({
        title: "Votre note a été bien modifiée",
        type: "success",
        timeout: 5000,
      });
    } catch (e) {
      Notification.error({
        title: "La modification de la note a échoué",
        timeout: 5000,
        type: "danger",
      });
      console.log(e);
    }
  },
  getNote: async function ({ commit }, note) {
    commit(SET_NOTE_SUCCESS, note);
  },

  //--------- Settings (Priority) --------//
  addPriority: async function ({ commit, state }, payload) {
    try {
      commit(SETTINGS_ADD_PRIORITY);
      payload.configuration = state.configurationId;
      let priority = await Api.addPriority(payload);
      commit(SETTINGS_ADD_PRIORITY_SUCCESS, priority);
      Notification.success({
        title: "Votre configuration a été mise à jour avec succès!",
        type: "success",
        timeout: 5000,
      });
    } catch (e) {
      console.log(e);
      commit(SETTINGS_ADD_PRIORITY_FAILURE);
      Notification.error({
        title: "La mise à jour de votre configuration a échoué",
        type: "danger",
        timeout: 5000,
      });
    }
  },

  editPriority: async function ({ commit }, payload) {
    try {
      commit(SETTINGS_EDIT_PRIORITY, priority);
      let priority = await Api.editPriority(payload.id, payload);
      commit(SETTINGS_EDIT_PRIORITY_SUCCESS, priority);
      Notification.success({
        title: "Votre configuration a été mise à jour avec succès!",
        type: "success",
        timeout: 5000,
      });
    } catch (e) {
      console.log(e);
      commit(SETTINGS_EDIT_PRIORITY_FAILURE);
      Notification.error({
        title: "La mise à jour de votre configuration a échoué",
        type: "danger",
        timeout: 5000,
      });
    }
  },

  deletePriority: async function ({ commit }, priority) {
    try {
      commit(SETTINGS_DELETE_PRIORITY);
      let result = await Api.deletePriority(priority.id);
      commit(SETTINGS_DELETE_PRIORITY_SUCCESS);
      Notification.success({
        title: "Votre configuration a été mise à jour avec succès!",
        type: "success",
        timeout: 5000,
      });
    } catch (e) {
      console.log(e);
      commit(SETTINGS_DELETE_PRIORITY_FAILURE);
      Notification.error({
        title: "La mise à jour de votre configuration a échoué",
        type: "danger",
        timeout: 5000,
      });
    }
  },

  //---------------//

  loadConfig: async function ({ commit, state }) {
    let configId = state.configurationId;
    try {
      let config = await Api.getConfiguration(configId);
      commit(GET_PRIORITIES_SUCCESS, config);
    } catch (e) {
      console.log(e);
      commit(GET_PRIORITIES_FAILURE);
    }
  },

  getPriorityElement: async function ({ commit }, priorityElement) {
    try {
      commit(ADD_PRIOELEMENT_SUCCESS, priorityElement);
    } catch (e) {
      console.log(e);
    }
  },

  getStatusElement: async function ({ commit }, statusElement) {
    try {
      commit(ADD_STATUSELEMENT_SUCCESS, statusElement);
    } catch (e) {
      console.log(e);
    }
  },

  //--------- Settings (Status) --------//
  addStatus: async function ({ commit, state }, payload) {
    try {
      payload.configuration = state.configurationId;
      commit(SETTINGS_ADD_STATUS);
      let status = await Api.addStatus(payload);
      commit(SETTINGS_ADD_STATUS_SUCCESS, status);
      Notification.success({
        title: "Votre configuration a été mise à jour avec succès!",
        type: "success",
        timeout: 5000,
      });
    } catch (e) {
      console.log(e);
      commit(SETTINGS_ADD_STATUS_FAILURE);
      Notification.error({
        title: "La mise à jour de votre configuration a échoué",
        type: "danger",
        timeout: 5000,
      });
    }
  },

  editStatus: async function ({ commit }, payload) {
    try {
      commit(SETTINGS_EDIT_STATUS);
      let status = await Api.editStatus(payload.id, payload);
      commit(SETTINGS_EDIT_STATUS_SUCCESS, status);
      Notification.success({
        title: "Votre configuration a été mise à jour avec succès!",
        type: "success",
        timeout: 5000,
      });
    } catch (e) {
      console.log(e);
      commit(SETTINGS_EDIT_STATUS_FAILURE);
      Notification.error({
        title: "La mise à jour de votre configuration a échoué",
        type: "danger",
        timeout: 5000,
      });
    }
  },

  deleteStatus: async function ({ commit }, status) {
    try {
      commit(SETTINGS_DELETE_STATUS);
      let result = await Api.deleteStatus(status.id);
      commit(SETTINGS_DELETE_STATUS_SUCCESS);
      Notification.success({
        title: "Votre configuration a été mise à jour avec succès!",
        type: "success",
        timeout: 5000,
      });
    } catch (e) {
      console.log(e);
      commit(SETTINGS_DELETE_STATUS_FAILURE);
      Notification.error({
        title: "La mise à jour de votre configuration a échoué",
        type: "danger",
        timeout: 5000,
      });
    }
  },

  ///---------//

  deleteCategory: async function ({ commit }, category) {
    try {
      commit(SETTINGS_DELETE_CATEGORY);
      let result = await Api.deleteCategory(category.id);
      commit(SETTINGS_DELETE_CATEGORY_SUCCESS);
      Notification.success({
        title: "Votre configuration a été mise à jour avec succès!",
        type: "success",
        timeout: 5000,
      });
    } catch (e) {
      console.log(e);
      commit(SETTINGS_DELETE_CATEGORY_FAILURE);
      Notification.error({
        title: "La mise à jour de votre configuration a échoué",
        type: "danger",
        timeout: 5000,
      });
    }
  },
  getCategoryElement: async function ({ commit }, statusElement) {
    try {
      commit(ADD_CATEGORYELEMENT_SUCCESS, statusElement);
    } catch (e) {
      console.log(e);
    }
  },
  editCategory: async function ({ commit }, payload) {
    try {
      commit(SETTINGS_EDIT_CATEGORY);
      let category = await Api.editCategory(payload.id, payload);
      commit(SETTINGS_EDIT_CATEGORY_SUCCESS, category);
      Notification.success({
        title: "Votre configuration a été mise à jour avec succès!",
        type: "success",
        timeout: 5000,
      });
    } catch (e) {
      console.log(e);
      commit(SETTINGS_EDIT_CATEGORY_FAILURE);
      Notification.error({
        title: "La mise à jour de votre configuration a échoué",
        type: "danger",
        timeout: 5000,
      });
    }
  },
  /*
  addCategory: async function ({ commit }, payload) {
    try {
      let status = await Api.setCategory(payload);
      commit(ADD_CONFIG_CATEGORY_SUCCESS, status)
      Notification.success({
        title: 'Votre configuration a été mise à jour avec succès!',
        type: 'success',
        timeout: 5000,
        icon: "ti-check",
        horizontalAlign: 'right',
        verticalAlign: 'top',
        position: 'top right'
      })
    }
    catch (e) {
      console.log(e)
      commit(ADD_CONFIG_CATEGORY_FAILURE)
      Notification.error({
        title: 'La mise à jour de votre configuration a échoué',
        type: 'danger',
        timeout: 5000,
        icon: "fa fa-times-circle",
        horizontalAlign: 'right',
        verticalAlign: 'top',
        position: 'top right'
      })
    }
  },
  */
  addCategory: async function ({ commit, state }, payload) {
    try {
      commit(SETTINGS_ADD_CATEGORY);
      payload.configurationId = state.configurationId;
      let category = await Api.addCategory(payload);
      commit(SETTINGS_ADD_CATEGORY_SUCCESS, category);
      Notification.success({
        title: "Votre configuration a été mise à jour avec succès!",
        type: "success",
        timeout: 5000,
      });
    } catch (e) {
      console.log(e);
      commit(SETTINGS_ADD_CATEGORY_FAILURE);
      Notification.error({
        title: "La mise à jour de votre configuration a échoué",
        type: "danger",
        timeout: 5000,
      });
    }
  },
  loadMap: async function ({ commit, state }) {
    try {
      let mapCoords = await Api.addMap(state.operations);
      commit(SET_COORDS_MAP_SUCCESS, mapCoords);
    } catch (e) {
      console.log(e);
    }
  },
  downloadAllOperations: async function ({ commit, state }) {
    try {
      await Api.downloadAllOperations();
      //commit(GET_OPERATIONS_SUCCESS,operations)
    } catch (e) {
      console.log(e);
    }
  },

  /************ Onboarding ***********/
  displayDemo: function ({ commit, state }) {
    state.onboarding.displayDemo = true
  },
  hideDemo: function ({ commit, state }) {
    state.onboarding.displayDemo = false
  },

  /********  Stripe *******/
  initStripePortal: async function ({ commit, state }) {
    try {
      let result = await Api.initStripePortal()
      //console.log(result)
      return result.url
    } catch (e) {
      Notification.error({
        title: "Une erreur est survenue",
        type: "danger",
        timeout: 5000,
      });
      throw e
    }
  },
  initCheckoutSession: async function ({ commit, state }, payload) {
    try {
      let result = await Api.initCheckoutSession(payload)
      //console.log(result)
      return result.url
    } catch (e) {
      Notification.error({
        title: "Une erreur est survenue",
        type: "danger",
        timeout: 5000,
      });
      throw e
    }
  }

};

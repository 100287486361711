export default {
    isEmailValid(email){
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
           return true
         } else {
           return false
         }
 
     },
     isPasswordValid(password){
        if(password.length>=8){
            return true
        }
        else return false
     }   
}
<template>
  <nav class="navbar navbar-expand-lg navbar-light" id="mynav">
    <div class="onboarding"></div>
    <div class="container-fluid">
      <a class="navbar-brand" href="#" id="brand">{{ routeName }}</a>
      <div class="trial-banner" v-if="isTrial">
        <trial-banner
          :remainingDays="trialRemainingDays"
          :expired="expired"
        ></trial-banner>
      </div>

      <button
        class="navbar-toggler navbar-burger"
        type="button"
        @click="toggleSidebar"
        :aria-expanded="$sidebar.showSidebar"
        aria-label="Toggle navigation"
        id="navbutton"
      >
        <span class="navbar-toggler-bar"></span>
        <span class="navbar-toggler-bar"></span>
        <span class="navbar-toggler-bar"></span>
      </button>
      <img class="logo-gray" src="@/assets/img/proximov-logo-gray.png" alt="" />
      <ul class="navbar-nav">
        <drop-down
          class="nav-item dropdownmobile"
          title-classes="nav-link"
          icon="fa fa-user"
        >
          <router-link
            v-if="islogged"
            to="/dashboard/profile"
            class="dropdown-item"
            >Mon profil</router-link
          >
          <router-link
            v-if="islogged"
            to="/dashboard/organization"
            class="dropdown-item"
            >Organisation</router-link
          >
          <router-link to="/dashboard/settings" class="dropdown-item"
            >Paramétrage</router-link
          >
          <router-link
            v-if="islogged"
            to="/dashboard/profile/change-password"
            class="dropdown-item"
            >Changer mot de passe</router-link
          >
          <a v-if="islogged" @click="logout" class="dropdown-item">Log out</a>
        </drop-down>
      </ul>

      <div class="collapse navbar-collapse">
        <div class="demo">
          <a href="#" @click.prevent="displayDemo">Démo</a>
        </div>
        <ul class="navbar-nav">
          <drop-down
            class="nav-item"
            title-classes="nav-link"
            icon="fa fa-user"
          >
            <router-link
              v-if="islogged"
              to="/dashboard/profile"
              class="dropdown-item"
              >Mon profil</router-link
            >
            <router-link
              v-if="islogged"
              to="/dashboard/organization"
              class="dropdown-item"
              >Organisation</router-link
            >
            <router-link to="/dashboard/settings" class="dropdown-item"
              >Paramétrage</router-link
            >
            <router-link
              v-if="islogged"
              to="/dashboard/profile/change-password"
              class="dropdown-item"
              >Changer mot de passe</router-link
            >
            <a v-if="islogged" @click="logout" class="dropdown-item">Log out</a>
          </drop-down>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
import AuthService from "@/auth/AuthService";
import utils from "@/utils";
import trialBanner from "@/components/Plan/Trial.vue";
export default {
  computed: {
    routeName() {
      const { name } = this.$route;
      return this.chechPage(name);
    },
    isTrial() {
      let subscription = this.$store.getters["contractor/getSubscriptionInfo"];
      if (typeof subscription.plan !== "undefined") {
        return utils.plan.isTrial(subscription.plan);
      } else return false;
    },
    expired() {
      let subscription = this.$store.getters["contractor/getSubscriptionInfo"];
      let plan_expiry_date = new Date(subscription.plan_expiry_date);
      let today = new Date();
      if (today > plan_expiry_date) {
        return true;
      } else return false;
    },
    trialRemainingDays() {
      let subscription = this.$store.getters["contractor/getSubscriptionInfo"];
      let plan_expiry_date = new Date(subscription.plan_expiry_date);
      let today = new Date();
      let diffTime = Math.abs(today - plan_expiry_date);
      //console.log(plan_expiry_date)
      //console.log(today)
      let remainingDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return remainingDays;
    },
  },
  data() {
    return {
      activeNotifications: false,
      islogged: AuthService.checkAuth("contractor"),
    };
  },
  components: {
    trialBanner,
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    chechPage(name) {
      return name;
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    // Log the user out
    logout() {
      this.$store.dispatch("auth/logout");
    },
    displayDemo() {
      this.$store.dispatch("contractor/displayDemo");
    },
  },
};
</script>
<style>
#brand {
  font-family: Nunito, sans-serif;
  color: #2d3748;
}
.notif {
  float: right;
}
.logop {
  color: #eebb4d;
  font-weight: bold;
}
h2 {
  font-weight: bold;
}
.dropdown-item {
  background-color: white;
  text-transform: none;
}

.fa-user::before {
  font-size: 34px;
  color: gray;
}
a.dropdown-toggle.btn-rotate.nav-link {
  margin-top: -30px;
}
ul.dropdown-menu.show::after {
  color: #f1f3f5;
}
#navbutton {
  border-color: #f1f3f5;
}
.logo-gray {
  height: 25px;
}
.trial-banner {
  display: none;
}

.demo a {
  background-color: #eabe00;
  color: #25273e;
  padding: 5px 20px;
  border-radius: 5px;
  margin-right: 20px;
}
.navbar-collapse {
  justify-content: flex-end;
}
@media screen and (min-width: 990px) {
  .dropdownmobile,
  .logo-gray {
    visibility: hidden;
  }
  #mynav {
    height: 50px;
    position: fixed;
    width: 77%;

    z-index: 1001;

    /**
   z-index: 0;
   */
  }
  .trial-banner {
    display: block;
  }

  @media screen and (min-width: 1200px) {
    #mynav {
      width: 82%;
    }
  }
}
@media screen and (max-width: 990px) {
  #mynav {
    height: 75px;
  }
  #brand {
    display: none;
  }
  .dropdownmobile {
    z-index: 1;
  }
  li.dropdown.nav-item.dropdownmobile {
    height: 75px;
  }
}
</style>
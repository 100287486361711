<template>
  <div>
    <div class="stats">
      Total : {{ stats.total }} / Récupérés : {{ stats.loaded }} / Filtrés :
      {{ stats.filtered }}
    </div>
    <loader v-if="loading"></loader>
    <div v-if="isEmpty() && !loading">
      <empty-state>
        <p>
          Vous pouvez commencer à créer vos opérations en cliquant sur le bouton
          "Nouvelle Opération"
        </p>
      </empty-state>
    </div>
    <div v-show="hasFilteredOperations() && !isEmpty()">
      <empty-state>
        <p>
          Aucune opération trouvée! Veuillez désactiver le filtre ou modifier les
          critères de recherche
        </p>
      </empty-state>
    </div>
    <list-header
      v-show="!hasFilteredOperations() && !loading"
      class="listOperations"
      titre1="RÉFÉRENCE"
      titre2="PRIORITÉ"
      titre3="STATUS"
      titre4="TITRE"
      titre5="CATÉGORIE"
      titre6="INTERVENANT"
      titre7="CRÉEE LE"
    >
      <div v-for="operation in filteredOperations" :key="operation.id">
        <card class="card desktop-style">
          <div class="mycard" v-on:click="goOperationDetails(operation.id)">
            <div class="row head">
              <span class="col-lg-2 col-md-4 col-sm-4 col-4 operationId"
                ><font color="#5fd5b7">No </font>
                {{ operation.reference }}</span
              >
              <p
                class="priorité col-lg-1 col-md-4 col-sm-4 col-3"
                v-bind:style="applyColor(operation.priority.color)"
              >
                {{ operation.priority.label }}
              </p>
              <div class="status col-lg-2 col-md-4 col-sm-4 col-5">
                <span
                  class="h5"
                  v-bind:style="applyBackgroundColor(operation.status.color)"
                >
                  <font color="#ffffff"> {{ operation.status.label }} </font>
                </span>
              </div>
              <p class="titreDesktop col-lg-3">
                {{ titleSplit(operation.title) }}
              </p>
              <div class="catDesktop col-lg-1">
                <p class="cat">{{ operation.category.label }}</p>
              </div>
              <div
                class="userDesktop col-lg-2"
                v-if="
                  typeof operation.assignee == 'undefined' ||
                  typeof operation.assignee == 'null' ||
                  operation.assignee == null
                "
              >
                <p>Non assigné</p>
              </div>
              <div
                class="userDesktop col-lg-2"
                v-if="
                  typeof operation.assignee != 'undefined' &&
                  typeof operation.assignee != 'null' &&
                  operation.assignee != null
                "
              >
                <p>
                  <img :src="operation.assignee.picture" class="userPicture" />
                  {{ operation.assignee.firstName }}
                  {{ operation.assignee.lastName }}
                </p>
              </div>
              <div class="createdat col-lg-1">
                <p id="datecreation">{{ operation.createdAt.substr(0, 10) }}</p>
              </div>
            </div>
            <div class="row">
              <div class="row">
                <p class="titre">{{ titleSplit(operation.title) }}</p>
              </div>
              <div class="row">
                <div class="col-md-6 col-sm-6 col-5">
                  <p class="category">{{ operation.category }}</p>
                </div>
                <div
                  class="col-md-6 col-sm-6 col-7"
                  v-if="
                    typeof operation.assignee == 'undefined' ||
                    typeof operation.assignee == 'null' ||
                    operation.assignee == null
                  "
                >
                  <p class="user">Non assigné</p>
                </div>
                <div
                  class="col-md-6 col-sm-6 col-7"
                  v-if="
                    typeof operation.assignee != 'undefined' &&
                    typeof operation.assignee != 'null' &&
                    operation.assignee != null
                  "
                >
                  <p class="user">
                    {{ operation.assignee.firstName }}
                    <img
                      :src="operation.assignee.picture"
                      class="userPicture"
                    />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </card>
        <card class="card mobile-style">
          <div class="mycard" v-on:click="goOperationDetails(operation.id)">
            <div class="row head">
              <span class="col-6 operationId"
                ><font color="#5fd5b7">No </font>
                {{ operation.reference }}</span
              >
              <div class="status col-6">
                <span
                  class="h5"
                  v-bind:style="applyBackgroundColor(operation.status.color)"
                >
                  <font color="#ffffff"> {{ operation.status.label }} </font>
                </span>
              </div>
            </div>
            <div class="row">
              <p class="col-12 titre-mobile">
                {{ titleSplit(operation.title) }}
              </p>
              <!--
                                <div class="col-md-6 col-sm-6 col-5"> <p class="category"> {{operation.category}} </p> </div>
                                -->
              <div class="col-7 creation-date-mobile">
                <p>Créé le {{ operation.createdAt.substr(0, 10) }}</p>
              </div>
              <div
                class="col-5"
                v-if="
                  typeof operation.assignee == 'undefined' ||
                  typeof operation.assignee == 'null' ||
                  operation.assignee == null
                "
              >
                <p class="user">Non assigné</p>
              </div>
              <div
                class="col-5"
                v-if="
                  typeof operation.assignee != 'undefined' &&
                  typeof operation.assignee != 'null' &&
                  operation.assignee != null
                "
              >
                <p class="user">
                  {{ operation.assignee.firstName }}
                  <img :src="operation.assignee.picture" class="userPicture" />
                </p>
              </div>
            </div>
          </div>
        </card>
      </div>
    </list-header>
    <div v-if="!isEmpty() && canloadMore" class="load-more-wrapper">
      <el-button
        round
        v-on:click="loadMoreOperations"
        :loading="loadingMoreOperations"
        >load more</el-button
      >
    </div>
    <!--
        <div v-else>all loaded</div>
        -->
    <!--
        <div v-if="operations.length" v-observe-visibility="handleScrolledToBottom"> 
            <scroll-loader :loader-method="handleScrolledToBottom" :loader-disable="allOperationsLoaded" :loader-size='30'></scroll-loader>
        </div>
        -->
  </div>
</template>
<script>
import configColor from "@/api/config.js";
import AuthService from "@/auth/AuthService.js";
import EventBus from "@/eventBus";
import ListHeader from "../../components/Global/listHeader.vue";
import EmptyState from "@/components/Global/EmptyState.vue";
import Loader from "@/components/Global/Loader.vue";

export default {
  components: {
    ListHeader,
    EmptyState,
    Loader,
  },
  data: function () {
    return {
      search: "",
      limit: 6,
      disable: false,
      value: true,
      status: [],
      category: "",
      firstName: "",
      lastName: "",
      //loading: true,
      loadingMoreOperations: false,
    };
  },
  computed: {
    operations() {
      return this.$store.getters["contractor/getOperations"];
    },
    operationsWithMeta() {
      return this.$store.getters["contractor/getOperationsWithMeta"];
    },
    operationsFilter() {
      return this.$store.getters["contractor/getOperationsFilter"];
    },
    loading() {
      return this.$store.getters["contractor/getLoader"].operationsList;
    },
    filteredOperations() {
      let operations = this.operations;
      let filteredOperations = [];
      let filter = this.operationsFilter;
      filteredOperations = operations.filter((operation) => {
        let doesStatusMatch = true;
        let doesCategoryMatch = true;
        let doesAssigneeMatch = true;
        let doesDepartmentMatch = true;
        if (filter.statuses.length > 0) {
          //return filter.statuses[0].id == operation.status.id
          doesStatusMatch = false;
          filter.statuses.forEach((element) => {
            if (element.id == operation.status.id) {
              doesStatusMatch = true;
            }
          });
        } else {
          doesStatusMatch = true;
        }
        if (Object.keys(filter.category).length != 0) {
          doesCategoryMatch = false;
          if (filter.category.id == operation.category.id) {
            doesCategoryMatch = true;
          }
        } else {
          doesCategoryMatch = true;
        }

        if (Object.keys(filter.assignee).length != 0) {
          doesAssigneeMatch = false;
          if (
            typeof operation.assignee !== "undefined" &&
            operation.assignee !== null
          ) {
            if (filter.assignee.id === operation.assignee.id) {
              doesAssigneeMatch = true;
            } else {
              doesAssigneeMatch = false;
            }
          }
        } else {
          doesAssigneeMatch = true;
        }

        if (Object.keys(filter.department).length != 0) {
          doesDepartmentMatch = false;
          if (
            typeof operation.location !== "undefined" &&
            operation.location !== null
          ) {
            if (filter.department.code === operation.location.department) {
              doesDepartmentMatch = true;
            } else {
              doesDepartmentMatch = false;
            }
          }
        } else {
          doesDepartmentMatch = true;
        }

        return (
          doesStatusMatch &&
          doesCategoryMatch &&
          doesAssigneeMatch &&
          doesDepartmentMatch
        );
      });
      return filteredOperations;
    },
    getAssigneePicture(assignee) {
      let user = JSON.parse(localStorage.getItem("user_account"));
      return user.picture;
    },
    allOperationsLoaded() {
      return this.$store.getters["contractor/operationsLoaded"];
    },
    canloadMore() {
      if (
        this.operations.length < this.operationsWithMeta.meta.pagination.total
      ) {
        return true;
      } else {
        return false;
      }
    },
    stats() {
      let operations = this.$store.getters["contractor/getOperations"];
      let operationsWithMeta =
        this.$store.getters["contractor/getOperationsWithMeta"];
      //let filteredOperations = this.$store.getters['contractor/getFilteredOperations'];
      return {
        total: operationsWithMeta.meta.pagination.total,
        loaded: operations.length,
        filtered: this.filteredOperations.length,
      };
    },
  },
  methods: {
    handleScrolledToBottom(isVisible) {
      if (!isVisible) {
        return;
      }

      this.assigneeFilter = "";
      this.categoryFilter = "";
      this.statusFilter = [
        "Nouveau",
        "Prête",
        "Préparation",
        "En cours",
        "Planifiée",
      ];
      let options = {
        limit: 20,
        start: this.operations.length,
        status: this.statusFilter,
        category: this.categoryFilter,
        firstName: this.assigneeFilter,
        lastName: this.assigneeFilter,
      };
      EventBus.$emit("ADD_FILTER", options);
      this.$store.dispatch("contractor/loadMoreOperations", options);
    },
    goOperationDetails(id) {
      this.$store.dispatch("contractor/setOperationId", id);
      this.$router.push("/dashboard/operations/" + id);
    },
    async loadMoreOperations() {
      this.loadingMoreOperations = true;
      await this.$store.dispatch("contractor/loadOperations");
      this.loadingMoreOperations = false;
    },
    isEmpty() {
      let totalOperations = this.stats.total;
      if (totalOperations > 0) {
        return false;
      } else return true;
    },
    hasFilteredOperations() {
      let filteredOperations = this.stats.filtered;
      if (filteredOperations > 0) {
        return false;
      } else return true;
    },
    /*
        statusBgColor(status)
        {
            for(var i=0;i<configColor.Status.length;i++){
                if(configColor.Status[i].name==status)
                {
                    return { 
                    'background-color':configColor.Status[i].color,
                    'border-color':configColor.Status[i].color,
                    'color':'white'
                    };
                }
            }            
        },
        */
    /*
        priorityColor(priority)
        {
        for(var i=0;i<configColor.Priorities.length;i++){
            if(configColor.Priorities[i].name==priority)
            {
                return { 
                'color':configColor.Priorities[i].color,
                };
            }
        }
        },
        */
    applyColor(color) {
      return {
        color: color,
      };
    },
    applyBackgroundColor(color) {
      return {
        "background-color": color,
        "border-color": color,
        color: "white",
      };
    },
    titleSplit(title) {
      var stringTitle = "";
      if (title && title.length > 40) {
        for (var i = 0; i < 5; i++) {
          stringTitle = stringTitle + " ".concat(title.split(" ")[i]);
        }
        return stringTitle;
      } else return title;

      console.log(title.length);
    },
  },

  async created() {
    //we get the status filter if present
    //console.log(statusID)
    /*
        EventBus.$on('ADD_FILTER', (options) => {
            this.status=options.status
            this.category=options.category
            this.firstName=options.firstName
            this.lastName=options.lastName
        })
        */
  },
};
</script>
<style scoped>
.load-more-wrapper {
  text-align: center;
}
.desktop-style {
  display: block;
  height: 65px;
}
.mobile-style {
  display: none;
  box-shadow: 0 2px 2px rgb(204 201 220 / 49%);
  height: 130px;
}

.titre-mobile {
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.creation-date-mobile p {
  font-size: 12px;
  color: gray;
}
.mobile-style hr {
  border-top: 1px solid #f1f3f4;
  margin: 10px 0px;
}
.createdat {
  padding-right: 0px;
  color: gray;
}

.operationId {
  font-weight: 600;
}
#datecreation {
  font-size: 12px !important;
}
.userPicture {
  border-radius: 15px;
  width: 25px;
  height: 25px;
}
.searchInput {
  padding: auto;
  padding-left: 20px;
  width: 30%;
  margin: 0px;
  margin-top: 10px;
  border-radius: 10px;
  border: none;
  float: left;
}
.desktop-style:hover {
  background-color: #8f919417;
  cursor: pointer;
}
.mobile-style:hover {
  background-color: #8f919417;
  cursor: pointer;
}
.card {
  margin-top: 10px;
  border: none;
  font-size: 14px;
  border-radius: 10px;
}
hr {
  display: block;
  width: 100%;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}
p {
  margin-bottom: 0px;
  font-size: 14px;
}
.status {
  float: left;
  font-weight: 700;
  line-height: 99%;
  text-transform: uppercase;
}
.h5 {
  font-size: 12px;
  font-weight: 700;
  border-radius: 5px;
  padding: 2px 10px;
}
.priorité {
  margin: auto;
  margin-right: 0px;
  margin-left: 0px;
  padding: 0px;
}
.row {
  padding-left: 5px;
}
.user {
  float: right;
  padding: 0px;
}
.catDesktop {
  padding: 0px;
  padding-left: 10px;
  margin: auto;
}
.cat,
.category {
  color: #eebb4d;
  padding: 0px;
  font-size: 13px;
}
.titre {
  padding-left: 20px;
  font-size: 14px;
}
.titreDesktop,
.userDesktop,
.operationId {
  margin: auto;
  margin-left: 0px;
  margin-right: 0px;
}
.userDesktop {
  padding: 0px;
  text-align: center;
}
@media screen and (min-width: 999px) {
  .category,
  .user,
  .titre {
    display: none;
  }
  .catDesktop,
  .titreDesktop,
  .userDesktop {
    display: block;
  }
  hr {
    display: none;
  }
}
@media screen and (max-width: 999px) {
  .catDesktop,
  .titreDesktop,
  .userDesktop,
  .headers {
    display: none;
  }
  .status,
  .priorité {
    text-align: right;
  }
  .desktop-style {
    display: none;
  }
  .mobile-style {
    display: block;
  }
}
@media screen and (max-width: 695px) {
  #opcard,
  .priorité,
  .category {
    font-size: 14px;
  }
  .desktop-style {
    display: none;
  }
  .mobile-style {
    display: block;
  }
}
@media screen and (max-width: 576px) {
  .desktop-style {
    display: none;
  }
  .mobile-style {
    display: block;
  }
  .priorité {
    margin-right: 0px;
    margin-left: 0px;
    text-align: center;
    padding-left: 0px;
    padding-right: 0px;
  }
  .searchInput {
    width: 50%;
  }
  .head {
    margin-right: 0px;
  }
}
</style>
import DashboardLayout from "@/layout/dashboard/DashboardLayout.vue";
// GeneralViews
import NotFound from "@/pages/NotFoundPage.vue";

// Admin pages
import Dashboard from "@/pages/Dashboard/Dashboard.vue";
import UserProfile from "@/pages/Profile/UserProfile.vue";
import Operations from "@/pages/Operations/Main.vue";
import newOperation from "@/pages/Operations/New.vue";
//import newOperation from "@/pages/Operations/New_v2.vue";

import updateOperation from "@/pages/Operations/UpdateOperation.vue";
import Calendrier from "@/pages/Calendrier/Calendrier.vue";
import TeamList from "@/pages/Equipe/Main.vue";
import CRM from "@/pages/CRM/CRM.vue";
import LandingPage from "@/pages/Landing/LandingPage.vue";
import rapports from "@/pages/Analytics/Rapports.vue";
import projects from "@/pages/Projects/Main.vue";
import settings from "@/pages/Administration/settings.vue";
import OperationDetail from "@/pages/Operations/Details.vue";
import kanban from "@/pages/Operations/Kanban.vue";
import list from "@/components/Global/listHeader";
import newMember from "@/pages/Equipe/NewMember.vue";
import MyOperations from "@/pages/Operations/MyOperations.vue";
import categoriesList from "@/pages/Administration/category.vue";
import prioritiesList from "@/pages/Administration/priority.vue";
import statusesList from "@/pages/Administration/status.vue";
//Authentication views
import Signup from "@/pages/Auth/signup";
import Signin from "@/pages/Auth/signin";
import SignupWithInvitation from "@/pages/Auth/signupWithInvitation";
import callback from "@/components/auth/callback";
import changePassword from "@/pages/Auth/changePassword";
import AuthLayout from "@/layout/Auth/AuthLayout.vue";
import ForgotPassword from "@/pages/Auth/forgotPassword";
import ResetPassword from "@/pages/Auth/resetPassword";

//Organization views
import organization from "@/pages/Organization/Main.vue";
import organizationProfile from "@/pages/Organization/Organization.vue";

//Plan and upgrade views
import Upgrade from "@/pages/Plan/Upgrade.vue";

//Unauthorized page
import Unauthorized from "@/pages/UnauthorizedPage.vue";

//Customers view
import Customers from "@/pages/CRM/Main.vue"

const routes = [
  /*
  {
    path: '/auth/signup',
    name: 'signup',
    component: signup
  },
  {
    path: '/auth/signin',
    name: 'signin',
    component: signin
  },
  */
  {
    path: "/auth",
    component: AuthLayout,
    redirect: "/signin",
    children: [
      {
        path: "/auth/signin",
        name: "signin",
        component: Signin,
      },
      /*
      {
        path:"/auth/signup",
        name: "signup",
        component: Signup
      },
      */
      {
        path: "/auth/signupWithInvitation",
        name: "signupWithInvitation",
        component: SignupWithInvitation,
      },
      {
        path: "/auth/forgot-password",
        name: "forgotPassword",
        component: ForgotPassword,
      },
      {
        path: "/auth/reset-password",
        name: "resetPassword",
        component: ResetPassword,
      },
    ],
  },
  /*
  {
    path:'/auth/callback',
    name:'callback',
    component: callback
  },
  */
  {
    path: "/upgrade",
    name: "Upgrade",
    component: Upgrade,
  },
  {
    path: "/checkout/cancel",
    name: "checkout.cancel",
    redirect: "/upgrade",
  },
  {
    path: "/checkout/success",
    name: "checkout.success",
    redirect: "/home",
  },
  {
    path: "/unauthorized",
    name: "Unauthorized",
    component: Unauthorized,
  },
  {
    path: "/",
    component: DashboardLayout,
    redirect: "/home",
    children: [
      {
        path: "/dashboard/operations",
        name: "Opérations",
        component: Operations,
        children: [
          {
            path: "/dashboard/operations",
            name: "Liste des opérations",
            component: list,
          },
          {
            path: "/dashboard/operations",
            name: "kanban",
            component: kanban,
          },
        ],
      },
      /*
      {
        path: "/dashboard/upgrade",
        name: "Upgrade",
        component: Upgrade,
      },
      */
      {
        path: "home",
        name: "Accueil",
        component: Dashboard,
      },
      {
        path: "/dashboard/profile",
        name: "profil",
        component: UserProfile,
      },
      {
        path: "/dashboard/profile/change-password",
        name: "Changement mot de passe",
        component: changePassword,
      },

      {
        path: "/dashboard/calendrier",
        name: "Calendrier",
        component: Calendrier,
      },
      {
        path: "/dashboard/crm",
        name: "Carnet clients",
        component: Customers,
        meta: {
          feature: "CLIENTS",
          roles: ["owner", "admin"],
        },
      },
      {
        path: "/dashboard/equipe",
        name: "Equipe",
        component: TeamList,
        meta: {
          roles: ["owner", "admin"],
        },
      },
      {
        path: "/dashboard/equipe/new",
        name: "Equipe > Nouveau membre",
        component: newMember,
      },
      {
        path: "/dashboard/rapports",
        name: "Rapports",
        component: rapports,
      },
      {
        path: "/dashboard/projects",
        name: "Projets",
        component: projects,
      },
      {
        path: "/dashboard/landing",
        name: "Vitrine",
        component: LandingPage,
        meta: {
          feature: "CLIENT_PORTAL",
          roles: ["owner", "admin"],
        },
      },
      {
        path: "/dashboard/myOperations",
        name: "Mes opérations",
        component: MyOperations,
      },
      {
        path: "/dashboard/settings",
        name: "settings",
        redirect: "/dashboard/settings/categories",
        component: settings,
        meta: {
          roles: ["owner", "admin"],
        },
        children: [
          {
            path: "/dashboard/settings/categories",
            name: "Liste des catégories",
            component: categoriesList,
            meta: {
              roles: ["owner", "admin"],
            },
          },
          {
            path: "/dashboard/settings/priorities",
            name: "Liste des priorités",
            component: prioritiesList,
            meta: {
              roles: ["owner", "admin"],
            },
          },
          {
            path: "/dashboard/settings/statuses",
            name: "Liste des status",
            component: statusesList,
            meta: {
              roles: ["owner", "admin"],
            },
          },
        ],
      },
      {
        path: "/dashboard/organization",
        name: "organization",
        redirect: "/dashboard/organization/general",
        component: organization,
        meta: {
          roles: ["owner", "admin"],
        },
        children: [
          {
            path: "/dashboard/organization/general",
            name: "Infos générales",
            component: organizationProfile,
            meta: {
              roles: ["owner", "admin"],
            },
          },
          {
            path: "/dashboard/settings/priorities",
            name: "Liste des priorités",
            component: prioritiesList,
          },
          {
            path: "/dashboard/settings/statuses",
            name: "Liste des status",
            component: statusesList,
          },
        ],
      },
      {
        path: "/dashboard/operations/new",
        name: "Nouvelle operation",
        component: newOperation,
      },
      {
        path: "/dashboard/operations/update/:id",
        name: "Modification de l'opération",
        component: updateOperation,
      },
      {
        path: "/dashboard/operations/:id",
        name: "Détails de l'opération",
        component: OperationDetail,
      },
    ],
  },
  { path: "*", component: NotFound },
];

/**
 * Asynchronously load view (Webpack Lazy loading compatible)
 * The specified component must be inside the Views folder
 * @param  {string} name  the filename (basename) of the view to load.
function view(name) {
   var res= require('../components/Dashboard/Views/' + name + '.vue');
   return res;
};**/

export default routes;

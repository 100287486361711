<template>
  <modal
    name="edit-modal"
    class="eventModal"
    :scrollable="false"
    :resizable="false"
    :adaptive="true"
    :clickToClose="true"
    classes="modal-custom"
    styles="overflow:auto;"
    width="400"
    shiftY="0.2"
    @opened="updateValues"
    @closed="resetValues"
  >
    <card class="card">
      <a href="#" class="close" @click="hide"></a>
      <FormulateForm
        v-model="values"
        :schema="schema"
        @submit="submitForm"
      ></FormulateForm>
    </card>
  </modal>
</template>
<script>
import EventBus from "@/eventBus";

export default {
  data: function () {
    return {
      submitted: false,
      switch: false,
      values: {},
    };
  },
  computed: {},
  props: {
    displayed: Boolean,
    title: String,
    schema: Array,
    data: Object,
    editFunction: Function,
  },
  computed: {},
  methods: {
    show() {
      this.values = this.data;
      this.$modal.show("config-modal");

      this.displayed = true;
    },

    hide() {
      this.$modal.hide("edit-modal");
      //this.displayed = false;
    },
    close() {
      //this.$emit("close");
      //this.$modal.hide("config-modal");
      // this.displayed = false;
    },
    updateValues() {
      console.log("----update values -----");
      this.values = this.data;
    },
    resetValues() {
      this.values = {};
    },
    async submitForm() {
      console.log("---submit---");
      console.log(this.values);
      await this.editFunction(this.values);
      this.$modal.hide("edit-modal");
    },
  },
  mounted() {
    // this.$modal.show("config-modal")
    //this.values = this.data
    //console.log(this.data)
    this.values = this.data;
    console.log("----values -----");
    console.log(this.values);
  },
  created() {
    this.values = this.data;
    console.log("----values -----");
    console.log(this.values);
  },
};
</script>
<style scoped>
.close {
  position: absolute;
  right: 32px;
  top: 32px;
  width: 32px;
  height: 32px;
  opacity: 0.3;
}
.close:hover {
  opacity: 1;
}
.close:before,
.close:after {
  position: absolute;
  left: 15px;
  content: " ";
  height: 33px;
  width: 2px;
  background-color: #333;
}
.close:before {
  transform: rotate(45deg);
}
.close:after {
  transform: rotate(-45deg);
}
.modal-custom {
  right: 0;
  position: absolute;
  min-height: 60vh;
  width: 300px;
  border-radius: 10px !important;
  padding: 10px;
}

.modal-custom h3 {
  margin-bottom: 10px;
  margin-top: 0px;
  font-size: 24px;
  font-weight: bold;
}
</style>

<template>
  <div class="expiration-wrapper">
    <card class="card card-expiration">
      <div class="card-body">
        <i class="ti-lock locker"></i><br />
        <p v-if="isTrial">
          Votre période d'essai a expiré, abonnez-vous pour continuer à profiter
          d'Optimop
        </p>
        <p v-else>
          Votre abonnement n'a pas été renouvelé, veuillez régler votre facture
          pour accéder à l'application
        </p>
        <el-button type="primary" round v-on:click="navigateTo('/upgrade')"
          >S'abonner</el-button
        >
      </div>
    </card>
  </div>
</template>
<script>
export default {
  props: {
    isTrial: {
      type: Boolean,
      default: false,
    },
  },
  computed: {},
  methods: {
    navigateTo(nav) {
      this.$router.push(nav);
    },
  },
  mounted() {},
  created() {},
};
</script>

<style scoped>
.expiration-wrapper {
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 1000;
  background-color: rgb(105 105 105 / 70%);
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  justify-content: space-around;
}

.card-expiration {
  width: 300px;
  height: 250px;
  text-align: center;
}
.locker {
  padding: 10px;
  border-radius: 50%;
  background-color: #eabe00;
  color: white;
  font-size: 20px;
}
.card-expiration .card-body {
  display: flex !important;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  align-content: center;
}
</style>

import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes";
import store from "@/store";
import AuthService from "@/auth/AuthService";

Vue.use(VueRouter);

// configure router
const router = new VueRouter({
  mode: "history",
  routes,
  linkActiveClass: "active",
});
router.beforeEach(async (to, from, next) => {
  let appLoaded = store.getters["contractor/appLoaded"];

  if (!AuthService.checkAuth("contractor") && to.path === "/home") {
    next("/auth/signin");
    window.history.forward();
  }
  if (AuthService.checkAuth("contractor") && to.path === "/auth/signin") {
    next({
      path: "/home",
    });
  }
  if (AuthService.checkAuth("contractor") && to.path === "/auth/signup") {
    next({
      path: "/home",
    });
  }

  if (
    AuthService.checkAuth("contractor") &&
    typeof to.meta.roles !== "undefined"
  ) {
    if (appLoaded === false) {

      await store.dispatch("auth/loadProfile");
      await store.dispatch("contractor/loadOrganization");

    }
    let currentRole = store.getters["auth/getCurrentRole"];
    if (!to.meta.roles.includes(currentRole)) {
      next({
        path: "/unauthorized",
      });
    }
  }

  if (
    AuthService.checkAuth("contractor") &&
    typeof to.meta.feature !== "undefined"
  ) {
    if (appLoaded === false) {
      await store.dispatch("auth/loadProfile");
      await store.dispatch("contractor/loadOrganization");
    }

    let subscription = store.getters["contractor/getSubscriptionInfo"];
    let availableFeatures = [];
    if (typeof subscription.features != "undefined") {
      availableFeatures = subscription.plan.features.concat(
        subscription.features
      );
    } else {
      availableFeatures = subscription.plan.features;
    }
    if (!availableFeatures.includes(to.meta.feature)) {
      next({
        path: "/upgrade",
      });
    } else {
      next();
    }
  } else {
    next(); // make sure to always call next()!
  }
});

export default router;

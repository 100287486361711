<template>
<div>
    <div class="sidebar">
    <img class="logo-signin" src="@/assets/img/proximov-logo-white.png" alt="">
  
    <div class="illustration-wrapper">

        <img src="@/assets/img/illustration-2.svg">
            <ul>
             <div>
                <li class="text1">Bienvenue sur Optimop</li>           

            </div>               
        </ul>
    </div>
    </div>
</div>
</template>
<script>
export default {
    name:'auth-sidebar',
    data :function(){
        return{
            titles:[
                {
                    titre: this.$t("sideBar_signin_section_1_title"),
                    numero:1,
                    description: this.$t("sideBar_signin_section_1_description")

                },
                {
                    titre: this.$t("sideBar_signin_section_2_title"),
                    numero:2,
                    description: this.$t("sideBar_signin_section_2_description")
                },
                {
                    titre:this.$t("sideBar_signin_section_3_title"),
                    numero:3,
                    description: this.$t("sideBar_signin_section_3_description")
                }
            ]      
        }
       
    }
}
</script>
<style scoped>
.numberCircle {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    padding: 8px;
    background: #fff;
    border: 2px solid black;
    color: black;
    text-align: center;
    font: 25px Lucida   , sans-serif;
    display: inline-block;
    margin-right: 20px

}
.numberCircle,.text1
{
    display: inline-block;
}
.logo-signin {
    height : 34px;
    margin: 40px;
}
ul li{
    color: white;
    list-style-type: none;
    margin-bottom: 20px

}
.desc1{
    margin-left: 70px
}
.text1{
    font-weight: bold;
    font-size:23px
}
p{
    color: white;
    margin-top: 20px;
    margin-bottom: 40px;
    margin-left: 60px;
    font-weight: bold;
    font-size: xx-large;

}
.pro
{
    color: #eebb4d
}
.illustration-wrapper{
    text-align: center;
}

.illustration-wrapper img {
    width: 350px;
    margin: 50px;
}

</style>

<template>
  <modal
    name="attachments-modal"
    class="eventModal"
    :scrollable="false"
    :resizable="true"
    height="auto"
    :adaptive="true"
    :clickToClose="false"
  >
    <card class="card">
      <form>
        <div class="row">
          <a class="button col-12" @click="hide">
            <h6 class="xbutton ti-close"></h6>
          </a>
          <div class="col-12">
            <label>Titre</label>
            <input
              type="text"
              placeholder="Titre"
              name="titre"
              v-model="titleFile"
              class="form-control"
              required
            />
          </div>

          <div class="col-12">
            <label>Pièces jointes</label>
            <el-upload
              class="upload-demo"
              drag
              :action="action"
              multiple
              :limit="5"
              :name="titleFile"
              :auto-upload="false"
              :file-list="media"
              ref="upload"
              :on-change="handleChange"
              :on-remove="handleRemove"
              :thumbnail-mode="true"
            >
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">
                Drop file here or <em>click to upload</em>
              </div>
            </el-upload>
          </div>
          <div class="col-12 text-center">
            <el-button
              style="margin-left: 10px"
              size="small"
              type="primary"
              :loading="submitted"
              @click="Upload"
              >Click to upload</el-button
            >
          </div>
        </div>
      </form>
    </card>
  </modal>
</template>
<script>
import uploader from "vux-uploader-component";
import EventBus from "@/eventBus";
import AuthService from "@/auth/AuthService";

let apiURL = process.env.VUE_APP_API_URI;
let accountId = AuthService.getUserTokenId();
let headers = {
  "Content-Type": "multipart/form-data",
  Authorization: `Bearer ${accountId}`,
};
export default {
  data: function () {
    return {
      titleFile: "",
      submitted: false,
      switch: false,
      action: apiURL + "/attachments",
      headers: headers,
      media: [],
    };
  },
  components: {
    uploader,
  },
  computed: {
    operationID() {
      return this.$route.params.id;
    },
    operation() {
      return this.$store.getters["contractor/getCurrentOperation"];
    },
    /*
        media(){
        return this.$store.getters['contractor/getMedia']
        },*/
    dataAttachment() {
      var formData = new FormData();
      formData.append(
        "data",
        '{"title":"' +
          this.titleFile +
          '","operation":"' +
          this.operationID +
          '"}'
      );
      for (const file of this.media) {
        //filesArray.push(file.blob)
        formData.append("files.media", file.blob);
      }
      return formData;
    },
  },
  methods: {
    handleChange(file, fileList) {
      this.media = fileList;
      console.log(this.media)
    },
    handleRemove(file, fileList) {
      const index = this.media.indexOf(file);
      if (index > -1) {
        this.media.splice(index, 1);
      }
    },
    show() {
      this.$modal.show("attachments-modal");
      EventBus.$emit("ADD_ATTACHMENT", this.switch);
    },
    hide() {
      this.$modal.hide("attachments-modal");
      EventBus.$emit("ADD_ATTACHMENT", this.switch);
    },
    close() {
      this.$modal.close("attachments-modal");
      EventBus.$emit("ADD_ATTACHMENT", this.switch);
    },
    async Upload() {
      this.submitted = true;
      let payload = {
        id: this.operationID,
        title: this.titleFile,
        media: this.media,
        operationId: this.operation.id,
        organizationId: this.operation.organization.id,
      };
      console.log(this.titleFile);
      console.log(this.media);
      if (this.titleFile !== "" && this.media.length > 0) {
        await this.$store.dispatch("contractor/addAttachments", payload);
        this.submitted = false;
        this.$store.dispatch(
          "contractor/refreshOperationbyId",
          this.operationID
        );
        this.hide();
      } else {
        this.submitted = false;
      }
      /**
      await this.$store.dispatch("contractor/addAttachments", payload);
      this.submitted = false;
      this.$store.dispatch("contractor/refreshOperationbyId", this.operationID);
      this.hide();
      */
    },
    submitUpload() {
      this.$refs.upload.submit();
    },
    handlePictureCardPreview(file) {
      console.log(file);
    },
  },
  mounted() {
    this.$modal.show("attachments-modal");
  },
  created() {
    let that = this;
    document.addEventListener("keyup", function (evt) {
      if (evt.keyCode === 27) {
        that.hide();
      }
    });
  },
};
</script>

<style scoped>
.upload {
  color: gray;
  padding-left: 0px;
}
.bg-white {
  position: relative;
  margin-bottom: 20px;
}
i {
  font-family: FontAwesome;
  margin: 0 auto;
  font-size: 1rem;
  font-style: normal;
  left: 17px;
  padding: 2px;
  bottom: 17px;
  color: gray;
}
.form-control {
  padding-left: 25px;
  position: relative;
}
.vm--container::v-deep .vm--modal {
  left: 500px !important;
  top: 120px !important;
}
@media screen and (max-width: 1100px) {
  .vm--container::v-deep .vm--modal {
    left: 0px !important;
    /*top: 900px !important*/
    margin-top: 50%;
  }
  .vm--container::v-deep .vm--overlay {
    /* top: 800px !important*/
    margin-top: 50%;
  }
}
@media screen and (max-width: 450px) {
  .btn {
    width: 50%;
    font-size: 10px;
    padding: 4px;
  }
}
</style>

<template>
  <card class="empty_state">
    <div class="empty_content">
    <img class="empty_image" src="@/assets/img/empty_operations.png">
        <slot></slot>
      </div>
  </card>
</template>
<script>


export default {
  created () {

  },
  computed: {
  
  },
  components : {
  }
}
</script>
<style scoped>
  .empty_state{
    padding: 50px;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .empty_content{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  img {
    width: 200px;
  }
</style>


<template>
    <div class="card">
      <div class="row card-header">
        <p class="col-10">
          {{title}}
      </p>
       
        <div v-show="!loading" class="col-2 kpi">
          {{kpi}}
        </div>
      </div>
      <loader v-show="loading" type="pulse"></loader>      
   
      <div v-show="!loading || operations.length === 0" v-for="operation in operations" :key="operation.id">
          <div class="mobile-style" >
                <div class="mycard" v-on:click="goOperationDetails(operation.id)" >
                    <div class="row head">
                        <span class="col-7 operationId"><font color="#5fd5b7">No </font> {{operation.reference}}</span> 
                        <div class="status col-5"><span class="h5" v-bind:style="applyBackgroundColor(operation.status.color)"> <font color="#ffffff"> {{operation.status.label}} </font>  </span></div>
                    </div>
                    <div class="row">
                            <p class="col-12 titre-mobile">{{titleSplit(operation.title)}}</p>                                <!--
                                <div class="col-md-6 col-sm-6 col-5"> <p class="category"> {{operation.category}} </p> </div>
                                -->
                                <div class="col-9 creation-date-mobile"><p>Dernière modification :  {{operation.updatedAt.substr(0,10)}} </p></div>
                                <div class=" col-3" v-if="(typeof(operation.assignee) == 'undefined') || (typeof(operation.assignee) == 'null') || operation.assignee == null"> <p class="user"> Not assigned  </p> </div>
                                <div class=" col-3" v-if="(typeof(operation.assignee) != 'undefined') &&  (typeof(operation.assignee) != 'null') && operation.assignee != null"> <p class="user">{{operation.assignee.firstName}}  <img :src="operation.assignee.picture" class="userPicture">    </p> </div>
                    </div>
                </div>

            </div>
      </div>
      <div class="empty" v-show="operations.length === 0">
        <span :class="emptyIcon"></span>
        <p>{{emptyText}}</p>
        
      </div>
    </div>
</template>
<script>
import statsCard2 from '@/components/Cards/StatsCard2'
import loader from '@/components/Global/Loader'
import Loader from '../Global/Loader.vue';
export default {
  props: {
    operations: {
      type: Array,
    },
    title: {
      type: String,
    },
     kpi: {
      type: Number,
    },
    loading: {
      type: Boolean
    },
    emptyText: {
      type: String,
    },
    emptyIcon: {
      type: String,
    }
  },
  components:{
    statsCard2,
    Loader
  },
  methods: {
    statusBgColor(color) {
      return "background-color:" + color;
    },
    async goToOperation(id) {
      let options = {
        limit: 20,
        start: 0,
        status: [status],
        category: "",
        firstName: "",
        lastName: "",
      };
      //await this.$store.dispatch("contractor/loadOperations", options);
      //this.$store.dispatch("contractor/loadOperations", options);
      this.$router.push("/dashboard/operations/" + id );
    },
    goOperationDetails(id){
            this.$store.dispatch('contractor/setOperationId',id);
            this.$router.push('/dashboard/operations/'+id);
    },
       applyColor(color){
            return {
                'color': color
            }
        },
        applyBackgroundColor(color) {
            return { 
                    'background-color':color,
                    'border-color':color,
                    'color':'white'
         }
        },
        titleSplit(title){
            var stringTitle='';
            if(title && title.length>40)
            {
                for(var i=0;i<5;i++){
                    stringTitle=stringTitle+' '.concat(title.split(" ")[i])
                }
                return stringTitle
            }
            else return title
            
            console.log(title.length)
        }
  },
};
</script>
<style scoped>

.empty{
  padding-top: 20px;
  text-align: center;
  color:green
}

.empty span{
  font-size: 24px;
}

.empty p{
  font-weight: bold;
}

.card-header{
  text-transform: capitalize;
}

.kpi{
  font-size:18px;
  font-weight: bold;
}
.card{
  height: 400px;
  overflow-y: scroll;
  background-color: #e6eaed;
}

.mobile-style {
  /* ;
    box-shadow: 0 2px 2px rgb(204 201 220 / 49%);
    */
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: white;
    border-radius: 5px;
    margin : 0 10px 10px 10px;
    
}

.titre-mobile {
    font-size: 14px;
    margin-top: 5px;
    margin-bottom: 5px;
}
.creation-date-mobile p{
    font-size: 12px;
    color: gray;
}
.mobile-style hr {
    border-top:1px solid #f1f3f4;
    margin : 10px 0px;
}
.createdat{
    padding-right: 0px;
    color: gray;
}

.operationId {
    font-weight: 600;
}
#datecreation{
    font-size: 12px !important;
}
.userPicture{
    border-radius: 15px;
    width: 25px;
    height: 25px;
}

.desktop-style:hover{
    background-color: #8f919417;
    cursor: pointer;
}

.mobile-style:hover{
    cursor: pointer;
}

.card{
  margin-top:10px;
  border: none;
  font-size: 14px;
  border-radius: 10px;
}
hr {
    display: block;
    width: 100%;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 0;
}
p{
    margin-bottom: 0px;
  font-size:14px; 
}
.status{
    float: left;
    font-weight: 700;
    line-height: 99%;
    text-transform: uppercase;
}
.h5{
    font-size: 12px;
    font-weight: 700;
    border-radius: 5px;
    padding:2px 10px;
}
.priorité{
    margin: auto;
    margin-right: 0px;
    margin-left: 0px;
    padding: 0px;
}
.row{
    padding-left: 5px;
}
.user{
    float: right;
    padding: 0px;
}
.catDesktop{
    padding: 0px;
    padding-left: 10px;
    margin: auto;
}
.cat,.category{
    color: #eebb4d;
    padding: 0px;
    font-size: 13px;
}
.titre{
    padding-left: 20px;
    font-size: 14px;
}
.titreDesktop,.userDesktop,.operationId{
    margin: auto;
    margin-left: 0px;
    margin-right: 0px;
}
.userDesktop{
    padding: 0px;
    text-align: center
}
@media screen and (min-width: 999px){
   .category,.user,.titre
   {
       display: none;
   }
    .catDesktop,.titreDesktop,.userDesktop
   {
       display: block;
   }
   hr{
       display: none;
   }
}
@media screen and (max-width: 999px){
   .catDesktop,.titreDesktop,.userDesktop,.headers
   {
   display: none;
   }
   .status,.priorité{
    text-align: right;
   }
   .desktop-style{
    display: none;
    }
    .mobile-style{
    display: block;
    }
}
@media screen and (max-width: 695px){
#opcard,.priorité,.category{
    font-size: 14px
}
.desktop-style{
    display: none;
}
.mobile-style{
    display: block;
}
}
@media screen and (max-width: 576px){
.desktop-style{
    display: none;
}
.mobile-style{
    display: block;
}
.priorité{
    margin-right: 0px;
    margin-left: 0px;
    text-align: center;
    padding-left: 0px;
    padding-right: 0px;
}
.searchInput{
    width: 50%}
.head{
    margin-right: 0px;}
}

</style>

//import cities from './cities.json'
import franceCities from './france_cities.json'
//import departments from './departments.json'
//import store from '@/store';
import axios from 'axios'


export default {
  /*
  getComputedStyle: function () {
    return cities
  },
  getDepartments: function () {
    return departments
  },
  getCities: function (departmentCode) {

    let filteredCities = cities.filter(city => {
      return city.department_code === departmentCode
    })
    return filteredCities
  },
  */
  searchCities: function (query) {
    let result = franceCities.filter(function (el) {
      return el.Nom_commune.includes(query.toUpperCase())
    });
    return result.slice(0, 20)
  },
  searchCitiesByZipCode: function (query) {
    let result = franceCities.filter(function (el) {
      return el.Code_postal.toString().includes(query)
    });
    //console.log('filter ' + query)
    //console.log(result.slice(0, 10))
    return result.slice(0, 15)
  },
  getCityFromZipCode: function (zipCode) {
    let result = franceCities.filter(function (el) {
      return el.Code_postal === zipCode
    });
    return result[0]
  },
  getCityFromZipCodeAndName: function (zipCode, cityName) {
    let result = franceCities.filter(function (el) {
      return el.Code_postal === zipCode && el.Nom_commune === cityName
    });
    return result[0]
  },
  /*
  detectEntryWithoutZipCode: function () {
    let result = franceCities.filter(function (el) {
      return typeof (el.Code_postal) === "undefined"
    });
    let result2 = franceCities.filter(function (el) {
      return typeof (el.Code_commune_INSEE) === "undefined" || el.Code_commune_INSEE === ""
    });
    let result3 = franceCities.filter(function (el) {
      return el.Ligne_5 === ""
    });
    console.log(result)
    console.log(result2)
    console.log(result3)
  },
  */
  /*
  getPostalCodeFromCity: function (cityName) {

    let filteredCities = cities.filter(city => {
      return city.name === cityName
    })
    console.log(filteredCities[0])
    return filteredCities[0]
  },
  */
  plan: {
    isTrial: function (plan) {
      if (plan.name === "trial") {
        return true
      }
      else return false
    }
  }

}
<template>
  <div>
    <div style="margin-bottom: 10px">
      <el-button
        type="primary"
        @click="showAddModal"
        icon="el-icon-circle-plus-outline"
        round
      >
        Ajouter
      </el-button>
    </div>
    <div v-show="showInfo" class="row" style="margin-bottom: 10px">
      <el-alert :title="infoText" type="warning" :closable="true" show-icon>
      </el-alert>
    </div>

    <el-table
      :data="data"
      style="width: 100%"
      row-class-name="table-row"
      header-row-class-name="header-row"
    >
      <el-table-column
        v-for="column in columns"
        :key="column.id"
        :prop="column.prop"
        :label="column.label"
        :width="column.label"
        :formatter="column.formatter"
      >
      </el-table-column>
      <el-table-column label="Operations" width="200">
        <template slot-scope="scope">
          <el-button
            :disabled="!isRowEditable(scope.row)"
            icon="el-icon-edit"
            @click="edit(scope.row)"
            circle
          >
          </el-button>

          <el-button
            type="danger"
            :disabled="!isRowDeletable(scope.row)"
            icon="el-icon-delete"
            @click="deleteItem(scope.row)"
            circle
          ></el-button>
          <el-dropdown @command="handleCommand" v-if="actionsAvailable">
            <span class="el-dropdown-link">
              Actions <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-for="action in actions"
                :key="action.name"
                :command="{ item: scope.row, action: action.command }"
                >{{ action.name }}</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <add-modal
      title="test modal"
      :schema="this.addFormSchema"
      :data="this.formData"
      :addFunction="this.addFunction"
    >
    </add-modal>
    <edit-modal
      title="test modal"
      :schema="this.editFormSchema"
      :data="this.formData"
      :editFunction="this.editFunction"
    >
    </edit-modal>
  </div>
</template>
<script>
import addModal from "@/components/Global/addModal";
import editModal from "@/components/Global/editModal";
export default {
  name: "crud-table",
  props: {
    columns: Array,
    data: Array,
    editFunction: Function,
    isRowEditable: Function,
    isRowDeletable: Function,
    deleteFunction: Function,
    addFunction: Function,
    addFormSchema: Array,
    editFormSchema: Array,
    showInfo: {
      type: Boolean,
      default: false,
    },
    infoText: String,
    actions: {
      type: Array,
    },
  },
  data() {
    return {
      isModalDisplayed: false,
      formData: {},
    };
  },
  components: {
    addModal,
    editModal,
  },
  computed: {
    actionsAvailable() {
      if (typeof this.actions !== "undefined") {
        if (this.actions.length > 0) {
          return true;
        } else return false;
      } else return false;
    },
  },
  methods: {
    showAddModal: function () {
      //console.log("clicked")
      this.formData = {};
      this.isModalDisplayed = true;
      this.$modal.show("add-modal");
    },
    closeModal: function () {
      this.isModalDisplayed = false;
    },
    edit: function (element) {
      console.log(element);
      // this.isModalDisplayed=true
      this.formData = element;
      console.log(this.formData);

      this.$modal.show("edit-modal");
    },
    deleteItem: function (element) {
      console.log(element.id);
      console.log("start deletion");
      this.deleteFunction(element);
    },
    handleCommand: function (command) {
      //console.log(command.item);
      //console.log(command.action);
      command.action(command.item);
    },
  },
  created() {},
};
</script>
<style>
.el-dropdown {
  margin-left: 5px;
}

.el-table,
.el-table__expanded-cell {
  background-color: transparent !important;
}

.table-row {
  color: black;
  background-color: white !important;
}

.header-row {
  background-color: transparent !important;
}

.el-table th {
  background-color: transparent !important;
}

.el-table th:first-child {
  border-radius: 10px 0 0 10px;
}
.el-table th:last-child {
  border-radius: 0 10px 10px 0;
}

.table-row td:first-child {
  border-radius: 10px 0 0 10px;
}
.table-row td:last-child {
  border-radius: 0 10px 10px 0;
}

.el-table td,
.el-table th.is-leaf {
  border-bottom: 5px solid #f1f3f4 !important;
}
</style>

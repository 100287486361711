<template>
    <div class="row">
      <!--
      <div class="row">
        <calendrier-filter></calendrier-filter>
      </div> 
      -->
      <div class="row">
        <vue-cal
          today-button
          class="vuecal--green-theme"
          :time="true"
          :timeFrom="7 * 60"
          :timeTo="20 * 60"
          :timeStep="30"
          :events="rangeAll"
          :on-event-click="onEventClick"
          :disable-views="['years','year']" 
          locale="fr"
          :editableEvents="false"
          :small="true"
          id="vue-cal-desktop"
          >
        </vue-cal>
        <vue-cal
          today-button
          class="vuecal--green-theme"
          :time="true"
          :timeFrom="7 * 60"
          :timeTo="20 * 60"
          :timeStep="30"
          :events="rangeAll"
          :on-event-click="onEventClick"
          active-view="day"
          :disable-views="['years','year','week']" 
          locale="fr"
          :editableEvents="false"
          :small="true"
          id="vue-cal-mobile"
          >
        </vue-cal>
      </div>      
    </div>
</template>
<script>
import moment from 'moment';
import Dropdownlist from '@/components/Dropdownlist.vue'
import VueCal from 'vue-cal'
import 'vue-cal/dist/i18n/fr.js'
import 'vue-cal/dist/vuecal.css'
import 'vue-cal/dist/i18n/zh-cn.js'
import CalendrierFilter from './CalendrierFilter'
import EventBus from '@/eventBus'

export default {
  data: function() {
            return {  
                allValue:true,
                category:'',
                firstName:'',
                lastName:'',
                priority:''
            }
    },
  components:{
        Dropdownlist,
        VueCal,
        CalendrierFilter
  },
  computed:{
        getMemberList()
        {
        return this.$store.getters['contractor/getTeamMembers'];    
        },
        currentEvent()
        {
        return this.$store.getters['contractor/getAssigneeEvent'];        
        },
        allEvents()
        {
        return this.$store.getters['contractor/getOperationsEvents'];        
        },
        rangeAll(){
        var arrayRange=new Array();
        //
        for(var i=0;i<this.allEvents.length;i++)
        {
            if(this.allEvents[i].startDate && this.allEvents[i].endDate)
            {
            var objectTemp={
              start : this.getOnlyDate(this.allEvents[i].startDate)+' '+this.getHour(this.allEvents[i].startDate),
              end : this.getOnlyDate(this.allEvents[i].endDate)+' '+this.getHour(this.allEvents[i].endDate),
              title : this.allEvents[i].operation.reference ,
              content: this.allEvents[i].operation.title,
              id: this.allEvents[i].operation.id,
              draggable:false,
              resizable:false,
              editable:false,
              deletable:false}
            arrayRange.push(objectTemp);
            }
        }
        this.allValue=true
        return arrayRange  
        }
  },
  methods:{
        onEventClick (event, e) {
        let operationId=event.id
        this.$router.push('/dashboard/operations/'+event.id)
        e.stopPropagation()
        },
        getHumanDate : function (date) 
        {
        return moment(date).format('MM/DD/YYYY HH:mm');
        },
        getHour : function (date) 
        {
        return moment(date).format('HH:mm');
        },
        getOnlyDate : function (date) 
        {
        return moment(date).format('MM/DD/YYYY');
        }
  },
  created(){
    EventBus.$on('ADD_FILTER_CALENDAR', (options) => {
      this.priority=options.priority
      this.category=options.category
      this.firstName=options.firstName
      this.lastName=options.lastName
    })
    
  }
}
</script>
<style scoped>
.vuecal--green-theme{
  margin-top: 20px
}
/*.icon{
  color: rgba(0,0,0,.1);
  visibility: visible;
  margin-left: 70px;
}
.dropdownsearch{
  float: left;
}*/
</style>
<style lang="postcss" scoped>
/deep/ .vuecal__cell-events .vuecal__event{
  background-color: #c6d4ee !important;
  color: black;
  border-radius: 6px;
  font-size: 12px;
  padding: 5px;
  border: 2px solid white;
}
/deep/ .vuecal__cell-events .vuecal__event .vuecal__event-title
{
  font-weight: bold;
  color: #2d3748
}
/deep/ .vuecal__event-time {
  font-weight: bold;
  color: #645b37;
}
#vue-cal-desktop{
    display: block;
  }
  #vue-cal-mobile{
    display: none;
  } 
@media (max-width: 996px){
  #vue-cal-desktop{
    display: none;
  }
  #vue-cal-mobile{
    display: block;
  }
}
</style>

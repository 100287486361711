export const getters = {
  getOperations: function (state) {
    return state.operations.data
  },
  getOperationsWithMeta: function (state) {
    return state.operations
  },
  getFilteredOperations: function (state) {
    return state.filteredOperations
  },
  getOperationId: function (state) {
    return state.operationId
  },
  getOperationsCountByStatus: function (state) {
    return state.operationsCountByStatus
  },
  getNewOperation: function (state) {
    return state.newOperation
  },
  displayAddMessage: function (state) {
    return state.operationAdded
  },
  getCurrentOperation(state) {
    return state.currentOperation
  },
  assigned(state) {
    return state.assigned
  },
  getStatusCount(state) {
    return state.statusCounts
  },
  operationsLoaded: function (state) {
    return state.operationsLoaded
  },
  getfilteredOperations: function (state) {
    return state.filteredOperations
  },
  getOperationsFilter: function (state) {
    return state.operationsFilter
  },
  getfilteredMembers: function (state) {
    return state.filteredMembers
  },
  getTeamMembers: function (state) {
    return state.teamMembers
  },
  getTeamMembers2: function (state) {
    return state.teamMembers2
  },
  teamLoaded: function (state) {
    return state.teamLoaded
  },
  getClients: function (state) {
    return state.clients
  },
  getfilteredClients: function (state) {
    return state.filteredClients
  },
  getOpEvents: function (state) {
    return state.OperationEvents
  },
  getSelectedDate: function (state) {
    return state.selectedDate
  },
  getCurrentEvent: function (state) {
    return state.currentEvent
  },
  getAssigneeEvent: function (state) {
    return state.assigneeEvent
  },
  getOperationsEvents: function (state) {
    return state.allEvents
  },
  getNewEvent: function (state) {
    return state.newEvent
  },
  getMedia: function (state) {
    return state.media
  },
  getUploadStatus: function (state) {
    return state.eventUploaded
  },
  getConfigurationId: function (state) {
    return state.configurationId
  },
  getOrganizationId: function (state) {
    return state.organizationId
  },
  getPriorities: function (state) {
    return state.configuration.priorities
  },
  getCategories: function (state) {
    return state.configuration.categories
  },
  getStatuses: function (state) {
    return state.configuration.statuses
  },
  getElementPriority: function (state) {
    return state.ElementPriority
  },
  getElementStatus: function (state) {
    return state.ElementStatus
  },
  getElementCategory: function (state) {
    return state.ElementCategory
  },
  getNote: function (state) {
    return state.note
  },
  getLanding: function (state) {
    return state.landing
  },
  landingMsg: function (state) {
    return state.landingUpdated
  },
  getfilteredAssignedOperations: function (state) {
    return state.filteredassignedOperations
  },
  getCoords: function (state) {
    return state.mapCoords
  },
  getOperationsFilter: function (state) {
    return state.operationsFilter
  },
  getSubscriptionInfo: function (state) {
    let subscription = {}
    subscription.plan = state.organization.plan
    subscription.features = state.organization.features
    subscription.plan_expiry_date = state.organization.plan_expiry_date
    return subscription
  },
  getOperationsToUpdate: function (state) {
    return state.operationsViews.operationsToUpdate
  },
  getCustomers: function (state) {
    return state.customers
  },

  appLoaded: function (state) {
    return state.appLoaded
  },
  getLoader: function (state) {
    return state.loader
  },
  displayDemo: function (state) {
    return state.onboarding.displayDemo
  },
  hasStripeSubscription: function (state) {
    if (state.organization.stripe_customer_id !== null) {
      return true
    }
    else return false
  }
}

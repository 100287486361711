<template>
    <div class="Operations">
        <div class="headerconfig">
        <div class="row">
            <!--
            <toggle-switch :value="val" class="toggle col-lg-3" :options="myOptions" @change="updateMap($event.value)"/>
            -->
            <div class="col-lg-7 col-10 row blockSearch">                    
                <search-box></search-box>              
            </div>
            <div class="col-lg-5 col-2 buttons-desktop"> 
                <el-button type="primary" round v-on:click="navigateTo('/dashboard/operations/new')">Nouvelle Opération</el-button>                
                <el-button type="secondary" round v-on:click="downloadAllOperations">Exporter Tout </el-button>                
            </div>
            <div class="col-lg-4 col-2 buttons-mobile"> 
                <el-button type="primary" circle icon="el-icon-plus" v-on:click="navigateTo('/dashboard/operations/new')"></el-button>
            </div> 
        </div>
        </div> 
        <div class="row" v-if="val!='Map'">
            <operations-filter></operations-filter>
        </div> 
        <operations-list></operations-list>   
        <!--
        <list-header class="listOperations" titre1="RÉFÉRENCE" titre2="PRIORITÉ" titre3="STATUS" titre4="TITRE" titre5="CATÉGORIE" titre6="INTERVENANT" titre7="CRÉEE LE">
            <operations-list></operations-list>
        </list-header> 
        -->
    </div>   
</template>
<script>
import Kanban from '@/pages/Operations/Kanban.vue'
import ToggleSwitchVue from '@/pages/ToggleSwitch.vue';
import OperationsList from '@/pages/Operations/OperationsList'
import stats from '@/components/Operations/stats'
import mapView from '@/pages/Mapview.vue'
import OperationsFilter from './OperationsFilter.vue';
import EventBus from '@/eventBus'
import searchBox from '@/components/Operations/SearchBox.vue'
import ListHeader from '../../components/Global/listHeader.vue';

    export default {
    data: function() {
            return {
                status:[],
                category:'',
                firstName:'',
                lastName:'',
                search:'',     
                val:"Liste" ,
                myOptions: {
                layout: {
                    color: 'black',
                    backgroundColor: '#f1F3F5',
                    selectedColor: 'white',
                    selectedBackgroundColor: '#5fd5b7',
                    borderColor: 'white smoke',
                    fontFamily: 'Nunito, sans-serif',
                    fontWeight: 'normal',
                    fontWeightSelected: 'normal',
                    squareCorners: false,
                    noBorder: false
                },
                size: {
                    fontSize: 0.9,
                    height: 2,
                    padding: 0.2,
                    width: 10
                },
                items: {
                    delay: .4,
                    preSelected: 'unknown',
                    disabled: false,
                    labels: [
                    {name: 'Liste', color: 'white', backgroundColor: '#5fd5b7'}, 
                    //{name: 'Kanban', color: 'white', backgroundColor: '#5fd5b7'},
                    //{name: 'Map', color: 'white', backgroundColor: '#5fd5b7'}
                    ]
                }
        },
}
    },
    components:{
        Kanban,
        OperationsList,
        stats,
        mapView,
        OperationsFilter,
        searchBox,
        ListHeader,
    },
    computed:{
        operations(){
        return this.$store.getters['contractor/getOperations'];
        }, 
        /*
        dynamicComponent() {   
        if(this.val==='Liste') {         
        return List;       
        } 
        else if(this.val==="Kanban"){         
        return Kanban;       
        }  
        else{
        return mapView 
        }  
        },     
        */
        filterOperations() { 
            return this.operations;  
        /* 
        --- to be used if we want to add filtered search
        if(this.search==='')
        {
            return this.operations;
        }
        else{
        return this.operations.filter( (operation) => {
            if(operation.title && operation.priority && operation.status && operation.category && operation.assignee && operation.reference){
            return operation.title.toLowerCase().includes(this.search.toLowerCase()) || operation.priority.toLowerCase().includes(this.search.toLowerCase()) || operation.status.toLowerCase().includes(this.search.toLowerCase()) || operation.category.toLowerCase().includes(this.search.toLowerCase()) || operation.reference.includes(this.search) || operation.assignee.firstName.toLowerCase().includes(this.search) || operation.assignee.lastName.toLowerCase().includes(this.search);
            }
            if(operation.title && operation.priority && operation.status && operation.category && !operation.assignee)
            {
            return operation.title.toLowerCase().includes(this.search.toLowerCase()) || operation.priority.toLowerCase().includes(this.search.toLowerCase()) || operation.status.toLowerCase().includes(this.search.toLowerCase()) || operation.category.toLowerCase().includes(this.search.toLowerCase());
            }   
        });
        }
        */
        },
        filterList(){
            this.$store.dispatch('contractor/loadfilteredOperations',this.filterOperations);     
        }
    },
    methods:{
    updateMap(value){
        this.val=value;
    },
    navigateTo (nav) {
        this.$router.push(nav)
    },
    async downloadAllOperations () {
        await this.$store.dispatch('contractor/downloadAllOperations')
    },
    async searchOp(){
        let options = {
            limit: 10,
            start: 0,
            status:this.status,
            category:this.category,
            firstName:this.firstName,
            lastName:this.lastName,
            search:this.search
        }   
        await this.$store.dispatch('contractor/loadOperations',options);
    }
    },  
    created(){  
        let statusID = parseInt(this.$route.query.status)
        if(statusID>=0){
            let statuses = this.$store.getters['contractor/getStatuses']; 
            let requestedStatus = statuses.find(x => {
                return x.id === statusID
            })
            let filter= {}
            filter.assignee={}
            filter.category={}
            filter.statuses=[]
            filter.statuses.push(requestedStatus)
            filter.department=''
            this.$store.dispatch('contractor/updateOperationsFilter',filter);
            this.loading=false

        }   
        else {
            let filter= {}
            filter.assignee={}
            filter.category={}
            filter.statuses=[]
            filter.department=''
            this.$store.dispatch('contractor/updateOperationsFilter',filter);

        }
      //  this.$store.dispatch('contractor/loadfilteredOperations',this.filterOperations);   
      /*
        EventBus.$on('ADD_FILTER', (options) => {
            this.status=options.status
            this.category=options.category
            this.firstName=options.firstName
            this.lastName=options.lastName
        })
        */
      /*  let options = {
            limit: 10,
            start: 0,
            status:this.statusFilter,
            category:'',
        }   
        this.$store.dispatch('contractor/loadOperations',options);*/
        
        
    },
}
</script>
<style>
.searchInput{
    height: 20px;
    margin-right: 10px;
}
#searchI{
    border-radius: 16px !important;
}
.blockSearch{
    align-content: center !important;
}
.row{
width: 100%;
}
.headerconfig{
margin-bottom : 20px
}
.buttons-desktop {
    display : flex;
    flex-direction: row-reverse;
    align-items: center;
}
.buttons-mobile{
    display: none;
}
@media screen and (max-width: 999px){
.toggle{
    display: none;
}
.headconfig{
    position: relative;
}
.listOperations{
margin-top: 30px;
}
.buttons-desktop{
    display: none;
}
.buttons-mobile {
    display: block;
}
}
@media screen and (min-width: 999px){
.headconfig{
position: absolute;  
padding-right: 40px;
}
.blockSearch{
    left: 0 !important;
}
}   
</style>
    
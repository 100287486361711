<template>
    <div>
            <!--Stats cards-->
    <div class="row">
     <div class="col-12">
        <card class="coming-soon-widget">
          <h5>Coming Soon !</h5>
          <p>Bientôt, vous pourrez piloter votre activité en toute efficacité grâce au cockpit</p>
        </card>
      </div>
    
  </div>
    <div class="row opaque">
      <div class="col-md-6 col-xl-3" id="mystats" v-for="stats in statsCards" :key="stats.title">
        <stats-card>
          <div class="icon-big text-center" :class="`icon-${stats.type}`" slot="header">
            <i :class="stats.icon"></i>
          </div>
          <div class="numbers" slot="content">
            <p>{{stats.title}}</p>
            {{stats.value}}
          </div>
          <div class="stats" slot="footer">
            <i :class="stats.footerIcon"></i> {{stats.footerText}}
          </div>
        </stats-card>
      </div>
    </div>

  
    <!--
  <div class="row">
     <div class="col-12">
        <coming-soon></coming-soon>
      </div>
    
  </div>
  -->
    <!--Recent Activity-->
    <div class="row opaque">

      <div class="col-6">
        <chart-card chartType="Line" :chartData="chartData"
        title="Demandes d'interventions"
        subTitle="Evolution des demandes sur les 6 derniers mois"
        ></chart-card>
      </div>
      <div class="col-6">
        <chart-card chartType="Pie" :chartData="chartDataPie"
        title="Répartition par client"
        subTitle="Répartition des demandes par client sur les 6 derniers mois"
        ></chart-card>
      </div>

    </div>
    </div>
</template>
<script>
import ComingSoon from "@/components/Global/comingSoon.vue";
 import { StatsCard, ChartCard } from "@/components/index";
export default {
    components:{
        ComingSoon,
        StatsCard,
         ChartCard,
    },
    data (){
        return {
                 statsCards: [
        {
          type: "warning",
          icon: "ti-settings",
          title: "Interventions",
          value: "1200",
          footerText: "Updated now",
          footerIcon: "ti-reload"
        },
        {
          type: "success",
          icon: "ti-wallet",
          title: "Revenue",
          value: "56.000€",
          footerText: "Last day",
          footerIcon: "ti-calendar"
        },
        {
          type: "danger",
          icon: "ti-briefcase",
          title: "Clients",
          value: "35",
          footerText: "In the last hour",
          footerIcon: "ti-timer"
        },
        {
          type: "info",
          icon: "ti-agenda",
          title: "Contrats",
          value: "15",
          footerText: "Updated now",
          footerIcon: "ti-reload"
        }
      ],
      chartData: {
         labels: ['Jan','Fev','Mar','Avr','Mai','Juin','Juillet','Août'],
        series: [[20,40,30,35,60,55,70,75]]
      },
      chartDataPie: {
         labels: ['20%','50%','30%'],
        series: [20,50,30]
      },
        }
    }
}
</script>
<style scoped>
.opaque{
  opacity: 0.6;
}
</style>

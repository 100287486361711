<template>
  <div>
    <crud-table
      :data="categories"
      :columns="columns"
      :editFunction="editCategory"
      :deleteFunction="deleteCategory"
      :isRowEditable="isEditable"
      :isRowDeletable="isDeletable"
      :addFunction="addCategory"
      :addFormSchema="this.addFormSchema"
      :editFormSchema="this.editFormSchema"
      :showInfo="false"
    >
    </crud-table>
  </div>
</template>
<script>
import crudTable from "@/components/Global/crudTable";

export default {
  data() {
    return {
      columns: [
        {
          id: 1,
          prop: "label",
          label: "Libellé",
          width: "180",
          type: "text",
        },
        {
          id: 2,
          prop: "ranking",
          label: "Ordre",
          width: "180",
          type: "text",
        },
      ],
      addFormSchema: [
        {
          component: "h3",
          children: "Ajouter une catégorie",
        },
        {
          type: "text",
          name: "label",
          label: "Libellé",
          validation: "required",
        },
        {
          type: "number",
          name: "ranking",
          label: "Ordre",
          validation: "required",
        },
        {
          type: "submit",
          label: "Ajouter",
        },
      ],
      editFormSchema: [
        {
          component: "h3",
          children: "Modifier une catégorie",
        },
        {
          type: "text",
          name: "label",
          label: "Libellé",
          validation: "required",
        },
        {
          type: "number",
          name: "ranking",
          label: "Ordre",
          validation: "required",
        },
        {
          type: "submit",
          label: "Modifier",
        },
      ],
    };
  },
  components: {
    crudTable,
  },
  computed: {
    categories() {
      return this.$store.getters["contractor/getCategories"];
    },
  },
  methods: {
    async editCategory(category) {
      await this.$store.dispatch("contractor/editCategory", category);
      this.$store.dispatch("contractor/loadOrganization", category);
    },
    async deleteCategory(category) {
      await this.$store.dispatch("contractor/deleteCategory", category);
      this.$store.dispatch("contractor/loadOrganization", category);
    },
    async addCategory(category) {
      let label = category.label;
      category.name = label.replace(/\W+/g, "_").toLowerCase();
      await this.$store.dispatch("contractor/addCategory", category);
      this.$store.dispatch("contractor/loadOrganization", category);
    },
    isEditable(item) {
      return true;
    },
    isDeletable(item) {
      return true;
    },
  },
};
</script>

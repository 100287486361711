<template>
  <div class="dropdown" v-if="options">
    <!-- Dropdown Input -->
    <div class="mydropdown position-relative">
      <input
        class="dropdown-input"
        v-bind:style="applyBackgroundColor(selected.color)"
        :name="name"
        @focus="showOptions()"
        @blur="exit()"
        @keyup="keyMonitor"
        v-model="selected.label"
        :disabled="disabled"
        :placeholder="placeholder"
        autocomplete="off"
      />
      <slot></slot>
    </div>

    <!-- Dropdown Menu -->
    <div class="dropdown-content" v-show="optionsShown">
      <div
        class="dropdown-item"
        @mousedown="selectOption(option)"
        v-for="(option, index) in options"
        :key="index"
      >
        {{ option.label }}
      </div>
    </div>
  </div>
</template>

<script>
import configColor from "@/api/config.js";

export default {
  name: "Dropdownlist",
  props: {
    name: {
      type: String,
      required: false,
      default: "dropdown",
      note: "Input name",
    },
    options: {
      type: Array,
      required: true,
      default: function () {
        return [];
      },
      note: "Options of dropdown. An array of options with id and name",
    },
    placeholder: {
      type: String,
      required: false,
      default: "Please select an option",
      note: "Placeholder of dropdown",
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
      note: "Disable the dropdown",
    },
    maxItem: {
      type: Number,
      required: false,
      default: 6,
      note: "Max items showing",
    },
    searchFilter: {
      type: Object,
    },
  },
  data() {
    return {
      selected: {},
      optionsShown: false,
    };
  },
  created() {
    //this.$emit('selected', this.selected);
    this.selected = this.searchFilter;
  },
  computed: {
    filteredOptions() {
      const filtered = [];
      const regOption = new RegExp(this.searchFilter, "ig");
      for (const option of this.options) {
        if (this.searchFilter.length < 1 || option.label.match(regOption)) {
          if (filtered.length < this.maxItem) filtered.push(option);
        }
      }
      return filtered;
    },
  },
  methods: {
    applyBackgroundColor(color) {
      return {
        backgroundColor: color,
        color: "white",
      };
    },
    selectOption(option) {
      this.selected = option;
      this.optionsShown = false;
      //this.searchFilter = this.selected.label;
      this.$emit("selected", this.selected);
    },
    showOptions() {
      if (!this.disabled) {
        //this.searchFilter = '';
        this.optionsShown = true;
      }
    },
    exit() {
      if (!this.selected.id) {
        this.selected = {};
        //this.searchFilter = '';
      } else {
        //this.searchFilter = this.selected.label;
      }
      //this.$emit('selected', this.selected);
      this.optionsShown = false;
    },
    // Selecting when pressing Enter
    keyMonitor: function (event) {
      if (event.key === "Enter" && this.filteredOptions[0])
        this.selectOption(this.filteredOptions[0]);
    },
  },
  watch: {
    /*
    searchFilter() {
      if (this.filteredOptions.length === 0) {
        this.selected = {};
      } else {
        this.selected = this.filteredOptions[0];
      }
      this.$emit("filter", this.searchFilter);
    },
    */
  },
};
</script>
<style lang="scss" scoped>
.dropdown {
  text-align: center;
  display: block;
  &:hover i {
    visibility: visible;
  }
  .dropdown-input {
    background: #f1f3f4;
    color: black;
    cursor: pointer;
    border: 1px solid #e7ecf5;
    border-radius: 3px;
    display: block;
    font-size: 0.9em;
    width: 90%;
    padding: 6px;
    max-width: 250px;
    text-align: center;
    border-radius: 6px;
    opacity: 0.8;
    border: none;
    padding-right: 20px;
    padding-left: 20px;
    &:hover {
      opacity: 1;
    }
  }
  .dropdown-content {
    border: none;
    margin-top: 0px;
    position: absolute;
    background-color: #f1f3f4;
    width: 90%;
    max-height: 248px;
    border: 1px solid #e7ecf5;
    box-shadow: 0px -8px 34px 0px rgba(0, 0, 0, 0.05);
    overflow: auto;
    z-index: 1000;
    .dropdown-item {
      color: black;
      font-size: 0.9em;
      line-height: 1em;
      padding: 8px;
      text-decoration: none;
      display: block;
      cursor: pointer;
      &:hover {
        background-color: #e7ecf5;
      }
    }
  }
  .dropdown:hover .dropdowncontent {
    display: block;
  }
}
.mydropdown {
  position: relative;
}
i {
  position: absolute;
  font-family: FontAwesome;
  font-size: 0.9rem;
  font-style: normal;
  margin-top: -25px;
  padding: 2px;
  margin-right: 0px;
  margin-left: 45px;
  color: black;
  visibility: hidden;
  float: right;
}
@media screen and(max-width: 647px) {
  .dropdown-content,
  .dropdown-input {
    width: 100%;
    font-size: 13px;
  }
  .dropdown {
    text-align: center;
  }
}
</style>
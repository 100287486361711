<template>
    <div>
        <card>     
        <b-card-header>
            <h5>Mon organisation</h5>
        </b-card-header>
        <div class="col-12">
            <el-tabs content-class="mt-3" v-model="activeTab" @tab-click="navigateTo">
                <el-tab-pane label="Organisation" name="general" active >
                    
                </el-tab-pane>
            </el-tabs>
             <router-view></router-view>
        </div>
    </card>
    </div>
</template>
<script>
import config from '@/api/config.js'

export default {
name:'administration',

    data: function() {
            return {
                //activeTab:"categories"
            }
    },
    components:{
    },
    computed:{
        configuration(){
            return config
        },
        organization() {
            return this.$store.getters['contractor/getfilteredMembers'];
        },
         activeTab(){            
            return this.$router.currentRoute.path.substring(this.$router.currentRoute.path.lastIndexOf('/') + 1)
        }
    },
    methods:{
     navigateTo(tab, event) {
        //console.log(tab, event);
        console.log(tab, event)
        /* 
       if(tab.name == "priorities") {
           this.$router.push('/dashboard/settings/priorities')
       }
       if(tab.name == "categories") {
           this.$router.push('/dashboard/settings/categories')
       }
       if(tab.name == "statuses") {
           this.$router.push('/dashboard/settings/statuses')
       }
         if(tab.name == "table") {
           this.$router.push('/dashboard/settings/table')
       }
       */
      }
    },
    created(){
        
    },
    mounted(){
    }
    }
</script>
<style scoped>

</style>
import Vue from "vue";
import App from "./App";
import router from "./router/index";
import store from './store';
import AuthService from '@/auth/AuthService'

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import PaperDashboard from "./plugins/paperDashboard";
/*
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
*/
//import './style.css'
//import "vue-notifyjs/themes/default.css";
import 'tui-calendar/dist/tui-calendar.min.css'
import BootstrapVue from 'bootstrap-vue'
import vueKanban from 'vue-kanban'
import ToggleSwitch from 'vuejs-toggle-switch'
import VueObserveVisibility from 'vue-observe-visibility'
import ScrollLoader from 'vue-scroll-loader'
import VueEditor from "vue2-editor"
import Notify from 'vue-notifyjs'
//import VCalendar from 'v-calendar'
import VModal from 'vue-js-modal'
import VueCal from 'vue-cal'
import 'vue-cal/dist/vuecal.css'
import 'vue-event-calendar/dist/style.css'
import 'vue-cal/dist/i18n/zh-cn.js'
import {Button as PButton} from '@/components'
import moment from 'moment'
import Uploader from "vux-uploader-component";
import AvatarCropper from "vue-avatar-cropper"
import ElementUI from 'element-ui';
import '@/assets/css/element-ui/theme/element-ui.css';
//import Vuetify from 'vuetify'
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';
import L from "leaflet";
import { Icon } from 'leaflet';
import {Notification} from 'element-ui'
import VueFormulate from '@braid/vue-formulate'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';

import Hotjar from 'vue-hotjar'

Vue.use (Hotjar, {
  id: process.env.VUE_APP_HOTJAR_ID,
  isProduction: process.env.NODE_ENV === 'production'
})

Vue.use(VueFormulate)
//Vue.use(LMap);
//Vue.use(LTileLayer);
//Vue.use(LMarker);
//Vue.use(L)
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

//Vue.use(Vuetify)
Vue.use(AvatarCropper)
Vue.use(Uploader)
Vue.use(moment)
Vue.use(VueCal)
Vue.use(VModal)
/*
Vue.use(VCalendar, {
  // ...some defaults
  screens: {
    tablet: '576px',
    laptop: '992px',
    desktop: '1200px',
}});
*/
Vue.use(Notify)
Vue.use(VueEditor);
Vue.use(VueObserveVisibility)
Vue.use(ToggleSwitch)
Vue.use(BootstrapVue);
//Vue.use(vueKanban)
Vue.use(ScrollLoader)
Vue.component(PButton)
import locale from 'element-ui/lib/locale/lang/fr'
Vue.use(ElementUI,{ locale });
Vue.component('v-select', vSelect)

/*import auth from '@/auth/AuthService'
Vue.use(auth)*/
// Import the Auth0 configuration
import { domain, clientId } from "../auth_config.json";

// Import the plugin here
//import { Auth0Plugin } from "./auth";

import i18n from './i18n'
// Install the authentication plugin here

/*
Vue.use(Auth0Plugin, {
  domain,
  clientId,
  onRedirectCallback: appState => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  }
});
*/


library.add(fas)
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false
function checkExpiration()
{
  let exp=localStorage.getItem('expires_at');
  if(exp < Date.now())
  {
    AuthService.logout(); 
  }
}
checkExpiration();
Vue.use(PaperDashboard);
Vue.config.devtools = true
/* eslint-disable no-new */
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app");

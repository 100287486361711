<template>
<div class="loader">   
    <!--
      <pulse-loader :loading="true" color="#41B883"></pulse-loader>
      <moon-loader :loading="true" color="#41B883"></moon-loader>
      -->
      <clip-loader v-show="type === 'clip'" :loading="true" color="#41B883" size="80px"></clip-loader>
      <pulse-loader v-show="type === 'pulse'" :loading="true" color="#41B883" size="10px"></pulse-loader>



</div>
</template>
<script>

//Source for Spins https://github.com/greyby/vue-spinner 

import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import MoonLoader from 'vue-spinner/src/MoonLoader.vue'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'

export default {
  props:{
    type:{
      type:String,
      default:"clip"
    }
  },
  created () {

  },
  computed: {
  
  },
  components : {
    ScaleLoader,
    PulseLoader,
    MoonLoader,
    ClipLoader
  }
}
</script>
<style scoped>
  .loader{
    position: absolute;
    background-color: #f1f3f4;
    height: 100%;
    width: 100%;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    }
</style>


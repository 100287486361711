<template>
  <component :is="tag"
             @click.native="hideSidebar"
             class="nav-item"
             v-bind="$attrs"
             tag="li">
    <a class="nav-link">
          
      <slot>
        <div v-if="!unlocked" class="ti-lock upgrade"></div>
        <i v-else :class="icon"></i>
        <p>{{name}}</p>
        <div v-show="comingsoon" class="soon">soon</div>  
            
      </slot>
    </a>
  </component>
</template>
<script>
export default {
  name: "sidebar-link",
  inheritAttrs: false,
  inject: {
    autoClose: {
      default: true
    },
    addLink: {
      default: ()=>{}
    },
    removeLink: {
      default: ()=>{}
    }
  },
  props: {
    name: String,
    icon: String,
    comingsoon: Boolean,
    unlocked: Boolean,
    tag: {
      type: String,
      default: "router-link"
    }
  },
  methods: {
    hideSidebar() {
      if (this.autoClose) {
        this.$sidebar.displaySidebar(false);
      }
    },
    isActive() {
      return this.$el.classList.contains("active");
    }
  },
  mounted() {
    if (this.addLink) {
      this.addLink(this);
    }
  },
  beforeDestroy() {
    if (this.$el && this.$el.parentNode) {
      this.$el.parentNode.removeChild(this.$el)
    }
    if (this.removeLink) {
      this.removeLink(this);
    }
  }
};
</script>
<style>
.soon {
    margin-left: 10px;
    background-color: white;
    color: black;
    padding: 0px 10px;
    font-weight: 500;
    font-size: 10px;
    border-radius: 5px;
}
.upgrade {
    background-color: #eabe00;
    padding: 5px;
    border-radius: 50%;
    margin-left: 3px;
    color: white;
    margin-right: 15px;
}
@media (max-width: 991px){
.nav-item{
text-transform: lowercase;
}
}

</style>

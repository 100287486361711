<template>
  <div>
    <!-- Operations stats-->
    <div class="row">
      <!--
       <stats></stats>
       -->
      <stats-bis :statuses="operationsCountByStatus"></stats-bis>
    </div>

    <!--widget operations-->
    <div class="row">
      <div class="col-lg-4 col-md-6 col-sm-12">
        <!--
        <stats-card-2 
          title="Opérations à mettre à jour"
          kpi="12"
          color="orange"
        
        ></stats-card-2>
        -->
        <list-operations-widget
          :operations="operationsToUpdate.data"
          title="Opérations sans mis à jour depuis 24h"
          :kpi="operationsToUpdate.meta.pagination.total"
          :loading="widgetToUpdateLoading"
          emptyText="Bravo ! vous êtes à jour !"
          emptyIcon="ti-cup"
        ></list-operations-widget>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12"></div>
    </div>
  </div>
</template>
<script>
import List from "@/components/Global/listHeader";
import { StatsCard, ChartCard } from "@/components/index";
import Chartist from "chartist";
import stats from "@/components/Operations/stats";
import statsBis from "@/components/Operations/stats-bis";
import ComingSoon from "@/components/Global/comingSoon";
import listOperationsWidget from "@/components/Operations/listOperationsWidget";
import Calendrier from "../Calendrier/Calendrier.vue";
import statsCard2 from "@/components/Cards/StatsCard2";
export default {
  components: {
    StatsCard,
    ChartCard,
    stats,
    statsBis,
    List,
    ComingSoon,
    listOperationsWidget,
    statsCard2,
  },
  data() {
    return {
      widgetToUpdateLoading: false,
    };
  },
  computed: {
    operationsCountByStatus() {
      /*
        let count = this.$store.getters['contractor/getOperationsCountByStatus'];
        let statuses = this.$store.getters['contractor/getStatuses'];
        console.log(statuses)
        statuses.foreach(element => {
        if(count[element.name] === undefined){
          element.count = 0
        }
        else{
          element.count = count[element.name]
        }
      })
      */
      return this.$store.getters["contractor/getOperationsCountByStatus"];
    },
    operations() {
      return this.$store.getters["contractor/getOperations"];
    },
    operationsToUpdate() {
      return this.$store.getters["contractor/getOperationsToUpdate"];
    },
    /**
     * Chart data used to render stats, charts. Should be replaced with server data
     */
  },

  async created() {
    this.widgetToUpdateLoading = true;
    await this.$store.dispatch("contractor/loadOperationsCount");

    let options = {
      limit: 10,
      start: 0,
    };
    await this.$store.dispatch("contractor/getOperationsToUpdate");
    this.widgetToUpdateLoading = false;
    //this.$store.dispatch('contractor/initOperations')
  },
};
</script>
<style scoped>
.opaque {
  opacity: 0.4;
}

/** 
#mystats{
padding-left: 30px;
padding-right: 50px;
} 
*/
</style>

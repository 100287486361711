import { mutations } from './mutations'
import { actions } from './actions'
import { getters } from './getters'

export default {
  namespaced: true,
  state: {
    appLoaded: false,
    //Organization
    organization: {},
    organizationId: null,

    //Settings State
    configurationId: null,
    configuration: {},
    categories: [],
    priorities: [],
    statuses: [],

    //Landing Page 
    landingId: null,
    landing: {},
    landingUpdated: false,

    //Team 
    teamMembers: [],
    teamMembers2: [],
    filteredMembers: {},
    teamLoaded: false,

    //Clients
    clients: {},
    filteredClients: {},

    //Customers 
    customers: [],

    //Operations
    operationsFilter: {
      statuses: [],
      category: {},
      assignee: {},
      department: ''
    },
    operationsCountByStatus: [],

    operations: {
      data: [],
      meta: {
        pagination: {
          start: 0,
          limit: 50,
          total: 0
        }
      }
    },

    operationsViews: {
      operationsToUpdate: {
        data: [],
        meta: {
          pagination: {
            start: 0,
            limit: 20,
            total: 0
          }
        }

      }
    },

    loader: {
      operationsList: true
    },
    operationsLoaded: false,

    filteredOperations: {},
    operationId: null,
    newOperation: {
      contact: {},
      location: {},
      secondaryContact: {}
    },
    currentOperation: {},
    operationAdded: false,
    statusChanged: false,
    statusCounts: [],
    operationAssigned: false,
    newEvent: {},
    currentEvent: {},
    assigneeEvent: {},
    allEvents: {},
    ElementPriority: {},
    ElementStatus: {},
    ElementCategory: {},
    eventUploaded: false,
    media: [],
    notes: [],
    note: {},
    filteredassignedOperations: {},
    mapCoords: {},

    //onboarding
    onboarding: {
      displayDemo: false,
    }

  },
  actions,
  mutations,
  getters
}
<template>  
    <div class="">
        <div v-if="expired">
            Votre période d'essai a expiré. 
            <router-link :to="{path:'/upgrade'}" >Upgradez maintenant !</router-link>  
        </div>
         <div v-else>
            Votre période d'essai expire dans {{remainingDays}} jours. 
            <router-link :to="{path:'/upgrade'}" >Upgradez maintenant !</router-link>  
        </div>
      
    </div>
</template>
<script>

export default {
    props:{
        remainingDays: Number,
        expired: Boolean
    },
    computed:{

    },
    methods:{

    },
    created()
    {
    
    }
}
</script>
<style scoped>

</style>

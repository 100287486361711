<template>
  <div class="col-12">
    <card>
      <b-card-header>
        <h5>Ajouter une nouvelle opération</h5>
        <h6 class="obligatoireTitle">
          <span class="obligatoire">*</span> champs obligatoires
        </h6>
      </b-card-header>
      <form @submit.prevent="add()">
        <div class="row">
          <div class="col-md-12">
            <label>Titre </label><span class="obligatoire"> *</span>
            <input
              type="text"
              placeholder="Titre"
              v-model="newOperation.title"
              name="titre"
              class="form-control"
              required
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <label>Desciption</label><span class="obligatoire"> *</span>
            <textarea
              type="text"
              placeholder="Description"
              v-model="newOperation.description"
              name="desciption"
              class="form-control"
              rows="3"
              required
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <label>Catégorie </label><span class="obligatoire"> *</span>
            <select
              class="form-control custom-select"
              v-model="newOperation.category"
              required
            >
              <option
                v-for="c in categories"
                :key="c.id"
                :value="c.id"
                selected
              >
                {{ c.label }}
              </option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <label>Priorité </label><span class="obligatoire"> *</span>
            <select
              class="form-control custom-select"
              v-model="newOperation.priority"
              required
            >
              <option
                v-for="priorité in priorities"
                :key="priorité.id"
                :value="priorité.id"
                selected
              >
                {{ priorité.label }}
              </option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <h5 class="form-title">Client</h5>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <select
              class="form-control custom-select"
              v-model="newOperation.customer"
              required
            >
              <option
                v-for="customer in customers"
                :key="customer.id"
                :value="customer.id"
                selected
              >
                {{ customer.organism }}
              </option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <h5 class="form-title">Contact principal</h5>
          </div>
        </div>
        <div class="row" v-if="newOperation.contact">
          <div class="col-md-4">
            <label>Nom du contact </label><span class="obligatoire"> *</span>
            <input
              type="text"
              placeholder=""
              v-model="newOperation.contact.name"
              name="name"
              class="form-control"
            />
          </div>
          <div class="col-md-4">
            <label>Téléphone</label><span class="obligatoire"> *</span>
            <input
              type="tel"
              placeholder=""
              v-model="newOperation.contact.phoneNumber"
              name="phoneNumber"
              class="form-control"
            />
          </div>
          <div class="col-md-4">
            <label>Email </label><span class="obligatoire"> *</span>
            <input
              type="email"
              placeholder=""
              v-model="newOperation.contact.email"
              name="email"
              class="form-control"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <h5 class="form-title">Contact secondaire</h5>
          </div>
        </div>
        <div class="row" v-if="newOperation.secondaryContact">
          <div class="col-md-4">
            <label>Nom du contact</label>
            <input
              type="text"
              placeholder=""
              v-model="newOperation.secondaryContact.name"
              name="name"
              class="form-control"
            />
          </div>
          <div class="col-md-4">
            <label>Téléphone</label>
            <input
              type="tel"
              placeholder=""
              v-model="newOperation.secondaryContact.phoneNumber"
              name="phoneNumber"
              @input="acceptNumber"
              class="form-control"
            />
          </div>
          <div class="col-md-4">
            <label>Email</label>
            <input
              type="email"
              placeholder=""
              v-model="newOperation.secondaryContact.email"
              name="email"
              class="form-control"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <h5 class="form-title">Adresse</h5>
          </div>
        </div>
        <div class="row" v-if="newOperation.location">
          <div class="col-md-12">
            <label>Adresse</label><span class="obligatoire"> *</span>
            <input
              type="text"
              placeholder=""
              v-model="newOperation.location.address"
              name="address"
              class="form-control"
              required
            />
          </div>
        </div>
        <div class="row" v-if="newOperation.location">
          <div class="col-md-4 postal-code">
            <label>Code Postal<span class="obligatoire">*</span></label>
            <el-select
              v-cancel-read-only
              v-model="selectedCity"
              value-key="Code_commune_INSEE"
              filterable
              remote
              reserve-keyword
              placeholder="Code Postal"
              :remote-method="searchCity"
              :loading="citiesLoading"
              @change="setCity"
            >
              <el-option
                v-for="item in cityOptions"
                :key="item.Code_commune_INSEE"
                :label="item.Code_postal + ' - ' + item.Nom_commune"
                :value="item"
              >
              </el-option>
            </el-select>
          </div>
          <div class="col-md-4">
            <label>Ville</label><span class="obligatoire">*</span>
            <input
              type="text"
              placeholder=""
              v-model="newOperation.location.city"
              name="country"
              class="form-control"
              required
              disabled
            />
          </div>
          <!--
                    <div class="col-md-4">
                        <label>Code postal</label><span class="obligatoire"> *</span>
                        <input type="text"
                        placeholder=""
                        v-model="newOperation.location.zipCode"
                        name="zipCode"
                        class="form-control" required/>
                    </div> 
                    -->
          <div class="col-md-4">
            <label>Pays</label><span class="obligatoire">*</span>
            <input
              type="text"
              placeholder=""
              v-model="newOperation.location.country"
              name="country"
              class="form-control"
              required
              disabled
            />
          </div>
        </div>
        <div class="text-center">
          <p-button type="primary" :round="true" :loading="submitted"
            >Ajouter</p-button
          >
          <!--
                    <button type="submit" class="btn btn-round btn-primary">Update</button>
                    -->
          <p v-if="displayAddMessage" class="update-msg">
            Votre opération a été ajouté avec succès
          </p>
        </div>
      </form>
    </card>
  </div>
</template>
<script>
import configuration from "@/api/config.js";
import utils from "@/utils/index.js";

export default {
  data: function () {
    return {
      submitted: false,
      citiesLoading: false,
      cityOptions: [],
      selectedCity: {},
      newOperation: {
        contact: {},
        customer: null,
        secondaryContact: {},
        location: {
          city: "",
          zipCode: "",
          country: "France",
        },
      },
      statusFilter: [
        "Nouveau",
        "Prête",
        "Préparation",
        "En cours",
        "Planifiée",
      ],
      departments: [],
      reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
    };
  },
  methods: {
    initOperationsState() {
      this.$store.dispatch("contractor/initOperations");
    },
    async add() {
      console.log(this.newOperation);
      if (
        !this.reg.test(this.newOperation.contact.email) &&
        !this.reg.test(this.newOperation.secondaryContact.email)
      ) {
      }
      this.submitted = true;
      await this.$store.dispatch(
        "contractor/createOperation",
        this.newOperation
      );
      this.submitted = false;
      this.initOperationsState();
      this.$router.push("/dashboard/operations");
      this.$store.dispatch("contractor/loadOperationsCount");
    },
    acceptNumber() {
      var x = this.newOperation.contact.phoneNumber
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      var y = this.newOperation.secondaryContact.phoneNumber
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.newOperation.contact.phoneNumber = !x[2]
        ? x[1]
        : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
      this.newOperation.secondaryContact.phoneNumber = !y[2]
        ? y[1]
        : "(" + y[1] + ") " + y[2] + (y[3] ? "-" + y[3] : "");
    },
    searchCity(query) {
      if (query !== "") {
        this.cityOptions = utils.searchCitiesByZipCode(query);
      } else this.cityOptions = [];
    },
    setCityName(zipCode) {
      let city = utils.getCityFromZipCode(zipCode);
      this.newOperation.location.city = city.Nom_commune;
    },
    setCity(city) {
      this.newOperation.location.city = city.Nom_commune;
      this.newOperation.location.zipCode = city.Code_postal;
    },
  },
  computed: {
    /*
        newOperation: function(){
            return this.$store.getters['contractor/getNewOperation']
        },
        */
    displayAddMessage: function () {
      return this.$store.getters["contractor/displayAddMessage"];
    },
    config: function () {
      return configuration;
    },
    statuses() {
      return this.$store.getters["contractor/getStatuses"];
    },
    categories() {
      return this.$store.getters["contractor/getCategories"];
    },
    priorities() {
      return this.$store.getters["contractor/getPriorities"];
    },
    /*
    cities() {
      return utils.getCities(this.newOperation.location.department);
    },
    */
    customers() {
      return this.$store.getters["contractor/getCustomers"];
    },
  },
  async created() {
    //utils.detectEntryWithoutZipCode();
    //console.log(utils.getFranceCities())
    //this.cities = utils.getFranceCities()
    //this.departments = utils.getDepartments();
  },
  /**
   * This directive has been added to fix the issue on iOS where the el-select is not editable
   * (the keyboard doesn't show up)
   */
  directives: {
    cancelReadOnly(el) {
      const input = el.querySelector(".el-input__inner");
      input.removeAttribute("readonly");
    },
  },
};
</script>
<style scoped>
.obligatoireTitle {
  float: right;
}
.obligatoire {
  color: red;
}
.form-title {
  margin-top: 10px;
  margin-bottom: 10px;
}
.update-msg {
  font-size: 12px;
  color: green;
  margin-top: 15px;
}
.custom-select:focus {
  box-shadow: 0px 0px 2px #2d3748;
  box-shadow: 0 0 0 3px -moz-mac-focusring;
  color: #222;
  outline: none;
}
.custom-select option {
  color: white;
  background-color: #2d3748;
}
/* Style the arrow inside the select element: */
.select-selected:after {
  position: absolute;
  content: "";
  top: 14px;
  right: 10px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-color: #fff transparent transparent transparent;
}

/* Point the arrow upwards when the select box is open (active): */
.select-selected.select-arrow-active:after {
  border-color: transparent transparent #fff transparent;
  top: 7px;
}

.container {
  padding: 30px;
  background-color: #f1f3f4;
  padding-bottom: 500px;
  align-content: center;
}
img {
  margin-top: -120px;
  margin-bottom: 20px;
  padding: 10px;
}
.form {
  text-align: center;
  padding: 10px;
  margin: auto;
}
.col-md-12 {
  padding-bottom: 15px;
}
input,
textarea {
  background-color: #f1f3f4;
  outline: none;
}
input:focus,
textarea:focus {
  outline: none !important;
  box-shadow: 0px 0px 2px #2d3748;
}
.form-control:focus {
  outline: none !important;
  background-color: #f1f3f4;
}
.form-control {
  background-color: #f1f3f4;
  border: none;
}
label {
  padding-left: 12px;
  float: left;
}
.postal-code {
  display: flex;
  flex-direction: column;
}
</style>

<template>
  <div class="wrapper container-fluid">
    <h1>Profitez de l'offre de lancement Optimop</h1>
    <div class="pricing-table">
      <div class="row">
        <div class="">
          <card class="plan">
            <h5>Offre de lancement</h5>
            <div class="price"><span>10€</span> / mois</div>
            <ul class="features-list">
              <li>
                Accès à toutes les fonctionnalités d'optimop la première année
              </li>
              <li>Gestion d'équipe</li>
              <li>Calendrier</li>
              <li>Gestion des clients</li>
              <li>Portail client</li>
              <li>Gestion de la configuration</li>
              <li>Limité à 5 membres d'équipe</li>
            </ul>
            <div class="btn-container">
              <!--
              <b-button
                href="https://buy.stripe.com/test_3csbJy4TM0Dz6L6fYZ"
                class="btn-primary"
                >S'abonner</b-button
              >
              -->
              <el-button
                type="primary"
                :loading="loading"
                @click="redirectToStripeCheckout('starter')"
                >S'abonner</el-button
              >
            </div>
          </card>
          <p>
            Pour tout besoin supplémentaire merci de nous contacter sur
            <a href="mailto:sales@optimop.co">sales@optimop.co</a>
          </p>
        </div>
        <!--
        <div class="col-lg-4 col-md-4 col-12">
          <card class="plan">
            <h5>Premium</h5>
            <div class="price-custom"><span>Arrive bientôt</span></div>
            <ul class="features-list">
              <li>Gestion des opérations</li>
              <li>Gestion d'équipe</li>
              <li>Calendrier</li>
              <li>Gestion des clients</li>
              <li>Portail client</li>
              <li>Gestion de la configuration</li>
              <li>Limité à 10 membres d'équipe</li>
            </ul>
            <div class="btn-container">
              <b-button
                href="https://buy.stripe.com/test_3cs14U4TMfyt9Xi7su"
                class="btn-primary"
                >S'abonner</b-button
              >
            </div>
          </card>
        </div>
        <div class="col-lg-4 col-md-4 col-12">
          <card class="plan">
            <h5>Entreprise</h5>
            <div class="price-custom"><span>Arrive bientôt</span></div>
            <ul class="features-list">
              <li>Gestion des opérations</li>
              <li>Gestion d'équipe</li>
              <li>Calendrier</li>
              <li>Gestion des clients</li>
              <li>Portail client</li>
              <li>Configuration</li>
              <li></li>
            </ul>
            <div class="btn-container">
              <b-button class="btn-primary">Nous contacter</b-button>
            </div>
          </card>
        </div>
        -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      //activeTab:"categories"
      loading: false,
    };
  },
  methods: {
    async redirectToStripeCheckout(planName) {
      switch (planName) {
        case "starter":
          try {
            this.loading = true;
            let payload = {
              priceId: process.env.VUE_APP_STARTER_PLAN_STRIPE_PRICE_ID,
              planId: process.env.VUE_APP_STARTER_PLAN_ID,
            };
            let url = await this.$store.dispatch(
              "contractor/initCheckoutSession",
              payload
            );
            this.loading = false;
            window.location.replace(url);
          } catch (e) {
            this.loading = false;
            console.log(e);
          }
          break;
      }
    },
  },
};
</script>
<style scoped>
.pricing-table {
  width: 80%;
}
.pricing-table .row {
  justify-content: center;
}
@media screen and (min-width: 1024px) {
  .wrapper {
  }
}
.wrapper {
  position: absolute;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  background-color: #f1f3f4;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.plan {
}

.plan h4 {
  text-align: center;
}
.plan h5 {
  text-align: center;
}
.price,
.price-custom {
  text-align: center;
  margin-bottom: 30px;
  height: 70px;
}
.price span {
  font-size: 40px;
  font-weight: bold;
}
.price-custom span {
  font-size: 20px;
  font-weight: bold;
}
.btn-container {
  text-align: center;
}
</style>

<template>
        <div>
        <div class="row">
            <div class="col-12">
                <fg-input type="text"
                        placeholder="Search"
                        v-model="search"
                        class="searchInput"
                        v-on="filterList"
                        >
                </fg-input>
            </div>
        </div>   
        <div class="row">
            <div class="col-12 filter-card">
            <el-button v-if="!filterDisplayed" round v-on:click="displayFilter()">Filtrer</el-button>
            <card v-if="filterDisplayed" class="filter">
                <div class="row">
                    <div class="col-md-3 col-12">
                        <el-select v-model="statusFilter" placeholder="Statut" >
                            <el-option :value="empty">Tous</el-option>
                            <el-option
                            v-for="item in statuses"
                            :key="item.name"
                            :label="item.name"
                            :value="item.name"
                            >
                            </el-option>
                        </el-select>
                    </div>
                    <div class="col-md-1 col-6">
                        <el-button circle icon="el-icon-close" v-on:click="hideFilter()"></el-button>
                    </div>
                </div>
            </card>
            </div>  
        </div>
        <div class="row headers">
            <div class="col-2 operationId"><p>RÉFÉRENCE</p></div><div class="col-1 priorité"><p>PRIORITÉ</p></div><div class="col-2"><p>STATUS</p></div><div class="col-3 titreDesktop"><p>TITRE</p></div><div class="col-2 catDesktop"><p>CATÉGORIE</p></div><div class="col-2 createdat"><p>CRÉEE LE</p></div>
        </div>
        <div v-for="operation in filterAssigneeList" :key="operation.id">
            <card class="card desktop-style" >
                <div class="mycard" v-on:click="goOperationDetails(operation.id)" >
                    <div class="row head">
                        <span class="col-lg-2 col-md-4 col-sm-4 col-4 operationId"><font color="#5fd5b7">No </font> {{operation.reference}}</span> <p class="priorité col-lg-1 col-md-4 col-sm-4 col-3" v-bind:style="priorityColor(operation.priority)">{{operation.priority}}</p> <div class="status col-lg-2 col-md-4 col-sm-4 col-5"><span class="h5" v-bind:style="statusBgColor(operation.status)"> <font color="#ffffff"> {{operation.status}} </font>  </span></div>
                        <p class="titreDesktop col-lg-3">{{titleSplit(operation.title)}}</p> <div class="catDesktop col-lg-2"> <p class="cat"> {{operation.category}} </p> </div>
                        <div class="createdat col-lg-2"> <p id="datecreation">  {{operation.created_at.substr(0,10)}}  </p> </div>
                    </div>
                    <hr/>
                    <div class="row">
                           <div class="row">
                            <p class="titre">{{titleSplit(operation.title)}}</p>
                            </div>
                            <div class="row">
                                <div class="col-md-6 col-sm-6 col-5"> <p class="category"> {{operation.category}} </p> </div>
                            </div>
                    </div>
                </div>
            </card>
            <card class="card mobile-style" >
                <div class="mycard" v-on:click="goOperationDetails(operation.id)" >
                    <div class="row head">
                        <span class="col-6 operationId"><font color="#5fd5b7">No </font> {{operation.reference}}</span> 
                        <div class="status col-6"><span class="h5" v-bind:style="statusBgColor(operation.status)"> <font color="#ffffff"> {{operation.status}} </font>  </span></div>
                    </div>
                    <div class="row">
                        <p class="col-12 titre-mobile">{{titleSplit(operation.title)}}</p>                                <!--
                                <div class="col-md-6 col-sm-6 col-5"> <p class="category"> {{operation.category}} </p> </div>
                                -->
                        <div class="col-7 creation-date-mobile"><p>Créé le {{operation.created_at.substr(0,10)}} </p></div>
                    </div>
                </div>

            </card>
        </div>
    </div>
</template>
<script>
import configColor from '@/api/config.js'
import AuthService from '@/auth/AuthService.js'
import EventBus from '@/eventBus'
import OperationsFilter from './OperationsFilter.vue';

export default {
    data: function() {
            return {
            search:'',
            statusFilter: '',
            filterDisplayed: false,
            empty:''
            }
    },
    components:{
    OperationsFilter
    },
    computed:{  
        profile() {
        return this.$store.getters['auth/getProfile'];
        },
        filterOperations() {    
        if(this.search==='')
        {
            return this.profile.assignedOperations;
        }
        else{
        return this.profile.assignedOperations.filter( (operation) => {
            if(operation.title && operation.priority && operation.status && operation.category)
            {
            return operation.title.toLowerCase().includes(this.search.toLowerCase()) || operation.priority.toLowerCase().includes(this.search.toLowerCase()) || operation.status.toLowerCase().includes(this.search.toLowerCase()) || operation.category.toLowerCase().includes(this.search.toLowerCase());
            }   
        });
        }
        },
        filterList(){
        this.$store.dispatch('contractor/loadfilteredAssignedOperations',this.filterOperations);     
        },
        statuses(){
            return configColor.Status
        },
        filterAssigneeList(){
        if(this.statusFilter =='')
        {
            return this.filterOperations
        }
        else{
        return this.filterOperations.filter( (operation) => {
            
            if(operation.status)
            {
                return  operation.status.toLowerCase()==this.statusFilter.toLowerCase()   
            }   
        });
        }
        }
        
    },
    methods:{
        goOperationDetails(id){
            this.$store.dispatch('contractor/setOperationId',id);
            this.$router.push('/dashboard/operations/'+id);
        },
        statusBgColor(status)
        {
            for(var i=0;i<configColor.Status.length;i++){
                if(configColor.Status[i].name==status)
                {
                    return { 
                    'background-color':configColor.Status[i].color,
                    'border-color':configColor.Status[i].color,
                    'color':'white'
                    };
                }
            }            
        },
        priorityColor(priority)
        {
        for(var i=0;i<configColor.Priorities.length;i++){
            if(configColor.Priorities[i].name==priority)
            {
                return { 
                'color':configColor.Priorities[i].color,
                };
            }
        }
        },
        titleSplit(title){
            var stringTitle='';
            if(title && title.length>30)
            {
                for(var i=0;i<5;i++){
                    stringTitle=stringTitle+' '.concat(title.split(" ")[i])
                }
                return stringTitle
            }
            else return title
            
            console.log(title.length)
            
        },
        hideFilter(){
            this.filterDisplayed=false;
        },
        displayFilter(){
            this.filterDisplayed=true
        },
    }
}
</script>
<style scoped>
.filter-card{
    margin-top: 20px;
}
.desktop-style{
    display: block;
    height: 60px;
}
.mobile-style {
    display: none;
    box-shadow: 0 2px 2px rgb(204 201 220 / 49%);
    height: 120px;
}

.titre-mobile {
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 10px;
}
.creation-date-mobile p{
    font-size: 12px;
    color: gray;
}
.mobile-style hr {
    border-top:1px solid #f1f3f4;
    margin : 10px 0px;
}
.createdat{
    padding-right: 0px;
    color: gray;
}
#datecreation{
    font-size: 12px !important;
}
.searchInput{
    padding:auto;
    padding-left: 20px;
    width: 60%;
    margin: 0px;
    margin-bottom: 20px;
    border-radius: 10px;
    border: none;
    float: left
}
.headers{
    margin-top: 25px;
    padding-right: 10px;
    font-size: 14px;
    color: rgba(88, 82, 82, 0.745);
}
.desktop-style:hover{
    background-color: #8f919417;
    cursor: pointer;
}
.desktop-style,.mobile-style{
  margin-top:10px;
  border: none;
  font-size: 14px;
  border-radius: 10px;
}
p{
    margin-bottom: 0px;
  font-size:14px; 
}
.status{
    float: left;
    font-weight: 700;
    line-height: 99%;
    text-transform: uppercase;
}
.h5{
    font-size: 12px;
    font-weight: 700;
    border-radius: 5px;
    padding:2px 10px;
}
.priorité{
    margin: auto;
    margin-right: 0px;
    margin-left: 0px;
    padding: 0px;
}
.row{
    padding-left: 5px;
}
.catDesktop{
    padding: 0px;
    padding-left: 10px;
    margin: auto;
}
.cat,.category{
    color: #eebb4d;
    padding: 0px;
    font-size: 13px;
}
.titre{
    padding-left: 20px;
    font-size: 14px;
}
.titreDesktop,.operationId{
    margin: auto;
    margin-left: 0px;
    margin-right: 0px;
}
@media screen and (min-width: 999px){
   .category,.user,.titre
   {
       display: none;
   }
    .catDesktop,.titreDesktop
   {
       display: block;
   }
   hr{
       display: none;
   }
}
@media screen and (max-width: 999px){
   .catDesktop,.titreDesktop,.headers
   {
       display: none;
   }
   .status,.priorité{
        text-align: center;
   }
   .desktop-style{
    display: none;
    }
    .mobile-style{
    display: block;
    }
}
@media screen and (max-width: 695px){
#opcard,.priorité,.category{
    font-size: 14px
}
.desktop-style{
    display: none;
}
.mobile-style{
    display: block;
}
}
@media screen and (max-width: 576px){
.operationId{
    padding-left: 10px;}
.searchInput{
    width: 90%}
.head{
    margin-right: 0px;}
}
</style>
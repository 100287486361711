<template>
  <div>
    <crud-table
      :data="teamMembers"
      :columns="columns"
      :editFunction="editMember"
      :deleteFunction="deleteMember"
      :isRowEditable="isEditable"
      :isRowDeletable="isDeletable"
      :addFunction="addMember"
      :addFormSchema="this.addFormSchema"
      :editFormSchema="this.editFormSchema"
      :showInfo="true"
      infoText="Vous ne pouvez pas modifier le owner de l'organisation"
    >
    </crud-table>
  </div>
</template>

<script>
import crudTable from "@/components/Global/crudTable";

export default {
  data() {
    return {
      columns: [
        {
          id: 1,
          prop: "email",
          label: "Email",
          width: "180",
        },
        {
          id: 2,
          prop: "firstName",
          label: "Prénom",
          width: "180",
        },
        {
          id: 3,
          prop: "lastName",
          label: "Nom",
          width: "180",
        },
        {
          id: 4,
          prop: "role",
          label: "Rôle",
          width: "180",
          formatter: this.formatColor,
        },
      ],
      addFormSchema: [
        {
          component: "h3",
          children: "Ajouter un membre",
        },
        {
          type: "text",
          name: "email",
          label: "Email",
          validation: "required",
        },
        {
          type: "text",
          name: "firstName",
          label: "Prénom",
          validation: "required",
        },
        {
          type: "text",
          name: "lastName",
          label: "Nom",
          validation: "required",
        },
        {
          type: "text",
          name: "password",
          label: "Mot de passe",
          validation: "required",
        },
        {
          type: "select",
          options: { member: "Member", admin: "Admin" },
          name: "role",
          label: "Rôle",
          validation: "required",
        },
        {
          type: "submit",
          label: "Ajouter",
        },
      ],
      editFormSchema: [
        {
          component: "h3",
          children: "Modifier un membre",
        },
        {
          type: "text",
          name: "email",
          label: "Email",
          disabled: true,
          validation: "required",
        },
        {
          type: "text",
          name: "firstName",
          label: "Prénom",
          validation: "required",
        },
        {
          type: "text",
          name: "lastName",
          label: "Nom",
          validation: "required",
        },
        {
          type: "select",
          options: { member: "Member", admin: "Admin" },
          name: "role",
          label: "Rôle",
          validation: "required",
        },
        {
          type: "submit",
          label: "Modifier",
        },
      ],
    };
  },
  components: {
    crudTable,
  },
  computed: {
    teamMembers() {
      return this.$store.getters["contractor/getTeamMembers"];
    },
  },
  methods: {
    async addMember(member) {
      //to-do add validation for password rules
      await this.$store.dispatch("contractor/addTeamMember", member);
      this.$store.dispatch("contractor/loadOrganization", member);
    },
    async editMember(member) {
      await this.$store.dispatch("contractor/editTeamMember", member);
      this.$store.dispatch("contractor/loadOrganization", member);
    },
    async deleteMember(member) {
      await this.$store.dispatch("contractor/deleteTeamMember", member);
      this.$store.dispatch("contractor/loadOrganization", member);
    },
    isEditable(item) {
      if (item.role === "owner") {
        return false;
      } else return true;
    },
    isDeletable(item) {
      if (item.role === "owner") {
        return false;
      } else return true;
    },
  },
  created() {},
};
</script>

<template>
    <div>
        <coming-soon></coming-soon>
    </div>
</template>
<script>
import ComingSoon from "@/components/Global/comingSoon.vue";
 
export default {
    components:{
        ComingSoon
    }
}
</script>
<style>

</style>

<template>
  <div>
    <div class="row headers">
      <div class="col-6"><p>Email</p></div>
      <div class="col-6"><p>Téléphone</p></div>
    </div>
    <div v-for="client in filteredClients" :key="client.id">
      <card class="card">
        <div class="mycard">
          <div class="row head">
            <div class="col col-lg-6 col-md-6 col-sm-6 col-6" id="email">
              {{ client.email }}
            </div>
            <div class="col col-lg-6 col-md-6 col-sm-6 col-6">
              {{ client.phoneNumber }}
            </div>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>
<script>
export default {
  computed: {
    clientMembers() {
      return this.$store.getters["contractor/getClients"];
    },
    filteredClients() {
      return this.$store.getters["contractor/getfilteredClients"];
    },
  },
  created() {
    this.$store.dispatch("contractor/loadClients");
  },
};
</script>
<style scoped>
.headers {
  margin-top: 25px;
  padding-right: 10px;
  font-size: 14px;
  color: rgba(88, 82, 82, 0.745);
}
.headers p {
  text-align: center;
}
#email {
  font-weight: bold;
}
.userPicture {
  border-radius: 15px;
  width: 40px;
  height: 40px;
}
.col {
  color: #2d3748;
  text-align: center;
  padding: 0px 5px 0px 5px;
}
.head {
  font-size: 14px;
}
.card:hover {
  background-color: #8f919417;
  cursor: pointer;
}
.card {
  border-radius: 10px;
}
@media screen and (max-width: 440px) {
  .userPicture {
    width: 20px;
    height: 20px;
  }
  #email {
    font-size: 12px !important;
    font-weight: bold;
  }
}
</style>

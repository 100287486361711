<template>
  <div class="col-12">
    <div class="card">
      <div class="stats-container">
        <div
          class="stats-element"
          v-for="status in statuses"
          :key="status.ranking"
        >
          <span v-bind:style="statusBgColor(status.color)">
            {{ status.count }}
          </span>
          <p v-on:click="goToOperations(status)">{{ status.label }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    statuses: {
      type: Array,
    },
  },
  methods: {
    statusBgColor(color) {
      return "background-color:" + color;
    },
    async goToOperations(status) {
      let options = {
        limit: 20,
        start: 0,
        status: [status],
        category: "",
        firstName: "",
        lastName: "",
      };
      //await this.$store.dispatch("contractor/loadOperations", options);
      this.$store.dispatch("contractor/loadOperations", options);
      this.$router.push("/dashboard/operations?status=" + status.id );
    },
  },
};
</script>
<style scoped>
.card {
  margin-top: 20px;
  border-radius: 10px;
  font-size: 14px;
  border: none;
  padding: 5px;
}
span {
  color: #ffffff;
  border-radius: 100px;
  margin-right: 5px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.stats-container {
  display: flex;
  width: 95%;
  margin: auto;
  flex-wrap: wrap;
  justify-content: space-between;
}
.stats-element {
  width: 200px;
  display: flex;
  align-items: center;
  padding: 8px;
}

.stats-element p {
  margin: 0;
  cursor: pointer;
}
</style>
